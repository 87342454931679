import axios from "axios";
import { PaidCreatorFields, UserProfile } from "@contracts/userProfile";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";

// Max for a customer drawn boundary, we increased this dramatically and added some notifations
// to let us know when it's actually being itilized
const MaximumTravelBoundaryInSquareMiles = 6000000; // About the entire US
const TravelBoundaryShowWarningInSquareMiles = 400000; // Show them a warning
const MaximumTravelRadiusInMiles = 1000;
const MinimumTravelRadiusInMiles = 10;

// Repo for the user profile CRUD
export default {
  MaximumTravelBoundaryInSquareMiles,
  TravelBoundaryShowWarningInSquareMiles,

  async saveProfile(profile: UserProfile, referredByCode: string = null): Promise<UserProfile> {
    let uri = `${import.meta.env.VITE_API_URL}/profile/${profile.sherpaId}`;
    if (referredByCode) uri += `?referredByCode=${referredByCode}`;
    const { data } = await axios.put<UserProfile>(uri, profile);
    getGlobalRemoteLogger().info(`ProfileRepo.saveProfile: creatorId=${data?.sherpaId}, referredByCode=${referredByCode}`, false, false, {
      creatorId: data?.sherpaId,
      referredByCode,
    });
    return data;
  },

  async loadProfile(authIdOrCreatorToLoad: string | null): Promise<UserProfile | null> {
    let uri = `${import.meta.env.VITE_API_URL}/profile`;
    if (authIdOrCreatorToLoad) {
      uri += `/${authIdOrCreatorToLoad}`;
    }
    const { data } = await axios.get(uri);
    getGlobalRemoteLogger().info(`ProfileRepo.loadProfile: creatorId=${data?.sherpaId}, inputParam=${authIdOrCreatorToLoad}`);

    return data;
  },

  // Verifies all paid creator fields are valid
  paidCreatorFieldsAreValid(collabPreferences: PaidCreatorFields): [boolean, Array<string> | null] {
    // If they aren't interested then don't validate
    if (collabPreferences.becomingCollabEligible === false && collabPreferences.isCollabEligible === false) {
      return [true, null];
    }
    // Validate
    const error = new Array<string>();
    if (!collabPreferences.locationName || collabPreferences.locationName.length === 0 || !collabPreferences.latitude) error.push("Home Town is required");
    if (!collabPreferences.boundarySelectMode || collabPreferences.boundarySelectMode.trim().length === 0) {
      error.push("Select a Boundary mode");
    } else if (collabPreferences.boundarySelectMode === "radius") {
      // Radius validation is in the UI
      if (collabPreferences.travelRangeInMiles < MinimumTravelRadiusInMiles || collabPreferences.travelRangeInMiles > MaximumTravelRadiusInMiles)
        error.push(`Travel Boundary radius must be between ${MinimumTravelRadiusInMiles} and ${MaximumTravelRadiusInMiles.toLocaleString()} miles`);
    } else if (collabPreferences.boundarySelectMode === "draw") {
      if (!collabPreferences.travelBoundaryGeoJson || collabPreferences.travelBoundaryGeoJson?.trim().length === 0) error.push("Click the map to define a travel boundary");
      else if (collabPreferences.travelBoundaryAreaInMiles > MaximumTravelBoundaryInSquareMiles) error.push("Current Travel Boundary is too large");
    } else if (collabPreferences.travelBoundaryAreaInMiles > MaximumTravelBoundaryInSquareMiles) error.push("Please select a smaller Travel Boundary");
    // Reducing the number of required fields
    // if (!collabPreferences.fullName || collabPreferences.fullName.length === 0) error.push("Full Name is required");
    // if (!collabPreferences.interests || collabPreferences.interests.length === 0) error.push("Interests field is required");
    // Deprecated: if (!collabPreferences.availability || collabPreferences.availability.length === 0) error.push("Availability is required");

    if (error?.length > 0) return [false, error];
    else return [true, null];
  },
};
