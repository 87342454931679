<template>
  <div v-if="collab" class="custom-collab-details">
    <h1 class="global-h1">{{ collab.title ?? `Custom Collab with ${collab.creatorName}` }}</h1>

    <div class="creator-info">
      <img :src="`https://dev-cdn.shrpa.com/cms/images/profiles/${collab.creatorPhoto}`" alt="Creator Photo" />
      <h3>{{ collab.creatorName }}</h3>
    </div>

    <div class="content-columns">
      <div class="messages-column" style="min-height: 900px">
        <div v-if="collab.status !== 'Planning'" class="collab-details-section creator-status">
          <CreatorStatus :collab="collab" />
          <div style="margin-top: 12px" v-if="collab.status === 'Complete' && !hasBeenMarkedAsPaid">
            <h3 class="what-next-heading">What Next?</h3>
            <p class="what-next-text">
              If you have questions or changes, send a message to the creator below.
              <div style="margin-top: 7px">
                Or if it looks good: <SrpButton size="tiny" style="margin-left: 8px" @click="showMarkPaidModal = true">Mark as Paid</SrpButton>
              </div>
            </p>
          </div>
        </div>
        <div class="collab-details-section messages-section">
          <h2 class="global-h2">Messages</h2>
          <CollabMessagesThread
            class="messages-thread-flyout"
            :messageThreadId="collab.messageThreadId"
            :customerId="collab.customerId"
            :collabInput="collab"
            messageSenderType="CustomerId"
            style="height: 100%"
          />
        </div>
      </div>

      <div class="details-column">
        <!--<div v-if="collab.status === 'Complete'" class="collab-details-section creator-status">
          <CreatorStatus :collab="collab" :showStatus="false" />
        </div>-->
        <div class="collab-details-section">
          <button class="expand-button" @click="isDetailsExpanded = !isDetailsExpanded">
            <h2 class="global-h2">Collab Details</h2>
            <span class="expand-icon" :class="{ 'is-expanded': isDetailsExpanded }">▼</span>
          </button>

          <div v-if="isDetailsExpanded" class="collab-details">
            <EditCollabDetails :collab="collab" :showFillWithAI="collab.status === 'Planning'" @submit="handleSubmit" />
            <div class="action-buttons">
              <SrpButton @click="handleSubmit">Save</SrpButton>
              <SrpButton :isDisabled="true" v-if="collab.status === 'Planning'" @click="handleSendToCreator" variant="secondary">Send to Creator</SrpButton>
            </div>
          </div>
        </div>

        <div class="collab-details-section">
          <button class="expand-button" @click="isHowItWorksExpanded = !isHowItWorksExpanded">
            <h2 class="global-h2" style="margin-bottom: 0">How Custom Collabs Work</h2>
            <span class="expand-icon" :class="{ 'is-expanded': isHowItWorksExpanded }">▼</span>
          </button>

          <div v-if="isHowItWorksExpanded">
            <HowCustomCollabsWork :showTitle="false" />
            <SrpButton size="small" @click="handleFormalize" style="margin-top: 12px"> Formalize the Collab </SrpButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add the modal -->
  <SrpModal :isVisible="showMarkPaidModal" @close="showMarkPaidModal = false">
    <template #title>Mark Collab as Paid</template>
    <template #content>
      <p>Once payment and comps have been delivered to the creator, you can mark this collab as paid.</p>
      <p>If you have questions or concerns please contact the creator.</p>
      <p style="margin-top: 16px">In the future, Shrpa plans to facilitate payment. If interested in this feature, please let us know!</p>
    </template>
    <template #footer>
      <div class="modal-footer">
        <SrpButton fill="outlined" @click="showMarkPaidModal = false">Cancel</SrpButton>
        <SrpButton @click="handleMarkAsPaid">Mark Paid</SrpButton>
      </div>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { fakeCollabs, type CustomCollab } from "./CustomCollabFakeData";
const route = useRoute();
import EditCollabDetails from "./EditCollabDetails.vue";
import CollabMessagesThread from "@views/PaidCollab/CollabMessagesThread/index.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import HowCustomCollabsWork from "./components/HowCustomCollabsWork.vue";
import CreatorStatus from "./components/CreatorStatus.vue";
import SrpModal from "@components/ui/SrpModal.vue";

const isHowItWorksExpanded = ref(true);
const isDetailsExpanded = ref(false);
const showMarkPaidModal = ref(false);
const hasBeenMarkedAsPaid = ref(false);

const collab = ref<CustomCollab | null>(null);

onMounted(() => {
  const collabId = Number(route.params.customCollabId);
  collab.value = fakeCollabs.find(c => c.id === collabId);

  isDetailsExpanded.value = collab.value?.status !== "Planning";
  isHowItWorksExpanded.value = collab.value?.status === "Planning";

  // Check if we should formalize
  if (route.query.action === 'formalize') {
    handleFormalize();
  }
});

const handleSubmit = (updatedCollab: any) => {
  // Handle collab update logic here
  console.log("Updating collab:", updatedCollab);
};

const handleSendToCreator = () => {
  // Handle sending to creator logic here
  console.log("Sending to creator:", collab.value);
};

const handleFormalize = () => {
  isHowItWorksExpanded.value = false;
  isDetailsExpanded.value = true;
  /* setTimeout(() => {
    const detailsSection = document.querySelector(".collab-details");
    detailsSection?.scrollIntoView({ behavior: "smooth" });
  }, 100); */
};

const handleMarkAsPaid = () => {
  // Handle marking as paid logic here
  console.log("Marking collab as paid:", collab.value);
  showMarkPaidModal.value = false;
  hasBeenMarkedAsPaid.value = true;
};
</script>

<style scoped lang="scss">
.custom-collab-details {
  padding: 20px;

  .creator-info {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 24px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    h3 {
      margin: 0;
      font-size: 1.1rem;
      font-weight: 500;
    }
  }

  .content-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    // margin-top: 24px;

    /*.messages-column,
    .details-column {
      /*background: white;
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
      border: 1px solid #e0e0e0;
    }*/

    .global-h2 {
      margin-bottom: 20px;
    }
  }

  .details-column {
    /*background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #e0e0e0;*/

    .action-buttons {
      display: flex;
      gap: 12px;
      margin-top: 20px;
      justify-content: flex-end;
    }
  }
}

.formalize-section {
  margin-top: 24px;
  display: flex;
  justify-content: center;

  .formalize-button {
    width: 100%;
    max-width: 300px;
  }
}

.collab-details-section {
  margin-top: 24px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;

  &.creator-status {
    padding: 20px;
    margin-bottom: 24px;
  }

  .expand-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .expand-icon {
      transition: transform 0.2s ease;

      &.is-expanded {
        transform: rotate(180deg);
      }
    }
  }

  .global-h2 {
    margin: 0;
  }
}

.messages-section {
  height: calc(100% - 140px); // Adjust for creator status height
  display: flex;
  flex-direction: column;

  .messages-thread-flyout {
    flex: 1;
    min-height: 0; // Required for flex child to scroll properly
  }
}

.what-next-heading {
  font-size: 1.1rem;
  margin-bottom: 8px;
  margin-top: 17px;
}

.what-next-text {
  margin-bottom: 12px;
  color: #666;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
</style>
