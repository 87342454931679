<template>
  <div class="ui form">
    <div v-if="collabInput.createdForCreatorId" style="margin-bottom: 25px">
      <!-- These fields are normally during the choose creator flow, which doesn't happen for the CreatedFor flow-->
      <div class="field">
        <div class="global-h4">Destination Contact Name</div>
        <p class="cms">Who is the primary contact for this visit? (if the creator has questions or businesses ask who they are working with)</p>
        <div
          :class="{
            'form-error-highlight': true,
            'form-error-highlight--bg-visible': errors.includes('Contact Name'),
          }"
        >
          <input class="global-text-input" type="text" v-model="collabInput.destinationContactName" ref="destinationContactNameInput" style="width: 320px; max-width: 320px" />
        </div>
      </div>

      <div class="field">
        <div class="global-h4">Contact Phone Number</div>
        <p class="cms">What is a good phone number for the creator to call in case of last-minute coordination needs?</p>
        <div
          :class="{
            'form-error-highlight': true,
            'form-error-highlight--bg-visible': errors.includes('Contact Phone Number'),
          }"
        >
          <input class="global-text-input" type="text" v-model="collabInput.destinationPhoneNumber" style="width: 320px; max-width: 320px" />
        </div>
      </div>
    </div>

    <!-- Area Boundaries and Excludes -->
    <div class="field">
      <div class="global-h4">Area Boundaries</div>
      <p class="cms">(Optional) The area you would like the adventures to stay within (ex. within 10 miles of town, within a specific county).</p>
      <textarea type="text" maxlength="1000" v-model="collabInput.areaBoundaries" style="height: 100px" ref="areaBoundariesTextarea"></textarea>
    </div>
    <div class="field">
      <div class="global-h4">Locations to Exclude</div>
      <p class="cms">(Optional) Are there locations the creator should avoid covering in this visit?</p>
      <textarea type="text" maxlength="1000" v-model="collabInput.excludedAttractions" style="height: 100px"></textarea>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from "vue";
import transformPhoneToUSMask from "@logic/PhoneMask";
import { CollabInput } from "@contracts/collab";

const props = defineProps({
  collabInput: { type: Object as () => CollabInput | null, required: true },
  errors: { type: Array<string>, required: true },
});

const destinationContactNameInput = ref<HTMLInputElement | null>(null);
const areaBoundariesTextarea = ref<HTMLInputElement | null>(null);

// Watch for phone number changes
watch(
  () => props.collabInput?.destinationPhoneNumber,
  newValue => {
    if (!newValue || !props.collabInput) return;
    props.collabInput.destinationPhoneNumber = transformPhoneToUSMask(newValue);
  }
);

// Replace mounted hook with onMounted
onMounted(() => {
  nextTick(() => {
    if (props.collabInput.createdForCreatorId) {
      destinationContactNameInput.value?.focus();
    } else {
      areaBoundariesTextarea.value?.focus();
    }
  });
});
</script>

<style scoped lang="scss">
@import "./form-error-highlight.scss";
</style>
