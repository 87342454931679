<template>
  <div v-if="isProduction">This only runs in Dev currently</div>
  <div v-else class="custom-collabs">
    <div class="custom-collabs-header">
      <div class="global-h1 creator-visits-page__h1">Custom Collabs</div>
    </div>
    <div class="header-actions">
      <SrpButton size="small" @click="showCreateModal = true">Start a New Collab</SrpButton>
      <SrpButton size="small" fill="outlined" :to="{ name: 'CustomerSavedCreators', params: { pageId: 'funtown' } }">View Saved Creators</SrpButton>
    </div>
    <div class="collab-cards">
      <div v-for="collab in collabs" :key="collab.id" class="collab-card">
        <div class="card-header">
          <div class="creator-info">
            <img :src="`https://dev-cdn.shrpa.com/cms/images/profiles/${collab.creatorPhoto}`" alt="Creator Photo" />
            <h3>{{ collab.creatorName }}</h3>
          </div>
          <div class="action-icons" v-if="collab.status !== 'Planning'">
            <span class="icon" @click="openChat(collab)">
              <IconEmbedded name="chat_2-5" color="dark-grey" :size="18" />
              <span v-if="collab.unreadCount" class="unread-count">{{ collab.unreadCount }}</span>
            </span>
          </div>
        </div>
        <div class="card-content">
          <template v-if="collab.status === 'Planning'">
            <div class="chat-message">
              <p class="message-time">{{ collab.lastMessageTime ? formatTime(collab.lastMessageTime) : "2 days ago" }}</p>
              <p class="message-text">{{ collab.lastMessage }}</p>
            </div>
            <div class="action-buttons">
              <button class="message-btn" @click="openChat(collab)">Message Creator</button>
              <button class="collab-btn" @click="openCollabDetails(collab, true)">Formalize Collab</button>
            </div>
          </template>
          <template v-else>
            <div>
              <div v-if="collab.status !== 'Planning'" class="title-row">
                <div class="global-h5">{{ collab.title }}</div>
                <div style="margin-top: 7px">
                  <b>{{ collab.status === "Complete" ? "Completed" : "Due" }}</b
                  >: {{ formatDate(collab.dueDate) }}
                </div>
              </div>
              <CreatorStatus :collab="collab" :showNotes="false" style="margin-top: 16px" />
            </div>
            <div class="button-container">
              <SrpButton size="small" fill="outlined" @click="openCollabDetails(collab, false)">
                {{ collab.status === "Complete" ? "View Deliverables" : "View Collab" }}
              </SrpButton>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Edit Collab Modal -->
    <SrpModal v-model:isVisible="showModal" size="large" isWithScroll :isClosable="true">
      <template #header>
        <h2 class="global-h1">{{ modalTitle }}</h2>
      </template>
      <template #content>
        <EditCollabDetails :collab="currentCollab" @submit="submitCollab" />
      </template>
      <template #footer>
        <div class="modal-buttons">
          <SrpButton fill="outlined" @click="closeModal">Cancel</SrpButton>
          <SrpButton @click="submitCollab">Save</SrpButton>
        </div>
      </template>
    </SrpModal>

    <SrpModal v-model:isVisible="showCreateModal" size="large" isWithScroll :isClosable="true">
      <template #content>
        <NewCustomCollab @submit="handleNewCollab" />
      </template>
    </SrpModal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@/components/ui/SrpButton.vue";
import NewCustomCollab from "./NewCustomCollab.vue";
import EditCollabDetails from "./EditCollabDetails.vue";
import IconEmbedded from "@/components/ui/IconEmbedded.vue";
import CreatorStatus from "./components/CreatorStatus.vue";
import { fakeCollabs, type CustomCollab } from "./CustomCollabFakeData";

const router = useRouter();
const route = useRoute();
const mode = ref("page");

// Add window declaration at the top of the script
declare const window: Window & typeof globalThis;
const isProduction = computed(() => {
  return window.location.hostname === "shrpa.com";
});

onMounted(() => {
  // Check query param and set mode
  const queryMode = route.query.mode as string;
  mode.value = queryMode === "modal" ? "modal" : "page";
});

const collabs = ref(fakeCollabs);

const showModal = ref(false);
const modalTitle = ref("");
const currentCollab = ref<any>({
  id: 0,
  title: "",
  creatorName: "",
  creatorPhoto: "",
  status: "Planning",
  description: "",
  lastMessage: "",
  cashComp: 0,
  dueDate: "",
  customerNotes: "",
  creatorNotes: "",
});

const showCreateModal = ref(false);

const openCollabDetails = (collab = null, formalizeCollab = false) => {
  if (mode.value === "page") {
    navigateToCollabDetails(collab, formalizeCollab);
    return;
  }

  if (collab) {
    currentCollab.value = { ...collab };
    modalTitle.value = "Update Collab Details";
  } else {
    currentCollab.value = {
      id: 0,
      title: "",
      creatorName: "",
      creatorPhoto: "",
      status: "Planning",
      description: "",
      lastMessage: "",
      cashComp: 0,
      dueDate: "",
      customerNotes: "",
      creatorNotes: "",
    };
    modalTitle.value = `Formalize the work with <creator name>`;
  }
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
};

const submitCollab = () => {
  if (currentCollab.value.id) {
    const index = collabs.value.findIndex(c => c.id === currentCollab.value.id);
    if (index !== -1) {
      collabs.value[index] = { ...currentCollab.value };
    }
  } else {
    currentCollab.value.id = collabs.value.length + 1;
    collabs.value.push({ ...currentCollab.value });
  }
  closeModal();
};

const formatDate = (date: string) => {
  if (!date) return "";
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

const formatTime = (time: string) => {
  if (!time) return "";
  return new Date(`1970/01/01 ${time}`).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

const openChat = (collab: any) => {
  if (mode.value === "page") {
    navigateToCollabDetails(collab);
  }

  // Implement modal chat opening logic here
  console.log("Opening chat for collab:", collab.id);
};

const navigateToCollabDetails = (collab: any, formalizeCollab = false) => {
  router.push({
    name: "CustomerCustomCollabDetails",
    params: { customCollabId: collab.id },
    query: { action: formalizeCollab ? "formalize" : undefined },
  });
};

const handleNewCollab = (newCollab: any) => {
  if (mode.value === "page") {
    navigateToCollabDetails(newCollab);
  }
};
</script>

<style scoped lang="scss">
.custom-collabs {
  .collab-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 20px;
  }

  .collab-card {
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 8px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      .creator-info {
        display: flex;
        align-items: center;
        gap: 12px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }

        h3 {
          margin: 0;
          font-size: 1.1rem;
          font-weight: 500;
        }
      }

      .action-icons {
        display: flex;
        gap: 12px;

        .icon {
          cursor: pointer;
          color: #666;

          &:hover {
            color: #333;
          }
        }
      }
    }

    .card-content {
      p {
        margin: 8px 0;
        color: #666;

        &.description {
          font-size: 1.1rem;
          color: #333;
          margin-bottom: 16px;
        }
      }

      .chat-message {
        background: #f5f5f5;
        border-radius: 8px;
        padding: 12px;
        margin-bottom: 20px;

        .message-time {
          color: #666;
          font-size: 0.9rem;
          margin-bottom: 4px;
        }

        .message-text {
          color: #333;
          margin: 0;
          line-height: 1.4;
        }
      }

      .action-buttons {
        display: flex;
        gap: 12px;
        margin-top: 20px;

        button {
          flex: 1;
          padding: 10px;
          border-radius: 6px;
          border: none;
          font-weight: 500;
          cursor: pointer;
          transition: background-color 0.2s;

          &.message-btn {
            background: white;
            border: 1px solid #ccc;
            color: #333;

            &:hover {
              background: #f5f5f5;
            }
          }

          &.collab-btn {
            background: #2b6b78;
            color: white;

            &:hover {
              background: #235761;
            }
          }
        }
      }

      .content-group {
        border: 1px solid #eee;
        border-radius: 6px;
        padding: 16px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .global-h5 {
          margin-top: 12px;
          margin-bottom: 4px;

          &:first-child {
            margin-top: 0;
          }
        }

        p {
          margin: 0 0 8px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .group-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 16px;

          .description {
            margin: 0;
            font-size: 1.1rem;
            color: #333;
          }

          .icon {
            cursor: pointer;
            color: #666;
            padding: 4px;

            &:hover {
              color: #333;
            }
          }
        }

        .status-row {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-bottom: 16px;

          .global-h5 {
            margin: 0;
          }

          .status-badge {
            margin: 0;
          }
        }
      }

      .button-container {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
      }
    }

    .status-badge {
      display: inline-block;
      padding: 4px 12px;
      border-radius: 16px;
      font-size: 0.9rem;
      background: #e8f5e9;
      color: #2e7d32;

      &.not-started {
        background: #e3f2fd;
        color: #1976d2;
      }
    }
  }

  .custom-collabs-header {
    margin-bottom: 16px;
  }

  .header-actions {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
  }
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  input,
  select,
  textarea {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  .modal-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    :deep(.srp-button) {
      min-width: 100px;
    }
  }
}

.collab-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;

  .form-field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    input,
    textarea {
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 6px;
      font-size: 1rem;

      &:focus {
        outline: none;
        border-color: #2b6b78;
      }
    }

    textarea {
      min-height: 100px;
      resize: vertical;
    }

    .payment-input {
      position: relative;
      display: flex;
      align-items: center;

      .dollar-sign {
        position: absolute;
        left: 12px;
        color: #666;
      }

      input {
        padding-left: 24px;
      }
    }

    textarea {
      min-height: 75px;
      height: 75px;
      resize: none;
    }
  }

  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

.drive-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2b6b78;
  text-decoration: none;
  margin-top: 8px;
  font-size: 0.9rem;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
  }

  i {
    font-size: 0.8rem;
    flex-shrink: 0;
  }
}
</style>
