<template>
  <div class="collab-snippet-v2">
    <div class="collab-snippet-v2__image-wrap">
      <SrpButton class="collab-snippet-v2__clone-btn">
        <template #icon>
          <IconEmbedded name="copy_2" :size="18" />
        </template>
        Clone
      </SrpButton>

      <img v-if="props.collab.coverImage" class="collab-snippet-v2__image" :src="`${contentBaseUri}/cms/images/expeditions/thumbnails/${props.collab.coverImage}`" alt="Collab photo" />
      <div v-else class="collab-snippet-v2__image-placeholder">
        <IconEmbedded name="image_1-5" :size="55" color="rgba(0, 0, 0, 0.06)" />
      </div>
    </div>

    <div class="collab-snippet-v2__name-and-date">
      <div class="collab-snippet-v2__name">
        {{ props.collab.collabName || "New Creator Visit" }}
      </div>
      <div v-if="props.collab.completedDate" class="collab-snippet-v2__date">
        <IconEmbedded class="collab-snippet-v2__date-icon" name="calendar_2" />
        {{ DateUtils.formatDateRange(props.collab.completedDate, 0, true) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import DateUtils from "@logic/DateUtils";

// Types
import { CreatorCollabSummary } from "@contracts/collab";

// Component
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpButton from "@components/ui/SrpButton.vue";

const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    collab: CreatorCollabSummary;
  }>(),
  {
    collab: null,
  }
);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Collab snippet v2 ==========================================================
.collab-snippet-v2 {
  display: flex;
  flex-direction: column;

  &__image-wrap {
    aspect-ratio: 2/1.1;
    border-radius: 6px 6px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transform: scale(1);
    transition: transform 0.05s ease-in-out;
  }

  &__clone-btn {
    position: absolute;
    inset: calc(50% - 20px) auto auto calc(50% - 53px);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.1);
  }

  &__name-and-date {
    flex-grow: 1;
    padding: 12px 18px 15px;
    border-radius: 0 0 6px 6px;
    background: rgba(240, 240, 240, 1);
  }

  &__name {
    flex-grow: 1;
    margin-bottom: 8px;
    font:
      500 17px/20px "Quicksand",
      sans-serif;
  }
  &:hover &__name {
    text-decoration: none;
  }

  &__date {
    display: flex;
    align-items: center;
    color: #5b5b5b;
    font-family: sans-serif;
    font-size: 14px;
    line-height: 14px;
  }

  &__date-icon {
    width: 15px;
    margin-right: 5px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-snippet-v2 {
    &__image-wrap {
      width: 120px;
    }
  }
}
</style>
