import { shrpaApi } from "./axios";

// Types
import { CollabGenerateInputPayload, CollabSuggestion } from "@contracts/collabSuggestions";

export default {
  async fetchAllCollabSuggestions(customerId: string): Promise<Array<CollabSuggestion>> {
    let uri = `/collab-suggestions/${customerId}`;
    const { data } = await shrpaApi.get<Array<CollabSuggestion>>(uri);

    return data;
  },

  async generateCollabSuggestions(pageId: string, payload: CollabGenerateInputPayload, countToGenerate = 4): Promise<Array<CollabSuggestion>> {
    const config = { headers: { "Content-Type": "application/json" } };
    const uri = `/collab-suggestions/${pageId}/generate?resultCount=${countToGenerate}`;

    const { data } = await shrpaApi.post<Array<CollabSuggestion>>(uri, JSON.stringify(payload), config);

    return data;
  },

  async fetchCollabSuggestion(customerId: string, collabSuggestionId: string): Promise<CollabSuggestion> {
    const uri = `/collab-suggestions/${customerId}/suggestions/${collabSuggestionId}`;
    const { data } = await shrpaApi.get<CollabSuggestion>(uri);
    return data;
  },
};
