import { shrpaApi } from "./axios";

// Types
import { CollabInput } from "@contracts/collab";

export default {
  async loadHighlights(communityId: string, collabInputId: string, creatorId: string): Promise<string[]> {
    if (!creatorId) return [];

    const uri = `/collabs/${communityId}/inputs/${collabInputId}/creator/${creatorId}/highlights`;

    const response = await shrpaApi.get<string[]>(uri);
    return response.data;
  },
  async loadCustomerCollabs(customerId: string): Promise<Array<CollabInput>> {
    const uri = `/collabs/${customerId}`;
    const { data } = await shrpaApi.get<Array<CollabInput>>(uri);

    return data;
  },
};
