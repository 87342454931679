<template>
  <div class="plan-info">
    <h4 class="global-h4" style="margin-bottom: 20px">Your Creator Suite subscription provides you access to</h4>

    <!-- Plan info section -->
    <div class="plan-info-section plan-info__section">
      <div class="global-h5 plan-info-section__title">Inspiration Source</div>
      <div class="plan-info-section__text">Access creative concepts, industry trends, and success stories to fuel your collaborations.</div>
    </div>
    <!-- / Plan info section -->

    <div class="plan-info__divider"></div>

    <!-- Plan info section -->
    <div class="plan-info-section plan-info__section">
      <div class="global-h5 plan-info-section__title">Collaboration Hub</div>
      <div class="plan-info-section__text">Manage and track collaborations with seamless coordination and unified communication tools.</div>
    </div>
    <!-- / Plan info section -->

    <div class="plan-info__divider"></div>

    <!-- Plan info section -->
    <div class="plan-info-section plan-info__section">
      <div class="global-h5 plan-info-section__title">Content Outpost</div>
      <div class="plan-info-section__text">
        <p class="global-p">Organize, enhance, and share your content effortlessly with tools for websites, social media, and flyers.</p>
      </div>
    </div>
    <!-- / Plan info section -->
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import "./plan-info.scss";
</style>
