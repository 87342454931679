<template>
  <div class="new-custom-collab">
    <div class="step-content">
      <div v-if="currentStep === 1">
        <h2 class="global-h1 heading-spacing">Who would you like to work with?</h2>
        <p>&lt;&lt;Creator selection form will go here&gt;&gt;<br />(this is implied when coming from Saved Creators)</p>

        <div class="invite-section">
          <h3 class="global-h3">Invite a creator</h3>
          <div class="invite-input-group">
            <input v-model="inviteEmail" type="email" placeholder="user@example.com" class="invite-input" />
            <SrpButton size="small" @click="addInvite"> Add </SrpButton>
          </div>
          <div v-if="invitedEmails.length > 0" class="invited-list">
            <div v-for="(email, index) in invitedEmails" :key="index" class="invited-email">
              {{ email }}
            </div>
          </div>
        </div>

        <HowCustomCollabsWork showTitle />
      </div>

      <div v-if="currentStep === 2">
        <h2 class="global-h1 heading-spacing">What kind of work?</h2>
        <div class="template-options">
          <div v-for="template in templates" :key="template.id" class="template-option" @click="selectTemplate(template.id)">
            <div class="template-content">
              <div class="template-title">{{ template.title }}</div>
              <div class="template-description">{{ template.description }}</div>
            </div>
            <div class="template-arrow">
              <IconEmbedded name="caret-right_2-5" :size="26" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentStep === 3">
        <h2 class="global-h1 heading-spacing">Start the Conversation</h2>
        <div class="chat-thread">
          <div class="creator-card">
            <div class="creator-info">
              <img :src="`https://dev-cdn.shrpa.com/cms/images/profiles/${selectedCreator.photo}`" alt="Creator Photo" />
              <h2 class="global-h1 heading-spacing">{{ selectedCreator.name }}</h2>
            </div>
          </div>

          <div class="message-section">
            <div class="message-header">
              <span>Initial Message:</span>
            </div>

            <textarea v-model="messageText" class="message-input" placeholder="Type your message..." rows="6"></textarea>

            <div class="message-footer">
              <button class="ai-assist" @click="showAiAssist = !showAiAssist" :class="{ active: showAiAssist }" title="Improve with AI">
                <IconEmbedded name="ai_2-5" :size="16" />
              </button>
            </div>

            <div v-if="showAiAssist" class="ai-assist-section">
              <div class="guidance-section">
                <label>Guidance:</label>
                <textarea
                  v-model="aiGuidance"
                  class="guidance-input"
                  placeholder="What kind of message would you like? (e.g. 'Make it more professional' or 'Add more details about compensation')"
                  rows="2"
                  style="height: 70px"
                ></textarea>
              </div>

              <div class="ai-results">
                <label>AI Suggestion:</label>
                <div class="results-content">
                  {{ aiResults || "AI suggestions will show here" }}
                </div>
                <button class="apply-button" @click="applyAiSuggestion" :disabled="!aiResults">Apply Suggestion</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="step-navigation">
      <SrpButton v-if="currentStep > 1" fill="outlined" @click="currentStep--"> Back </SrpButton>
      <SrpButton v-if="currentStep < 3" @click="currentStep++"> Next </SrpButton>
      <SrpButton v-if="currentStep === 3" @click="handleSubmit"> Send Message </SrpButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import SrpButton from "@/components/ui/SrpButton.vue";
import IconEmbedded from "@/components/ui/IconEmbedded.vue";
import HowCustomCollabsWork from "./components/HowCustomCollabsWork.vue";

// Add defineEmits declaration
const emit = defineEmits<{
  (event: "submit", collab: any): void;
}>();

const currentStep = ref(1);
const selectedTemplate = ref("");

const templates = [
  {
    id: "business",
    title: "Highlight Local Business",
    description: "Feature and promote local establishments",
  },
  {
    id: "event",
    title: "Attend an Event",
    description: "Cover and document local events",
  },
  {
    id: "photoshoot",
    title: "Photo Shoot",
    description: "Professional photography session",
  },
  {
    id: "other",
    title: "Creator Proposal",
    description: "Ask the creator to propose a collab!",
  },
];

const selectTemplate = (templateId: string) => {
  selectedTemplate.value = templateId;
  // You can add additional logic here when a template is selected
  currentStep.value++;
};

const handleSubmit = () => {
  // Create a new collab object with the form data
  const newCollab = {
    id: "1", // hardcoded Id for a new collab
    creatorName: selectedCreator.value.name,
    creatorPhoto: selectedCreator.value.photo,
    status: "Planning",
    description: "Planning collab",
    lastMessage: messageText.value,
    cashComp: 0,
    dueDate: "",
    customerNotes: "",
    creatorNotes: "",
  };

  // Emit the event with the new collab data
  emit("submit", newCollab);
};

const selectedCreator = ref({
  name: "Sarah Anderson",
  photo: "4cf39ebb-8ae8-4da5-847b-6f4e5f12a2ca.jpg",
});

const messageText = ref(`We would like to highlight [BUSINESS NAME]
We require the following content: [DELIVERABLES]
Timeline needed: [TIMELINE]
We are able to compensate with: [COMPENSATION]`);

const showAiAssist = ref(false);
const aiGuidance = ref("");
const aiResults = ref("");

const applyAiSuggestion = () => {
  if (aiResults.value) {
    messageText.value = aiResults.value;
  }
};

const inviteEmail = ref("");
const invitedEmails = ref<string[]>([]);

const addInvite = () => {
  if (inviteEmail.value && !invitedEmails.value.includes(inviteEmail.value)) {
    invitedEmails.value.push(inviteEmail.value);
    inviteEmail.value = ""; // Clear the input
  }
};
</script>

<style scoped lang="scss">
.new-custom-collab {
  padding: 20px;

  .step-content {
    min-height: 300px;
    margin-bottom: 24px;
  }

  .step-navigation {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
}

.template-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
}

.template-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: #2b6b78;
    background-color: #f8f8f8;
  }
}

.template-content {
  .template-title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  .template-description {
    font-size: 0.875rem;
    color: #666;
  }
}

.template-arrow {
  color: #666;
  font-size: 0.875rem;
}

.creator-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  margin: 0 0 24px;

  .creator-info {
    display: flex;
    align-items: center;
    gap: 12px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    h2 {
      margin: 0 0 16px;
      font-size: 1.1rem;
      font-weight: 500;
    }
  }
}

.message-section {
  margin-top: 24px;

  .message-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    span {
      font-weight: 500;
      color: #333;
    }

    .ai-assist {
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px 12px;
      font-size: 1.1rem;
      transition: all 0.2s ease;
      border: 1px solid #2b6b78;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 6px;

      &::after {
        content: "AI Assist";
        font-size: 0.9rem;
      }

      &:hover {
        color: darken(#2b6b78, 10%);
        border-color: darken(#2b6b78, 10%);
      }
    }
  }

  .message-input {
    width: 100%;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 0.95rem;
    line-height: 1.5;
    resize: vertical;
    min-height: 120px;
    background: #f8f8f8;

    &:focus {
      outline: none;
      border-color: #2b6b78;
      background: white;
    }

    &::placeholder {
      color: #999;
    }
  }

  .message-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    padding: 8px 0;
    border-bottom: 1px solid #e0e0e0;

    .ai-assist {
      background: none;
      border: 1px solid #2b6b78;
      color: #2b6b78;
      cursor: pointer;
      padding: 8px 12px;
      font-size: 1.1rem;
      transition: all 0.2s ease;
      border-radius: 4px;
      display: flex;
      align-items: center;
      gap: 6px;

      &::after {
        content: "AI Assist";
        font-size: 0.9rem;
      }

      &:hover {
        color: darken(#2b6b78, 10%);
        border-color: darken(#2b6b78, 10%);
      }
    }
  }

  .ai-assist-section {
    background: #f0f7f8;
    border: 1px solid #2b6b78;
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0 16px;
  }

  .guidance-section {
    margin-bottom: 16px;

    label {
      display: block;
      font-weight: 500;
      margin-bottom: 4px;
      color: #333;
    }

    .guidance-input {
      width: 100%;
      padding: 8px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      font-size: 0.9rem;
      resize: vertical;
      background: white;

      &:focus {
        outline: none;
        border-color: #2b6b78;
      }
    }
  }

  .ai-results {
    label {
      display: block;
      font-weight: 500;
      margin-bottom: 4px;
      color: #333;
    }

    .results-content {
      background: white;
      padding: 12px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      min-height: 80px;
      margin-bottom: 12px;
      color: #666;
    }

    .apply-button {
      background: #2b6b78;
      color: white;
      border: none;
      padding: 8px 16px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0.9rem;
      transition: background 0.2s ease;

      &:hover {
        background: darken(#2b6b78, 10%);
      }

      &:disabled {
        background: #ccc;
        cursor: not-allowed;
      }
    }
  }
}

.chat-thread {
  background: #f8f8f8;
  border-radius: 12px;
  padding: 24px;

  .creator-card {
    padding: 0;
    margin-bottom: 16px;
  }

  .message-section {
    margin-top: 0;

    .message-input {
      background: white;
    }
  }
}

.heading-spacing {
  padding-bottom: 12px;
}

.invite-section {
  margin: 24px 0;

  .invite-input-group {
    display: flex;
    gap: 12px;
    margin-top: 12px;
  }

  .invite-input {
    width: 250px;
    padding: 8px 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 0.95rem;

    &:focus {
      outline: none;
      border-color: #2b6b78;
    }
  }

  .invited-list {
    margin-top: 12px;
  }

  .invited-email {
    display: block;
    padding: 4px 0;
    // margin: 4px 0;
    font-size: 0.9rem;
  }
}
</style>
