import { shrpaApi } from "./axios";
import axios from "axios";

// Types
import { Page } from "@contracts/pages";

export interface CustomerDetails {
  itineraries: Array<any>;
  page: Page;
  totalPaidCollabs?: number;
}

export default {
  async loadCustomerDetails(id: string, isPublicFacing: boolean): Promise<CustomerDetails> {
    // passing isPublicFacing so we get the totalPaidCollabs field back
    const { data } = await shrpaApi.get<CustomerDetails>(`/pages/${id}?isPublicFacing=${isPublicFacing}`);

    return data;
  },
};
