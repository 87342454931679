<template>
  <li class="collab-tile">
    <div class="collab-tile__left-side">
      <!-- User info -->
      <div class="user-info collab-tile__user-info">
        <div class="user-info__avatar-n-msg-count">
          <RouterLink class="user-info__msg-count-link-wrap" :to="buildCollabLink(true)">
            <MsgCountBadge v-if="sharedSummaryFields.unreadMessageCount" class="user-info__msg-count" :count="sharedSummaryFields.unreadMessageCount" isHoverable />
          </RouterLink>
          <AvatarWithFallback class="user-info__avatar" :src="avatarUri" :isRounded="isCustomerCollab" :objectFit="isCustomerCollab ? 'cover' : 'contain'" />
        </div>

        <div class="user-info__name">
          {{ isCustomerCollab ? customerCollabActionSummary.creatorName : creatorCollabActionSummary.customerName }}
        </div>
      </div>
      <!-- / User info -->
    </div>

    <RouterLink class="collab-tile__right-side" :to="buildCollabLink(false)">
      <SrpButton
        class="collab-tile__cta-btn"
        :size="screenSize === 'mobile' ? 'tiny' : 'small'"
        :fill="sharedSummaryFields.actionRequired ? 'solid' : 'outlined'"
        :color="sharedSummaryFields.actionRequired ? 'orange' : 'teal'"
      >
        {{ sharedSummaryFields.actionRequired ? sharedSummaryFields.statusDisplayText : "View Collab" }}
        <template #iconRight>
          <IconEmbedded name="caret-right_3-5" :size="screenSize === 'mobile' ? 11 : 13" color="rgba(5, 133, 135, 0.1)" />
        </template>
      </SrpButton>

      <h4 class="global-h4 collab-tile__collab-name">
        {{ sharedSummaryFields.collabName }}
      </h4>

      <div class="collab-tile__date-n-status">
        <div v-if="sharedSummaryFields.visitDate" class="collab-tile__date">
          {{ moment(sharedSummaryFields.visitDate).format("MMM Do YYYY") }}
        </div>
        <div v-else-if="sharedSummaryFields.preferredDateRangeStart && sharedSummaryFields.preferredDateRangeEnd" class="collab-tile__date">
          {{ DateUtils.formatDateRangeTwoDates(sharedSummaryFields.preferredDateRangeStart, sharedSummaryFields.preferredDateRangeEnd) }}
        </div>
        <div
          v-if="!sharedSummaryFields.actionRequired && (sharedSummaryFields.status || sharedSummaryFields.statusDisplayText)"
          :class="{
            'collab-tile__status-stripe': true,
            'collab-tile__status-stripe--green': isCollabCompleted,
          }"
        >
          <IconEmbedded v-if="isCollabCompleted" name="check_4-5" color="rgba(84, 158, 63, 1)" :size="12" style="margin-right: -2px; position: relative; top: 1px" />
          {{ isCollabCompleted ? "Completed" : sharedSummaryFields.statusDisplayText }}
        </div>
      </div>
    </RouterLink>
  </li>
</template>

<script setup lang="ts">
import { inject, Ref, onMounted, computed } from "vue";
import moment from "moment";
import DateUtils from "@logic/DateUtils";

// Components
import AvatarWithFallback from "@components/AvatarWithFallback.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import MsgCountBadge from "@components/MsgCountBadge.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { CreatorCollabActionSummary, CustomerCollabActionSummary, SharedCollabActionSummary } from "@contracts/collabActionSummaries";
import { ScreenSize } from "@contracts/screenSize";
import { RouteLocationRaw } from "vue-router";

// Global variables & injections
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;
const screenSize = inject("screenSize") as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    customerCollabActionSummary?: CustomerCollabActionSummary | null;
    creatorCollabActionSummary?: CreatorCollabActionSummary | null;
  }>(),
  {
    customerCollabActionSummary: null,
    creatorCollabActionSummary: null,
  }
);

// Is customer collab action summary ==========================================
const isCustomerCollab = computed<boolean>(() => Boolean(props.customerCollabActionSummary) && !props.creatorCollabActionSummary);

// Calc is Collab completed ==========================================================
const isCollabCompleted = computed<boolean>(() => sharedSummaryFields.value.completedDate !== null);

// Avatar URI =================================================================
const avatarUri = computed<string>(() => {
  return props.creatorCollabActionSummary?.customerLogo
    ? `${contentBaseUri}/cms/images/orgs/${props.creatorCollabActionSummary?.customerLogo}`
    : props.customerCollabActionSummary?.creatorProfileImage;
});

// Shared fields ==============================================================
const sharedSummaryFields = computed<SharedCollabActionSummary>(() => {
  return isCustomerCollab.value ? props.customerCollabActionSummary : props.creatorCollabActionSummary;
});

// Generate the collab router link ============================================
function buildCollabLink(openMessage = false): RouteLocationRaw {
  const messageParam = openMessage ? `messages=true` : "";
  return isCustomerCollab.value
    ? {
        name: "PaidCollabCommunityAdmin",
        params: {
          communityId: sharedSummaryFields.value.customerId,
        },
        hash: `#collab=${sharedSummaryFields.value.collabInputId}&${messageParam}`,
      }
    : {
        name: "CollabOpportunity",
        params: {
          communityId: sharedSummaryFields.value.customerId,
          collabInputId: sharedSummaryFields.value.collabInputId,
          creatorId: sharedSummaryFields.value.creatorId,
        },
        hash: `#${messageParam}`,
      };
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// User info ==================================================================
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__avatar-n-msg-count {
    margin-bottom: 3px;
    position: relative;
    z-index: 0;
  }

  &__avatar {
    width: 75px;
    position: relative;
    z-index: 0;
  }

  &__msg-count-link-wrap {
    position: absolute;
    inset: 0 -10px auto auto;
    z-index: 1;
  }

  &__msg-count {
  }

  &__name {
    text-align: center;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .user-info {
    &__avatar {
      width: 65px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .user-info {
    &__avatar {
      width: 60px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .user-info {
    &__avatar {
      width: 50px;
    }
  }
}

// Collab tile ================================================================
.collab-tile {
  border: 1px rgba(0, 0, 0, 0.1) solid;
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  color: rgba(91, 91, 91, 1);
  font: 14px/19px sans-serif;

  // left side ------------------------
  &__left-side {
    width: 120px;
    min-width: 120px;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__user-info {
  }

  // right side -----------------------
  &__right-side {
    flex-grow: 1;
    padding: 12px 18px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: rgba(91, 91, 91, 1);
    background: rgba(231, 237, 240, 1);
  }

  &__collab-name {
    margin-bottom: 5px;
  }

  &__right-side:hover &__collab-name {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.2);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }

  &__date-n-status {
    gap: 7px 10px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__date {
  }

  &__status-stripe {
    padding: 0 17px 0 7px;
    border-left: 1px rgba(0, 0, 0, 0.3) solid;
    position: relative;
    z-index: 0;
    overflow: hidden;
    white-space: nowrap;

    &::before,
    &::after {
      content: "";
      width: 1000px;
      height: 100%;
      position: absolute;
      inset: 0 0 auto auto;
      z-index: -2;
      clip-path: polygon(0 0, 100% 0, 99.1% 50%, 100% 100%, 0 100%);
      background: rgba(0, 0, 0, 0.3);
    }

    &::after {
      z-index: -1;
      clip-path: polygon(0 5%, 99.72% 5%, 98.9% 50%, 99.72% 95%, 0 95%);
      background: rgba(255, 255, 255, 1);
    }

    &--green {
      border-color: rgba(206, 228, 200, 1);
      color: rgba(44, 117, 23, 1);

      &::before,
      &::after {
        background: rgba(206, 228, 200, 1);
      }
    }
  }

  &__cta-btn {
    order: 100;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
  }
  &__cta-btn:hover + &__collab-name {
    text-decoration: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .collab-tile {
    // left side ------------------------
    &__left-side {
      width: 100px;
      min-width: 100px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-tile {
    // left side ------------------------
    &__left-side {
      width: 90px;
      min-width: 90px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-tile {
    // left side ------------------------
    &__left-side {
      width: 80px;
      min-width: 80px;
    }
  }
}
</style>
