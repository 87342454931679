<template>
  <div>
    <div class="confirm-availability">
      <transition name="fade-7ms">
        <div v-if="isLoading" class="confirm-availability__loader">
          <Loader />
        </div>
      </transition>

      <div v-if="!isConfirmed" class="confirm-availability__content">
        <div class="global-h2 confirm-availability__headline">
          Confirm your availability for this collab!
          <span class="confirm-availability__date">{{ visitDateString }}</span>
        </div>

        <!-- Company info -->
        <div class="company-info confirm-availability__company-info">
          <img v-if="collab" class="company-info__logo" :src="`${contentBaseUri}/cms/images/orgs/${collab.communityIcon}`" alt="partner logo" />

          <div class="global-h4 company-info__name">
            {{ collab && collab.collabInput ? collab.collabInput.name : "" }}
          </div>
        </div>
        <!-- / Company info -->

        <div class="confirm-availability__divider"></div>

        <!-- Full name form -->
        <div class="phone-number-form confirm-availability__phone-number-form">
          <div class="global-h4 phone-number-form__title">Your full name</div>
          <div class="phone-number-form__description">For any activity or lodging reservations and payment.</div>
          <div class="phone-number-form__description">
            <input class="global-text-input" style="max-width: 320px" type="text" v-model.trim="userProfileStore.getViewingUserProfile.collabPreferences.fullName" placeholder="Your Name" />
          </div>
          <div v-if="showFullNameRequiredError" style="color: red">Please enter your full name before accepting</div>
        </div>
        <!-- / Full name form -->

        <!-- Phone number form -->
        <div class="phone-number-form confirm-availability__phone-number-form">
          <div class="global-h4 phone-number-form__title">Your phone number</div>
          <div class="phone-number-form__description">
            Your number may be used for any last-minute coordination needed for this collab.<br />
            We'll never share you number with anyone beside the destination you're working with.
          </div>
          <div v-if="userProfileStore.getViewingUserProfile.phoneNumberIsVerified" class="phone-number-form__description">
            Your phone number is: <MaskedPhoneNumber :phone-number="userProfileStore.getViewingUserProfile.phoneNumber" />
          </div>
          <SrpButton size="tiny" :fill="userProfileStore.getViewingUserProfile.phoneNumberIsVerified ? 'text' : 'solid'" @click="showPhoneNumberVerificationModal = true">
            {{ userProfileStore.getViewingUserProfile.phoneNumberIsVerified ? "Change phone number" : "Provide your phone number" }}</SrpButton
          >
          <div v-if="showPhoneNumberRequiredError" style="color: red">Please verify your phone number before accepting</div>
        </div>
        <!-- / Phone number form -->

        <!-- Collab Expectations -->
        <div>
          <h3>By confirming, you agree:</h3>
          <div>
            You are committing to complete this collaboration.<br />
            Any cancellations are highly discouraged and can impact your reputation and cause significant inconvenience for the destination you're working with.
          </div>
          <h4>Collab Expectations:</h4>
          <CollabExpectationsList :requestedLocationCount="collab?.collabInput?.requestedLocationCount" :requestedAdventureCount="collab?.collabInput?.requestedAdventureCount" />
        </div>
        <!-- / Collab Expectations -->

        <div class="confirm-availability__email-note">Reach out to <CopyTextNew>collabs@shrpa.com</CopyTextNew> with any questions.</div>

        <div style="margin: 20px 0; display: flex; align-items: center">
          <SrpCheckbox :isWithHalo="false">
            <template #input><input type="checkbox" v-model="isAgreedToTheTerms" /></template>
            <template #text>By proceeding, I agree to the </template>
          </SrpCheckbox>
          &nbsp;
          <RouterLink class="global-a global-a--gray" lang="en" hreflang="en" :to="{ name: 'Terms' }" target="_blank">Terms and Conditions</RouterLink>
          <!--<IconEmbedded name="external-link_2" :size="17" style="position: relative; inset: -1px 0 0 2px" color="rgba(0, 0, 0, 0.5)" />-->
        </div>
      </div>

      <div v-else class="confirm-availability__content">
        <h1 class="global-h1" style="margin-bottom: 10px">Next Steps!</h1>

        <ul class="global-ul" style="margin-bottom: 25px">
          <li>Make a Visit Plan.</li>
          <li>Communicate with the Destination to ensure everything is lined up and you're both on the same page.</li>
          <li>Visit the Destination!</li>
        </ul>

        <WhenWillIGetPaidNote :totalPaymentFromShrpa="totalPaymentFromShrpa" :titleSize="3" style="margin-bottom: 30px" />

        <NoteWithIcon color="blue">
          <template #text>
            <div>
              <h5 class="global-h5">Remember</h5>
              <span>Reach out early and often if you have questions!</span>
            </div>
          </template>
        </NoteWithIcon>
      </div>

      <div v-if="!isConfirmed" class="confirm-availability__buttons">
        <div class="ui large buttons">
          <SrpButton @click="showContactShrpaModal = true" color="gray">
            <template #icon><IconEmbedded name="envelope_2-5" :size="19" /></template>
            Contact Shrpa
          </SrpButton>
          <div class="or"></div>
          <SrpButton @click="confirmParticipation(true)" :isDisabled="!isAgreedToTheTerms">Confirm</SrpButton>
        </div>
      </div>
    </div>

    <!--Contact Shrpa Modal-->
    <SrpModal v-model:isVisible="showContactShrpaModal" :zIndex="400">
      <template #header><h2 class="global-h2">Need to contact Shrpa about this collab?</h2></template>
      <template #content>
        Unsure if you can commit to this collaboration?<br />
        Let us know your circumstances and we'll work with you to find a solution.<br />
        <textarea v-model="contactShrpaMessage" :required="true" :min-length="50" :max-length="5000" @focus="isDeclineReasonInvalid = false"></textarea>
        <div v-if="isDeclineReasonInvalid" style="color: red">Required</div>
      </template>
      <template #footer>
        <div class="ui basic black button" @click="showContactShrpaModal = false">Cancel</div>
        <div class="ui primary button" @click="confirmParticipation(false)">Send</div>
      </template>
    </SrpModal>

    <SrpModal v-model:isVisible="showPhoneNumberVerificationModal" :zIndex="400" size="small">
      <template #content>
        <PhoneNumberVerificationForm
          @phoneVerified="
            () => {
              showPhoneNumberVerificationModal = false;
              onPhoneNumberVerified();
            }
          "
          @verificationCanceled="
            () => {
              showPhoneNumberVerificationModal = false;
              onPhoneNumberVerificationCanceled();
            }
          "
        />
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts" setup>
import DateUtils from "@logic/DateUtils";
import { computed, ref } from "vue";

// Types
import { CreatorCollab } from "@contracts/collab";

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import Loader from "@components/Loader/Loader.vue";
import axios from "axios";
import SrpModal from "@components/ui/SrpModal.vue";
import CollabExpectationsList from "../CollabExpectationsList.vue";
import PhoneNumberVerificationForm from "@components/PhoneNumberVerificationForm.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useRoute } from "vue-router";
import MaskedPhoneNumber from "@components/MaskedPhoneNumber.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import WhenWillIGetPaidNote from "@components/WhenWillIGetPaidNote.vue";

const route = useRoute();

const props = withDefaults(
  defineProps<{
    collab: CreatorCollab | null;
    totalPaymentFromShrpa: number;
  }>(),
  {
    collab: null,
    totalPaymentFromShrpa: 0,
  }
);

const emit = defineEmits<{
  (e: "confirmed", willWorkForCreator: boolean): void;
}>();

const userProfileStore = useUserProfileStore();

const contentBaseUri = ref(globalThis.Bootstrap.Config.contentCdnBaseUri as string);
const showPhoneNumberVerificationModal = ref(false);
const showFullNameRequiredError = ref(false);
const showPhoneNumberRequiredError = ref(false);
const isLoading = ref(false);
const showContactShrpaModal = ref(false);
const isDeclineReasonInvalid = ref(false);
const contactShrpaMessage = ref("");

const visitDateString = computed<string>(() => {
  return DateUtils.formatDateRange(props.collab?.creatorInput?.proposedVisitDateTime, props.collab?.creatorInput?.numberOfDaysStaying);
});

async function confirmParticipation(willWorkForCreator: boolean): Promise<void> {
  const phoneNumberIsVerified = userProfileStore.getViewingUserProfile.phoneNumberIsVerified;

  if (willWorkForCreator) {
    if (!phoneNumberIsVerified) {
      showPhoneNumberRequiredError.value = true;
      return;
    }
    if (!userProfileStore.getViewingUserProfile.collabPreferences.fullName) {
      showFullNameRequiredError.value = true;
      return;
    }
  } else {
    if (!contactShrpaMessage.value) {
      isDeclineReasonInvalid.value = true;
      return;
    }
  }

  const uri = `${import.meta.env.VITE_API_URL}/collabs/${props.collab.communityId}/inputs/${route.params.collabInputId}/creator/${route.params.creatorId}/confirm`;
  const payload = {
    creatorFullName: userProfileStore.getViewingUserProfile.collabPreferences?.fullName?.trim(),
    confirmed: willWorkForCreator,
    declineDetails: contactShrpaMessage.value,
  };

  isLoading.value = true;
  await axios.put(uri, payload);
  isLoading.value = false;
  // If they confirm, show them the next steps pane, otherwise just close the modal
  if (willWorkForCreator) {
    isConfirmed.value = true;
  } else {
    showContactShrpaModal.value = false;
  }

  emit("confirmed", willWorkForCreator);
}
function onPhoneNumberVerified() {
  showPhoneNumberRequiredError.value = false;
}

function onPhoneNumberVerificationCanceled() {
  if (!userProfileStore.getViewingUserProfile.phoneNumberIsVerified) {
    showPhoneNumberRequiredError.value = true;
  }
}

// Handle isConfirmed state ===================================================
const isConfirmed = ref<boolean>(false);

// Agree to the terms =========================================================
const isAgreedToTheTerms = ref<boolean>(true);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Company info ===============================================================
.company-info {
  display: flex;
  align-items: center;

  &__logo {
    width: 70px;
    margin-right: 13px;
  }

  &__name {
    font-weight: 100;
  }
}

// Phone number form ==========================================================
.phone-number-form {
  &__title {
    margin-bottom: 4px;
  }

  &__description {
    margin-bottom: 13px;
  }

  &__input {
    max-width: 355px;
  }
}

// Confirm availability =======================================================
.confirm-availability {
  position: relative;
  z-index: 0;
  color: #5b5b5b;
  font:
    300 14px/19px "Helvetica Neue",
    sans-serif;

  &__loader {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);

    & .ajax-loader {
      margin: 0;
    }
  }

  &__content {
    margin-bottom: 25px;
    box-sizing: border-box;
  }

  &__headline {
    margin-bottom: 21px;
  }

  &__date {
    margin-top: 7px;
    display: block;
    color: #ec563b;
  }

  &__company-info {
    margin-bottom: 20px;
  }

  &__divider {
    width: calc(100% + 80px);
    margin: 0 -40px 27px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__phone-number-form {
    margin-bottom: 30px;
  }

  &__email-note {
    margin-top: 15px;
  }

  &__buttons {
    padding: 22px 30px;
    margin: 0 -30px -20px;
    border-radius: 0 0 6px 6px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    z-index: 0;
    background: #f3f3f3;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .confirm-availability {
    &__content {
    }

    &__headline {
      br {
        display: none;
      }
    }

    &__buttons {
      padding: 15px 25px;
      margin: 0 -20px -15px;
    }
  }
}
</style>
