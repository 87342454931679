<template>
  <div class="ui form">
    <div class="field">
      <div class="global-h4">Locations and Activities</div>
      <!--<div style="margin-top: 3px"><b>- Creators will visit a minimum of 10 locations in your destination.</b></div>-->
      <div
        :class="{
          'form-error-highlight': true,
          'form-error-highlight--bg-visible': errors.includes('Suggested OR Required Activities'),
        }"
        style="width: 100%"
      >
        <p class="cms" style="margin-top: 7px"><b>Suggested Locations:</b> Give the creator ideas for great places to visit!</p>
        <textarea
          type="text"
          maxlength="1500"
          ref="suggestedLocationsInput"
          v-model="collabInput.suggestedAttractions"
          placeholder="Ex. Central Perk Coffee, Barney's Bowlarama"
          style="height: 100px"
        ></textarea>

        <TransitionedHeightAuto :isOpened="!collabInput.requiredAttractions">
          <SrpCheckbox class="interested-in-being-paid__checkbox" :isWithHalo="false">
            <template #input>
              <input type="checkbox" v-model="isRequiredLocationsTextareaVisible" @change="() => {}" />
            </template>
            <template #text>
              <span>Are any of these locations required?</span>
            </template>
          </SrpCheckbox>
        </TransitionedHeightAuto>

        <TransitionedHeightAuto :isOpened="Boolean(isRequiredLocationsTextareaVisible || collabInput.requiredAttractions)">
          <p class="cms" style="margin-top: 10px"><b>(Optional) Required Locations: Choose 3-5 places max</b> that you consider must-dos.</p>
          <textarea type="text" maxlength="1000" v-model="collabInput.requiredAttractions" placeholder="Ex. Don't miss the Art Museum" style="height: 120px"></textarea>
        </TransitionedHeightAuto>
      </div>

      <br />
      <TransitionedHeightAuto :isOpened="Boolean(collabInput.requiredAttractions && collabInput.requiredAttractions.length > 0)">
        <h6 class="global-h6" style="margin-bottom: 8px; font-weight: 400">Is the cost of any required activity over $50?</h6>
        <div>
          <label style="padding: 4px 0; display: flex; align-items: center">
            <input type="radio" class="hidden" :value="true" v-model="collabInput.requestingBigTicketItems" style="width: 13px; margin-right: 10px" />
            Yes
          </label>
          <label style="padding: 4px 0; display: flex; align-items: center">
            <input type="radio" class="hidden" :value="false" @change="() => clearValidation()" v-model="collabInput.requestingBigTicketItems" style="width: 13px; margin-right: 10px" />
            No
          </label>
        </div>
      </TransitionedHeightAuto>

      <div v-if="collabInput.requestingBigTicketItems === true" style="color: red">Note: You'll need to cover the cost of these activities for them to be included in the Creator Visit.</div>
      <!--Comped Activities-->
      <div class="field" style="margin-top: 2rem">
        <div class="global-h4">Comped Activities</div>

        <label>
          Are there any activities you would like to assist with?
          <span v-if="validationResult && validationResult.compedActivityTagsRequired" class="required-field">Required</span>
        </label>

        <div
          :class="{
            'form-error-highlight': true,
            'form-error-highlight--bg-visible': errors.includes('Comped Activities Category'),
          }"
          style="padding: 0 5px 7px; margin-bottom: 15px; display: inline-flex"
        >
          <CollabCompTags :collabInput="collabInput" />
        </div>

        <NoteWithIcon size="tiny" color="blue" style="margin-bottom: 10px">
          <template #icon><IconEmbedded name="bulb_2-5" :size="16" /></template>
          <template #text>
            Comping activities is a great way to receive more applicants and maximize the visit experience!<br />
            <b>We've seen a significant increase in interested creators when activities are comped.</b>
          </template>
        </NoteWithIcon>
        <!--If this changes, review the logic in changeStep() also-->
        <template v-if="collabInput.compedActivitiesTags.length > 0">
          <p class="cms">
            Comped Activities Details (more can be added later)
            <span v-if="validationResult && validationResult.compedActivityTextRequired" class="required-field">Required</span>
          </p>

          <div
            :class="{
              'form-error-highlight': true,
              'form-error-highlight--bg-visible': errors.includes('Comped Activities Details'),
            }"
            style="width: 100%"
          >
            <CompedActivitiesTextarea v-model:value="collabInput.bigTicketItemBookinNotes" />
          </div>
        </template>
      </div>
      <div class="field" v-if="collabInput.compedActivitiesTags.length > 0">
        <label class="section-title">Estimated Comped Activity Value</label>
        <p class="cms">
          We show this to the creator to convey the total value they're receiving.<br />
          If you aren't sure, just put in an estimate.
        </p>
        <i class="dollar sign icon" style="padding-top: 10px"></i>
        <div
          :class="{
            'form-error-highlight': true,
            'form-error-highlight--bg-visible': errors.includes('Estimated Comped Activity Value'),
          }"
          style="display: inline-flex"
        >
          <input type="number" v-model.number="collabInput.compedActivitiesEstimatedValue" style="width: 80px; display: inline" />
        </div>
        <span style="margin-left: 5px"> (this should exclude lodging value)</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

// Types
import { CollabInput } from "@contracts/collab";
import { CollabInputValidationError } from "@logic/CollabInputValidator";

// Components
import CollabCompTags from "@views/PaidCollab/CollabCompTags.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import CompedActivitiesTextarea from "@views/PaidCollab/CreateCollab/CompedActivitiesTextarea.vue";

export default defineComponent({
  name: "StepLocations",

  components: {
    CompedActivitiesTextarea,
    TransitionedHeightAuto,
    SrpCheckbox,
    IconEmbedded,
    CollabCompTags,
    NoteWithIcon,
  },

  props: {
    collabInput: { type: Object as () => CollabInput | null, required: true },
    validationResult: { type: Object as () => CollabInputValidationError | null, default: null },
    clearValidation: { type: Function, required: true },
    errors: { type: Array<string>, required: true },
  },

  data() {
    return {
      isRequiredLocationsTextareaVisible: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      (this.$refs.suggestedLocationsInput as HTMLInputElement)?.focus();
    });
  },
});
</script>

<style scoped lang="scss">
@import "./form-error-highlight.scss";
</style>
