<template>
  <div class="collab-drafts-list">
    <div class="collab-drafts-list__header">
      <h1 class="global-h1 collab-drafts-list__title">Use Collab Drafts</h1>
    </div>

    <ul class="collab-drafts-list__list-itself">
      <!-- Collab draft snippet -->
      <li class="collab-draft-snippet collab-drafts-list__collab-draft-snippet" v-for="collab in props.draftCollabs" :key="collab.id">
        <h3 class="global-h3 collab-draft-snippet__name">
          {{ getCollabNameWithFallback(collab.name) }}
        </h3>
        <div class="collab-draft-snippet__date">
          <IconEmbedded class="collab-draft-snippet__calendar-icon" name="calendar_2" :size="22" color="rgba(0, 0, 0, 0.5)" />
          {{ formatPreferredTimingDates(collab) }}
        </div>
        <SrpButton class="collab-draft-snippet__cta-button" size="small" @click="emit('click:getStarted', collab.id)">Get Started</SrpButton>
      </li>
      <!-- / Collab draft snippet -->
    </ul>
  </div>
</template>

<script setup lang="ts">
import { getCollabNameWithFallback, formatPreferredTimingDates } from "@helpers/CollabHelper";

// Types
import { CollabInput } from "@contracts/collab";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    customerId: string;
    draftCollabs: Array<CollabInput>;
  }>(),
  {
    customerId: "",
    draftCollabs: () => [],
  }
);

const emit = defineEmits<{
  (e: "click:getStarted", collabId: string): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Collab draft snippet =======================================================
.collab-draft-snippet {
  padding: 16px 22px 23px;
  border-radius: 6px;
  background: rgba(240, 240, 240, 1);

  &__name {
    margin-bottom: 6px;
    font-weight: 600;
  }

  &__date {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  &__calendar-icon {
    margin-right: 5px;
  }

  &__cta-button {
  }
}

// Collab drafts list =========================================================
.collab-drafts-list {
  &__header {
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__title {
  }

  &__list-itself {
    gap: $desktop-wide-grid-gap-width;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__collab-draft-snippet {
    width: calc(50% - $desktop-wide-grid-gap-width / 2);
  }
}
</style>
