<template>
  <div class="content-wrapper map-container">
    <GMapMap
      ref="mapRef"
      :style="`aspect-ratio: ${mapAspectRatio}; width: 100%; display: flex; justify-content: center; align-items: flex-start`"
      :center="{ lat: getLatitude, lng: getLongitude }"
      :options="{
        zoomControl: isWithZoomControl,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
      :zoom="getZoom"
    >
      <GMapMarker v-if="lat" :position="{ lat, lng }" :draggable="!onlyAllowDrawingMode" @dragend="markerMoved" />
      <GMapCircle
        v-if="showCircle && lat"
        :options="{
          strokeColor: '#FF0000',
          strokeOpacity: 0.2,
          strokeWeight: 1,
        }"
        :center="{ lat, lng }"
        :radius="circleRadiusInMeters"
      />
      <template v-if="isMapReady">
        <GMapMarker v-for="(location, i) in customerLocations" :key="`custloc-${i}`" :position="{ lat: location.lat, lng: location.lng }" :icon="customerMarkerIcon" />
      </template>
    </GMapMap>

    <template v-if="boundarySelectMode === 'draw' && !showBoundaryAsReadonly">
      <div v-if="!onlyAllowDrawingMode" style="position: absolute; right: 55px; top: 8px" data-name="re-draw button">
        <button v-if="drawingMode" class="ui small button" @click="setDrawingMode(false)">Change Pin Location</button>
        <button v-else class="ui small button" @click="redrawBoundary">Re-Draw Boundary</button>
      </div>
      <div v-else-if="!!onlyAllowDrawingMode || drawingMode" style="position: absolute; right: 55px; top: 8px"><button class="ui small button" @click="clearPolygon(true)">Clear Boundary</button></div>
    </template>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { inject, PropType } from "vue";
import { defineComponent } from "vue";
import { PageSummary } from "@contracts/pages";

// Inspired by: https://stackoverflow.com/a/32769692/336486
// Previous attemps used DrawingManager but that was overkill in the end
// https://developers.google.com/maps/documentation/javascript/reference/3.42/drawing?hl=en
// https://diegoazh.github.io/gmap-vue/examples/drawing-manager-with-slot.html#source-code

export default defineComponent({
  name: "DrawingMap",

  props: {
    lat: { type: null as PropType<number | null>, required: true },
    lng: { type: null as PropType<number | null>, required: true },
    inputGeoJson: { type: String, required: false },
    circleRadiusInMiles: { type: Number, required: true },
    // Custom Boundary or simple circle are the two MODES supported for the map.
    boundarySelectMode: { type: null as any, required: true },
    // Communities can move the marker around OR draw a polygon, creators can't move the marker (it's based on their location)
    onlyAllowDrawingMode: { type: Boolean, required: true },
    // We now show the boundary in the Visit Plan where it's not editable
    showBoundaryAsReadonly: { type: Boolean, default: false },
    defaultZoomLevel: { type: Number, default: 12 },
    isCreatorFlow: { type: Boolean, required: true },
    mapAspectRatio: { type: String, default: "1/1" },
    isWithZoomControl: { type: Boolean, default: true },
  },

  emits: ["boundaryChanged", "markerManuallyMoved", "mapReady"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      isMapReady: false,

      output: "",
      // Map Settings
      zoomLevel: 12, // Note: May be adjusted based on a bunch of conditions (see below)
      // NOTE: mapOptions are inline above (bc they reference mapDraggable)
      map: null as globalThis.google.maps.Map,
      // The marker isn't draggable in drawing mode so we need to let them toggle between
      drawingMode: true,
      // Variable to explicitly hide the circle under certain conditions
      hideCircle: false,

      // We show customer locations to the creator
      customerLocations: [],
      customerMarkerIcon: {} as globalThis.google.maps.Icon,
    };
  },

  computed: {
    // Needed when they don't have a location set. Roughly center of the US lat: 39.687664, lng: -95.253992
    getLatitude(): number {
      if (this.lat) return +this.lat;
      return 39.687664;
    },
    getLongitude(): number {
      if (this.lng) return +this.lng;
      return -95.253992;
    },
    getZoom(): number {
      if (this.lng) return this.zoomLevel;

      return 4; // zoomed out further since we don't know where they are yet
    },
    showCircle(): boolean {
      return this.boundarySelectMode === "radius";
    },
    circleRadiusInMeters(): number {
      return this.circleRadiusInMiles * 1609.34;
    },
  },

  watch: {
    // Little hacky but works
    circleRadiusInMiles() {
      this.circleRadiusChanged();
    },
    boundarySelectMode() {
      this.initialize();
    },
  },

  async mounted() {
    this.zoomLevel = this.defaultZoomLevel;

    (this.$refs.mapRef as any).$mapPromise.then(map => {
      this.isMapReady = true;
      this.$emit("mapReady");

      this.customerMarkerIcon = {
        url: "https://cdn.shrpa.com/images/shrpa-logo-border-white-150.png",
        scaledSize: new globalThis.google.maps.Size(20, 20),
      } as globalThis.google.maps.Icon;

      // Note: We hit an issue where navigating away and back would show the previous shape (it would save fine and a refresh would show it correctly).
      // It seems like the initializeGeoJson() call below is firing faster than the inputGeoJson prop is updated...
      // This is a bit of a hack but fixes it, could possibly put watch on the inputGeoJson prop instead.
      this.map = map;
      setTimeout(() => {
        this.map.data.setStyle({
          editable: true,
          // Don't make the boundary draggable (mobile is really difficult to navigate if the boundary is draggable)
          draggable: false,
        });
        this.bindDataLayerListeners(this.map);
        this.initialize();

        if (this.isCreatorFlow) this.loadCustomers();
      }, 500);
    });
  },

  methods: {
    initialize() {
      this.globalLog.info(`DrawingMap.Initialize: boundarySelectMode=${this.boundarySelectMode}`);
      let isInDrawingMode = this.boundarySelectMode === "draw";
      if (isInDrawingMode) {
        // Initialize the polygon
        const hasInputGeoJson = this.initializeGeoJson();
        if (hasInputGeoJson) {
          // Already have a polygon, set to adjusting mode
          this.setDrawingMode(false);
        } else {
          // Specifically setting drawing mode to Polygon (vs making the user pick)
          this.setDrawingMode(true);
        }
      } else {
        // Clear the polygon and turn off drawing mode. The circle will show automatically.
        this.clearPolygon(false);
      }

      if (this.showBoundaryAsReadonly) {
        this.map.data.setStyle({
          editable: false,
          draggable: false,
        });
      }

      if (!globalThis.google.maps?.geometry?.spherical) {
        // This seems to not always be initialized when we hit this page.
        console.info("Waiting for google.maps.geometry.spherical to initialize");
        setTimeout(() => {
          this.checkForZoomAdjust();
        }, 2000);
      } else {
        this.checkForZoomAdjust();
      }
    },

    async loadCustomers() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/pages/locations`);
      this.customerLocations = data;
    },
    checkForZoomAdjust() {
      // console.info("checkForZoomAdjust" + this.circleRadiusInMiles);
      if (!this.boundarySelectMode) this.zoomLevel = this.isCreatorFlow ? 7 : 11;
      else if (this.boundarySelectMode === "draw") {
        let area = this.getAreaInMiles();
        if (area === 0) this.zoomLevel = this.isCreatorFlow ? 7 : 11;
        else if (area < 50) this.zoomLevel = 12;
        else if (area < 1000) this.zoomLevel = 10;
        else if (area < 10000) this.zoomLevel = 9;
        else if (area < 50000) this.zoomLevel = 8;
        else if (area < 100000) this.zoomLevel = 7;
        else if (area < 500000) this.zoomLevel = 6;
        else if (area < 1000000) this.zoomLevel = 5;
        else this.zoomLevel = 4;
      } else {
        // If we're using withinMiles, we need to adjust the zoom level
        if (this.circleRadiusInMiles < 10) this.zoomLevel = 11;
        else if (this.circleRadiusInMiles < 21) this.zoomLevel = 10;
        else if (this.circleRadiusInMiles < 51) this.zoomLevel = 9;
        else if (this.circleRadiusInMiles < 100) this.zoomLevel = 8;
        else if (this.circleRadiusInMiles < 250) this.zoomLevel = 7;
        else if (this.circleRadiusInMiles < 600) this.zoomLevel = 6;
        else this.zoomLevel = 5;
      }
    },
    redrawBoundary() {
      // Clear any current polygons and return to drawing mode
      this.clearPolygon(true);
    },
    setDrawingMode(drawingMode: boolean) {
      if (drawingMode) {
        // Sets to active "drawing a polygon" mode
        this.map.data.setDrawingMode("Polygon");
      } else {
        // The user can drag the marker or adjust the polygon in this mode
        this.map.data.setDrawingMode(null);
      }
      this.drawingMode = drawingMode;
    },
    bindDataLayerListeners(map) {
      // NOTE! Google disables most of the typical events (ex. click) when drawing mode is enabled.
      map.data.addListener("addfeature", this.featureAddedOrMoved);
      map.data.addListener("setgeometry", this.featureAddedOrMoved);
      // map.data.addListener('removefeature', this.shapeChanged);
      // map.data.addListener('click', this.onclick);
    },
    // Initialize the input GeoJson, returns true if there was some, false if not
    initializeGeoJson(): boolean {
      if (this.inputGeoJson && this.inputGeoJson != null && this.inputGeoJson.length > 0) {
        const geoJSON = JSON.parse(this.inputGeoJson);
        this.map.data.addGeoJson(geoJSON);
        return true;
      }
      return false;
    },
    featureAddedOrMoved() {
      let featureCount = this.getFeatureCount();
      this.globalLog.info("Map.FeatureAddedOrMoved, Count=" + featureCount);
      // If there is more than 1 shape remove the 1st one
      if (featureCount > 1) {
        // Should only ever need to remove the 1st feature
        var isFirst = true;
        this.map.data.forEach(feature => {
          if (isFirst) {
            this.map.data.remove(feature);
          }
          isFirst = true;
        });
      }
      // Setting this to null moves to the "adjusting" mode vs. adding new points
      this.setDrawingMode(false);

      // Emit the geoJson
      this.map.data.toGeoJson(this.geoJsonGenerated);
    },
    circleRadiusChanged() {
      let hasPolygon = false;
      this.map.data?.forEach(element => {
        hasPolygon = true;
      });
      this.globalLog.info(`CircleRadiusChanged: Radius=${this.circleRadiusInMiles}, HasPolygon=${hasPolygon}`);
      // They could change this even if they have a polygon, in which case we basically ignore it
      if (!hasPolygon) {
        this.checkForZoomAdjust();
        let areaInMiles = this.getAreaInMiles();
        this.$emit("boundaryChanged", null, areaInMiles);
      }
    },
    getAreaInMiles(): number {
      try {
        if (this.boundarySelectMode === "radius") {
          // Calc area based on the circle (pi * r^2)
          let areaInMiles = Math.PI * this.circleRadiusInMiles * this.circleRadiusInMiles;
          this.globalLog.info(`getAreaInMiles of circle: ${areaInMiles}`);
          return areaInMiles;
        } else {
          // Calc area of the polygon
          // Just get the first feature (we only support a single polygon)
          let feature = null as globalThis.google.maps.Data.Feature;
          this.map.data.forEach(element => {
            feature = element;
          });
          if (feature?.getGeometry()?.getType() == "Polygon") {
            var bounds = [];
            feature.getGeometry().forEachLatLng(path => {
              bounds.push(path);
            });
            // Note: See the check in initialize
            if (!globalThis.google.maps?.geometry?.spherical) {
              console.warn("google.maps.geometry.spherical not initialized");
              return -1;
            } else {
              let areaInMeters = globalThis.google.maps?.geometry?.spherical.computeArea(bounds);
              // Convert to miles
              let areaInMiles = areaInMeters * 0.0000003861;
              this.globalLog.info(`getAreaInMiles of polygon: ${areaInMiles}`);
              return areaInMiles;
            }
          }
        }
      } catch (e) {
        this.globalLog.error(e);
      }
      return 0;
    },
    geoJsonGenerated(json) {
      const jsonString = JSON.stringify(json);
      var areaInMiles = this.getAreaInMiles();
      // this.globalLog.info(`AreaInMiles=${areaInMiles}`);
      // this.globalLog.info(jsonString);
      this.$emit("boundaryChanged", jsonString, areaInMiles);
    },
    markerMoved(location) {
      if (!this.onlyAllowDrawingMode) {
        this.globalLog.info(`Marker moved: lat=${location.latLng.lat()} lng=${location.latLng.lng()}`);
        this.$emit("markerManuallyMoved", location.latLng.lat(), location.latLng.lng());
      }
    },
    clearPolygon(returnToDrawingMode: boolean) {
      // Removes all shapes from the map
      this.map.data.forEach(feature => {
        this.map.data.remove(feature);
      });
      this.setDrawingMode(returnToDrawingMode);

      var areaInMiles = this.getAreaInMiles();
      this.$emit("boundaryChanged", null, areaInMiles);
    },
    getFeatureCount(): number {
      let featureCount = 0;
      this.map.data.forEach(feature => {
        featureCount++;
      });
      return featureCount;
    },
  },
});
</script>

<style scoped>
.map-container {
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  width: 100%;
}
</style>
