<template>
  <div class="collab-tiles-section">
    <div class="collab-tiles-section__header">
      <h3 class="global-h3 collab-tiles-section__title">My Collabs</h3>

      <SrpButton v-if="props.isAddMoreCollabsButtonVisible" class="collab-tiles-section__add-more-collabs-button" @click="emit('clickAddMoreCollabsButton')" size="small">
        <template #icon>
          <IconEmbedded name="plus_4" :size="18" />
        </template>
        Add More Creator Visits
      </SrpButton>
    </div>

    <ul class="collab-tiles-section__tiles-list">
      <template v-for="collabActionSummary in collabsToShow" :key="collabActionSummary.collabInputId">
        <CollabActionSummaryTile class="collab-tiles-section__tile" :customerCollabActionSummary="collabActionSummary" />
      </template>
    </ul>

    <ShowMoreBtnSnippet v-if="props.collabAndCreatorSummaryArray.length > collabsToShow.length" class="collab-tiles-section__show-all-btn" @click="showAll = true">
      View All
      <IconEmbedded name="caret-right_3-5" :size="18" color="rgba(120, 120, 120, 1)" style="position: relative; top: 3px; left: 5px" />
    </ShowMoreBtnSnippet>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

// Components
import CollabActionSummaryTile from "@components/collabSnippets/CollabActionSummaryTile.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import ShowMoreBtnSnippet from "@views/Creator/CreatorDashboard/snippets/ShowMoreBtnSnippet.vue";

// Types
import { CustomerCollabActionSummary } from "@contracts/collabActionSummaries";
import SrpButton from "@components/ui/SrpButton.vue";

const router = useRouter();

const props = withDefaults(
  defineProps<{
    collabAndCreatorSummaryArray: Array<CustomerCollabActionSummary> | null;
    isAddMoreCollabsButtonVisible: boolean;
  }>(),
  {
    collabAndCreatorSummaryArray: null,
    isAddMoreCollabsButtonVisible: false,
  }
);

const emit = defineEmits<{
  (e: "clickAddMoreCollabsButton"): void;
}>();

// Collabs to show ============================================================
// Only show those with activeOrRecentlyCompleted unless showAll is true
const showAll = ref(false);
const minimumToTryToShow = 4;

const collabsToShow = computed<Array<CustomerCollabActionSummary>>(() => {
  if (showAll.value) {
    return props.collabAndCreatorSummaryArray;
  } else {
    const filtered = props.collabAndCreatorSummaryArray.filter(collab => collab.activeOrRecentlyCompleted === true);
    // Default to showing some if they just have old completed collabs
    if (filtered.length <= minimumToTryToShow) {
      return props.collabAndCreatorSummaryArray.slice(0, minimumToTryToShow);
    }
    return filtered;
  }
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Collab tiles section =======================================================
.collab-tiles-section {
  &__header {
    margin-bottom: 17px;
    gap: 10px 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__title {
  }

  &__add-more-collabs-button {
  }

  &__tiles-list {
    padding: 0;
    margin: 0 0 30px;
    gap: 27px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__tile {
    width: calc(50% - 15px);
  }

  &__show-all-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .collab-tiles-section {
    &__tiles-list {
      gap: 24px;
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .collab-tiles-section {
    &__tiles-list {
      gap: 22px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-tiles-section {
    &__tiles-list {
      gap: 18px;
    }

    &__tile {
      width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-tiles-section {
    &__tiles-list {
      gap: 17px;
    }

    &__tile {
      width: 100%;
    }
  }
}
</style>
