export interface CustomCollab {
  id: number;
  customerId: string;
  customerPhoto: string;
  creatorName: string;
  creatorPhoto: string;
  messageThreadId: string;
  status: "Planning" | "InFlight" | "Complete";
  title: string | null;
  purpose: string;
  deliverables: string;
  cashComp: number;
  otherComp?: string;
  activityDate?: string;
  dueDate: string;
  creatorNotes: string | null;
  customerNotes: string | null;
  deliverableLink?: string;
  lastMessage?: string | null;
}

export const fakeCollabs: CustomCollab[] = [
  {
    id: 1,
    customerId: "funtown",
    customerPhoto: "7f675a26-424a-43fb-a44e-26b22ddbfa8e.png",
    creatorName: "sarah.anderson@example.com",
    creatorPhoto: "d7b5848c-7dce-44a3-aef3-1c8de4fa2d79.jpg",
    messageThreadId: "7ea7521b-0b68-4f27-9f24-a49e0459e85c",
    status: "Planning",
    lastMessage: "Yes! I found this amazing viewpoint that would be perfect for sunrise shots. Would you like to see some test shots?",
    title: null, // Planning collabs don't have titles yet
    purpose: "",
    deliverables: "",
    cashComp: 0,
    otherComp: "",
    activityDate: "",
    dueDate: "",
    creatorNotes: "",
    customerNotes: "",
  },
  {
    id: 2,
    customerId: "funtown",
    customerPhoto: "7f675a26-424a-43fb-a44e-26b22ddbfa8e.png",
    creatorName: "Sarah Anderson",
    creatorPhoto: "075378cf-8d73-40df-aa77-cbf6cd83adcf.jpg",
    messageThreadId: "7ea7521b-0b68-4f27-9f24-a49e0459e85c",
    title: "Promo Video for Summer Fest",
    status: "InFlight",
    purpose: "Highlight our upcoming Aurora Bay Fest!  A vibrant celebration of local arts, music, and cuisine.",
    deliverables: "25 photos\nOne Instagram post",
    cashComp: 250,
    otherComp: "2 nights at Grand Bay Hotel",
    activityDate: "2025-05-08",
    dueDate: "2025-05-30",
    creatorNotes: "I'm currently lining up the hotel and reviewing the festival schedule.",
    customerNotes: null,
  },
  {
    id: 3,
    customerId: "funtown",
    customerPhoto: "7f675a26-424a-43fb-a44e-26b22ddbfa8e.png",
    title: "Restaurant Week Hype",
    creatorName: "Emma Wilson",
    creatorPhoto: "4cf39ebb-8ae8-4da5-847b-6f4e5f12a2ca.jpg",
    messageThreadId: "7ea7521b-0b68-4f27-9f24-a49e0459e85c",
    status: "Complete",
    purpose: "Highlight our upcoming Restaurant Week! A celebration of local restaurants and their unique offerings.\nWe would like a blog post each week for 3 weeks.",
    deliverables: "3 blog posts with photos",
    lastMessage: null,
    cashComp: 375,
    dueDate: "2024-03-01",
    customerNotes: "",
    creatorNotes: "Final edits complete. All photos have been uploaded to the shared drive.",
    deliverableLink: "https://drive.google.com/drive/folders/1SnGbBFqq4itkriWt8pIoMeSebyXeNEIm",
  },
];
