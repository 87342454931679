import { socialMediaFieldNames, SocialMediaFields } from "@contracts/creatorPublicProfileFields";

export default {
  isAllEmpty(socialFields: SocialMediaFields): boolean {
    const _socialFields = {};

    socialMediaFieldNames.forEach(fieldName => (_socialFields[fieldName] = socialFields[fieldName]));

    return Object.values(_socialFields).every(f => !Boolean(f));
  },
};
