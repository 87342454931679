<template>
  <div class="plan-info">
    <!-- Plan info section -->
    <div class="plan-info-section plan-info__section">
      <div class="global-h5 plan-info-section__title">Creator Visits</div>

      <div class="plan-info-section__text">
        <template v-if="subscriptionNameLowerCased.includes('starter') || subscriptionNameLowerCased.includes('excursion')">
          <b>{{ props.totalCollabsForYearlySubscription }}</b> {{ `Creator Visit${props.totalCollabsForYearlySubscription > 1 ? "s" : ""}` }} + Creator Suite subscription
        </template>

        <template v-if="subscriptionNameLowerCased.includes('weekender')">
          <b>{{ props.totalCollabsForYearlySubscription }}</b> {{ `Creator Visit${props.totalCollabsForYearlySubscription > 1 ? "s" : ""}` }} + Creator Suite subscription + Priority Support
        </template>

        <template v-if="subscriptionNameLowerCased.includes('destination')">
          <b>{{ props.totalCollabsForYearlySubscription }}</b> {{ `Creator Visit${props.totalCollabsForYearlySubscription > 1 ? "s" : ""}` }} + 1 year of Creator Suite + Premium Support + Collab
          Concierge
        </template>
      </div>
    </div>
    <!-- / Plan info section -->

    <div class="plan-info__divider"></div>

    <!-- Plan info section -->
    <div class="plan-info-section plan-info__section">
      <div class="global-h5 plan-info-section__title">Inspiration Source</div>
      <div class="plan-info-section__text">Access creative concepts, industry trends, and success stories to fuel your collaborations.</div>
    </div>
    <!-- / Plan info section -->

    <div class="plan-info__divider"></div>

    <!-- Plan info section -->
    <div class="plan-info-section plan-info__section">
      <div class="global-h5 plan-info-section__title">Collaboration Hub</div>
      <div class="plan-info-section__text">Manage and track collaborations with seamless coordination and unified communication tools</div>
    </div>
    <!-- / Plan info section -->

    <div class="plan-info__divider"></div>

    <!-- Plan info section -->
    <div class="plan-info-section plan-info__section">
      <div class="global-h5 plan-info-section__title">Content Outpost</div>
      <div class="plan-info-section__text">
        <p class="global-p">Organize, enhance, and share your content effortlessly with tools for websites, social media, and flyers.</p>
      </div>
    </div>
    <!-- / Plan info section -->
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    totalCollabsForYearlySubscription: number;
    subscriptionName: string;
  }>(),
  {
    totalCollabsForYearlySubscription: 0,
    subscriptionName: "",
  }
);

// Subscription name lowerCased ===============================================
const subscriptionNameLowerCased = computed<string>(() => props.subscriptionName.toLowerCase());
</script>

<style scoped lang="scss">
@import "./plan-info.scss";
</style>
