<template>
  <teleport v-if="isGridOverlayVisible" to="body">
    <GridDevOverlay
      style="position: fixed; inset: 0 auto auto 0; z-index: 9999999999999999999999999999999999999"
      :is-columns-mode="isGridOverlayColumnsMode"
      :right-col-shift="gridOverlayRightColShift"
    />
  </teleport>

  <FloatingProblemBadges ref="domRefFloatingProblemBadges" />

  <ToastsList />
  <div>
    <!-- helpButtonZindex is needed to "hide" the button (without removing it from the DOM because otherwise it would be impossible to track it's position and detect whether it intersects other elements) from time to time when it overlaps other important elements on the page -->
    <HelpFloatingButton v-if="isHelpFloatingButtonVisible" class="help-floating-button" :style="{ zIndex: helpButtonZindex }" />
    <LoginModal :isVisible="isShowLoginModal" @close="isShowLoginModal = false" />

    <div class="pusher">
      <!--Allow pages to hide the nav (not the most elegant but works)-->
      <div
        v-if="showNav"
        class="ui container top-nav"
        :class="{
          wide: showAdminLeftNav || showCreatorLeftNav,
          'main-menu-container-wide': showAdminLeftNav || showCreatorLeftNav,
        }"
      >
        <div class="ui text menu main-menu">
          <div class="left menu" style="align-items: center">
            <div class="business-name">
              <img
                v-if="globalRoot.orgInContext?.isSpotlight === true || $route.path === '/spotlight' || $route.name === 'SpotlightOnboard'"
                class="ui small image"
                src="https://cdn.shrpa.com/images/spotlight-logo1.png"
                alt="shrpa - Explore Local"
              />
              <RouterLink v-else style="cursor: pointer" lang="en" hreflang="en" :to="{ name: 'Home' }">
                <img class="ui small image" src="https://cdn.shrpa.com/images/shrpa-full-color.png" alt="shrpa - Explore Local" />
              </RouterLink>
            </div>
            <div v-if="showOrgInContextLogo && !isHeaderSimplified" style="display: flex; align-items: center">
              <i class="plus icon" style="font-size: 20px; color: #058587; margin: 0 30px"></i>
              <a @click.prevent="logoClicked()" style="cursor: pointer" lang="en" hreflang="en">
                <img :src="`${contentBaseUri}/cms/images/orgs/${globalRoot.orgInContext.logoUri}`" class="partner-img" alt="partner logo" />
              </a>
              <!--Note: This class is used as a selector and setup in the Frill UI, so don't change it
                  Hiding on mobile until they ship the fix to attach to multiple elements.
              <i class="large bell outline icon shrpa-whats-new hide-on-desktop" style="cursor:pointer; margin-left:1rem;"></i>-->
            </div>
          </div>
          <template v-if="!isHeaderSimplified">
            <!--Top Nav Full-->
            <!--Unauthenticated-->
            <div v-if="!globalAuth.isAuthenticated" class="item menu-items right floated hide-on-mobile">
              <RouterLink lang="en" hreflang="en" :to="{ name: 'Home' }" class="item hidden-menu">Home</RouterLink>
              <a lang="en" hreflang="en" :href="globalGetLink('ForCommunities')" class="item hidden-menu">For Destinations</a>
              <a lang="en" hreflang="en" :href="globalGetLink('ForCreators')" class="item hidden-menu">For Creators</a>
              <!--Auth/Login logic-->
              <a lang="en" hreflang="en" @click="showLoginModal()" class="item hidden-menu">Log in</a>
            </div>
            <!--Authenticated-->
            <div v-else class="item menu-items right floated hide-on-mobile">
              <TrialDaysCounter style="margin-right: 20px" :trialEndDate="globalRoot.orgInContext?.trialEndDate" :paymentStatus="globalRoot.orgInContext?.paymentStatus" />
              <!--Customer Dashboard -->
              <RouterLink lang="en" hreflang="en" :to="dashboardLink" class="item hidden-menu"> Dashboard </RouterLink>
              <a v-if="showCreateButton" lang="en" hreflang="en" class="item hidden-menu" @click="createClicked()">Create</a>
              <!--Auth/Login logic-->
              <a lang="en" hreflang="en" v-if="!globalAuth.loading && !globalAuth.isAuthenticated" @click="showLoginModal()" class="item hidden-menu">Log in</a>
              <RouterLink lang="en" hreflang="en" v-else-if="getViewingUserProfile" :to="profileLink" class="ui item hidden-menu circular image">
                <i @click.prevent="stopImpersonation" v-if="isImpersonating" class="close icon" style="position: absolute; margin-top: -38px; margin-left: 41px"></i>
                <img v-if="getViewingUserProfile" :src="(getViewingUserProfile.imageIsExternal ? '' : `${contentBaseUri}/cms/images/profiles/`) + getViewingUserProfile.tileImageLocation" />
                <div v-else>Profile</div>
              </RouterLink>
              <a lang="en" hreflang="en" v-else class="item hidden-menu">
                <i class="spinner icon"></i>
              </a>
              <!--<a v-if="showSettings" lang="en" hreflang="en" @click="settingsClicked()">
              <i class="large cog icon hide-on-mobile" style="cursor: pointer; margin-left: 1rem; opacity: 0.7"></i>
            </a>-->
              <!--Note: This class is used as a selector and setup in the Frill UI, so don't change it-->
              <i v-if="globalRoot.orgInContext?.isSpotlight === false" class="large bell outline icon shrpa-whats-new hide-on-mobile" style="cursor: pointer; margin-left: 1rem"></i>
            </div>
          </template>
        </div>
      </div>

      <!--Content (and left nav)-->
      <div v-if="showAdminLeftNav || showCreatorLeftNav" class="ui stackable two column grid container wide page-content">
        <div
          :class="{
            'three wide column left-nav': windowInnerWidth >= 1240,
            'sixteen wide column left-nav': windowInnerWidth < 1240,
          }"
          style="position: relative; z-index: 2"
        >
          <CustomerAdminLeftNav v-if="showAdminLeftNav" class="customer-admin-left-nav" />
          <CreatorLeftNav v-else-if="showCreatorLeftNav" class="customer-admin-left-nav" />
        </div>
        <div
          id="content-column"
          :class="{
            'thirteen wide column': windowInnerWidth >= 1240,
            'sixteen wide column left-nav': windowInnerWidth < 1240,
          }"
        >
          <!--Key is needed to update views with different routes
            <router-view :key="$route.path"></router-view>-->
          <RouterView />
        </div>
      </div>
      <!--Router above shows for admin pages, this is used everywhere else-->
      <RouterView v-else />

      <!--We're using frill.co for what's new-->
      <FrillWidget />

      <footer v-if="showNav && showFooter" class="nav-footer">
        <div class="ui container">
          <div v-if="globalRoot.orgInContext?.isSpotlight === false" class="footer-columns">
            <!--Footer Nav-->
            <ul class="footer-links">
              <li><a lang="en" hreflang="en" :href="globalGetLink('ForCommunities')">For Destinations</a></li>
              <li><a lang="en" hreflang="en" :href="globalGetLink('ForCreators')">For Creators</a></li>
              <li><RouterLink lang="en" hreflang="en" :to="{ name: 'Contact' }">Contact</RouterLink></li>
              <!--<li><RouterLink lang="en" hreflang="en" :to="{ name: 'BecomeAGuide' }">About</RouterLink></li>-->
              <!--Note: This class is used as a selector and setup in the Frill UI, so don't change it.
                The Frill css selector only seems to attach to the first one so leaving this out for now (could probably manually fire it)
              <li><span class="shrpa-whats-new" style="cursor:pointer">What's New</span></li>-->
            </ul>
            <ul class="footer-links">
              <li><RouterLink lang="en" hreflang="en" :to="{ name: 'Home' }">Shrpa Home</RouterLink></li>
              <li>
                <a lang="en" hreflang="en" target="_blank" :href="globalGetLink('Instagram')" class="Instagram-icon">
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                    ></path>
                  </svg>
                  Instagram
                </a>
              </li>
              <li>
                <a lang="en" hreflang="en" target="_blank" :href="globalGetLink('Facebook')" class="Facebook-icon">
                  <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M19,4V7H17A1,1 0 0,0 16,8V10H19V13H16V20H13V13H11V10H13V7.5C13,5.56 14.57,4 16.5,4M20,2H4A2,2 0 0,0 2,4V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V4C22,2.89 21.1,2 20,2Z"
                    ></path>
                  </svg>
                  Facebook
                </a>
              </li>

              <li><a lang="en" hreflang="en" v-if="!globalAuth.loading && globalAuth.isAuthenticated" @click="logout">Log out</a></li>
              <li><RouterLink :to="{ name: 'AdminIndex' }" lang="en" hreflang="en" v-if="getActingUserProfile?.isSuperuser">Super</RouterLink></li>
              <li v-if="getActingUserProfile?.isSuperuser" style="user-select: none; cursor: pointer" @click="isActivityFeedFlyoutVisible = true">Activity Feed</li>

              <SideFlyout v-model:isVisible="isActivityFeedFlyoutVisible" width="700" title="Activity Feed" side="right">
                <div class="activity-feed-wrap">
                  <ActivityFeed :isWithH1="false" @clickApply="isActivityFeedFlyoutVisible = false" />
                </div>
              </SideFlyout>

              <li><RouterLink :to="{ name: 'AdminIndex' }" lang="en" hreflang="en" v-if="getActingUserProfile?.isSalesUser">Sales User</RouterLink></li>
            </ul>
          </div>
          <p class="copyright">
            © {{ new Date().getFullYear() }} shrpa • Explore Local • <RouterLink lang="en" hreflang="en" :to="{ name: 'Terms' }">Terms</RouterLink> &amp;
            <RouterLink lang="en" hreflang="en" :to="{ name: 'Privacy' }">Privacy</RouterLink>
          </p>
        </div>
      </footer>

      <div id="addToHome" class="addToHome" style="display: none">
        <div class="ui container">
          <div
            class="ui basic center aligned segment add-to-home"
            style="position: fixed; left: 10%; right: 10%; bottom: 140px; z-index: 100; padding: 20px; background: #ffffff; border: 3px solid rgba(5, 133, 135, 0.75)"
          >
            <div style="width: 100%; text-align: right">
              <h3 onclick="closePwaPrompt()" style="padding: 0px 10px">X</h3>
            </div>
            <div id="androidPwaPrompt" style="display: none">
              <h3 class="ui header">Add shrpa to your home screen! (like an App)</h3>
              <a lang="en" hreflang="en" class="ui orange large button" onclick="chromePwaPromptClicked()">Add to Home Screen</a>
            </div>
            <div id="iosSafariPwaPrompt" style="display: none">
              <h3 class="ui header">Add shrpa to your home screen! (like an App)</h3>
              <h3 class="ui header">Just click on the Share icon below and scroll right until you find the Add to Home Screen</h3>
            </div>
            <div id="iosChromePwaPrompt" style="display: none">
              <h3 class="ui header">Add shrpa to your home screen! (like an App)</h3>
              <div class="ui header">
                You need to open shrpa in Sarafi. Click here:
                <div id="shrpaInSafari" data-href="https://shrpa.com">Shrpa in Safari</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Mobile Nav-->
      <div v-if="showNav && showFooter" class="sticky-menu">
        <!--Unauthenticated-->
        <template v-if="!globalAuth.isAuthenticated">
          <!--Hide if Spotlight-->
          <template v-if="!isHeaderSimplified">
            <RouterLink lang="en" hreflang="en" :to="{ name: 'Home' }" class="item home">
              <i class="icon home"></i>
              <div class="mobile-menu-label">Home</div>
            </RouterLink>
            <a lang="en" hreflang="en" class="item share" :href="globalGetLink('ForCommunities')">
              <i class="icon map signs"></i>
              <div class="mobile-menu-label">Destinations</div>
            </a>
            <a lang="en" hreflang="en" class="item share" :href="globalGetLink('ForCreators')">
              <i class="edit icon"></i>
              <div class="mobile-menu-label">Creators</div>
            </a>
            <!--Auth/Login logic-->
            <a lang="en" hreflang="en" @click="showLoginModal()" class="item login">
              <i class="arrow alternate circle right outline icon"></i>
              <div class="mobile-menu-label">Log in</div>
            </a>
          </template>
        </template>
        <!--Authenticated-->
        <template v-else>
          <!-- Dashboard -->
          <RouterLink lang="en" hreflang="en" class="item more" :to="dashboardLink">
            <i class="icon chart line"></i>
            <div class="mobile-menu-label">Dashboard</div>
          </RouterLink>
          <a v-if="showCreateButton" lang="en" hreflang="en" class="item share" @click="createClicked()">
            <i class="icon edit"></i>
            <div class="mobile-menu-label">Create</div>
          </a>
          <!--Auth/Login logic-->
          <a lang="en" hreflang="en" v-if="!globalAuth.loading && !globalAuth.isAuthenticated" @click="showLoginModal()" class="item login">
            <i class="arrow alternate circle right outline icon"></i>
            <div class="mobile-menu-label">Log in</div>
          </a>
          <RouterLink lang="en" hreflang="en" v-else-if="getViewingUserProfile" :to="profileLink" class="item login">
            <i @click.prevent="stopImpersonation" v-if="isImpersonating" class="close small icon" style="position: absolute; margin-top: -5px; margin-left: 10px"></i>
            <img
              v-if="getViewingUserProfile"
              class="ui circular image centered"
              :src="(getViewingUserProfile.imageIsExternal ? '' : `${contentBaseUri}/cms/images/profiles/`) + getViewingUserProfile.tileImageLocation"
            />
            <div class="mobile-menu-label">Profile</div>
          </RouterLink>
          <a v-else class="item login">
            <i class="spinner icon"></i>
            <div class="mobile-menu-label">Loading...</div>
          </a>
          <!--My Page-->
          <RouterLink lang="en" hreflang="en" v-if="myPageLink" :to="myPageLink" class="item home">
            <i class="icon home"></i>
            <div class="mobile-menu-label">My Page</div>
          </RouterLink>
        </template>
      </div>
      <div id="pwaDebugDiv" style="display: none"></div>
    </div>
  </div>

  <SrpModal v-model:isVisible="isBrowserIncompatibilityModalVisible" :isClosable="false" :isCloseButtonVisible="false">
    <template #content>
      <div :style="{ padding: screenSize === 'mobile' ? '10px' : '30px' }">
        <BrowserIsIncompatibleInfo />
      </div>
    </template>
  </SrpModal>
</template>

<script lang="ts">
import { defineComponent, inject, provide, ref, onMounted, onBeforeUnmount } from "vue";
import axios from "axios";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import { getInstance } from "@auth/authWrapper";
import { RouteHelper } from "@helpers/RouteHelper";
import Logger from "./helpers/Logger";
import { mapActions, mapState } from "pinia";
import { MetricSender } from "@helpers/MetricSender";
import { useHead, useSeoMeta } from "@unhead/vue";

// Types
import { ScreenSize } from "@contracts/screenSize";
import { UserProfile } from "@contracts/userProfile";

// Components
import ActivityFeed from "@components/ActivityFeed/index.vue";
import BrowserIsIncompatibleInfo from "@components/BrowserIsIncompatibleInfo.vue";
import CustomerAdminLeftNav from "@components/Navigation/CustomerAdminLeftNav.vue";
import CreatorLeftNav from "@components/Navigation/CreatorLeftNav.vue";
import FloatingProblemBadges from "@components/FloatingProblemBadges.vue";
import FrillWidget from "@components/FrillWidget.vue";
import GridDevOverlay from "@components/ui/GridDevOverlay.vue";
import HelpFloatingButton from "@components/HelpFloatingButton.vue";
import LoginModal from "@components/Modals/LoginModal.vue";
import SideFlyout from "@components/ui/SideFlyout.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { ToastsList } from "@components/ToastsList";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";
import { useMiscellaneousStore } from "@stores/miscellaneous";

// Logic
import { setChurnZeroContactAndTrackLogInForCreator } from "@logic/ChurnZero";
import OrgContext from "@logic/OrgContext";
import AdminContext from "@logic/AdminContext";
import TrialDaysCounter from "@components/TrialDaysCounter.vue";
import { PartnerNavInfo } from "./contracts/partnerNavInfo";

export default defineComponent({
  name: "NavigationComponent",

  components: {
    TrialDaysCounter,
    ActivityFeed,
    BrowserIsIncompatibleInfo,
    CustomerAdminLeftNav,
    CreatorLeftNav,
    FloatingProblemBadges,
    FrillWidget,
    GridDevOverlay,
    HelpFloatingButton,
    LoginModal,
    SideFlyout,
    SrpModal,
    ToastsList,
  },

  setup() {
    const screenSize = ref<ScreenSize>("desktop");
    const screenSizeUpdateKey = ref<symbol>(Symbol());

    const gridContentFullWidth = ref<string>("0px");
    const gridColWidth = ref<string>("0px");
    const gridColGapWidth = ref<string>("0px");

    let updateKeyTimeout = null;

    const domRefFloatingProblemBadges = ref<any>(null);

    onMounted(() => {
      setScreenRanges();
      window.addEventListener("resize", setScreenRanges);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", setScreenRanges);
    });

    provide("screenSize", screenSize);
    provide("screenSizeUpdateKey", screenSizeUpdateKey);
    provide("domRefFloatingProblemBadges", domRefFloatingProblemBadges);

    provide("gridContentFullWidth", gridContentFullWidth);
    provide("gridColWidth", gridColWidth);
    provide("gridColGapWidth", gridColGapWidth);

    function setScreenRanges() {
      const w = window.innerWidth;

      if (w >= 1700) screenSize.value = "desktop-wide";
      else if (w >= 1440 && w <= 1699) screenSize.value = "desktop";
      else if (w >= 1240 && w <= 1439) screenSize.value = "laptop";
      else if (w >= 905 && w <= 1239) screenSize.value = "tablet-large";
      else if (w >= 600 && w <= 904) screenSize.value = "tablet";
      else if (w <= 599) screenSize.value = "mobile";

      gridColWidth.value = {
        "desktop-wide": "100px",
        desktop: "81px",
        laptop: "70px",
        "tablet-large": "76px",
        tablet: "47px",
        mobile: "calc((100vw - 18px - 19px - 17px * 5) / 6)",
      }[screenSize.value];

      gridColGapWidth.value = {
        "desktop-wide": "27px",
        desktop: "27px",
        laptop: "24px",
        "tablet-large": "22px",
        tablet: "18px",
        mobile: "17px",
      }[screenSize.value];

      gridContentFullWidth.value = {
        "desktop-wide": "1497px",
        desktop: "1269px",
        laptop: "1104px",
        "tablet-large": "762px",
        tablet: "502px",
        mobile: "calc(100vw - 18px - 19px)",
      }[screenSize.value];

      clearTimeout(updateKeyTimeout);
      updateKeyTimeout = setTimeout(() => (screenSizeUpdateKey.value = Symbol()), 100);
    }

    return {
      screenSize,
      domRefFloatingProblemBadges,
    };
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,
      globalGetLink: inject("globalGetLink") as any,

      authService: {} as any,
      // Keeps us from calling this multiple times
      loadProfileCalled: false,

      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,
      // Note: The profile data is pulled from getViewingUserProfile
      // partnerInfo was replaced by globalRoot.orgInContext
      isShowLoginModal: false, // True/False for show login modal

      windowInnerWidth: 0,

      isGridOverlayVisible: false,
      isGridOverlayColumnsMode: false,
      gridOverlayRightColShift: 0,

      isActivityFeedFlyoutVisible: false,

      isBrowserIncompatibilityModalVisible: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile", "isImpersonating"]),
    ...mapState(useMiscellaneousStore, ["helpButtonZindex"]),
    isHeaderSimplified(): boolean {
      return this.$route.name === "SpotlightOnboard" || (this.$route.path === "/spotlight" && !this.authService?.isAuthenticated);
    },
    showNav(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return (
        this.$route.name !== "ItinerarySteps" &&
        this.$route.name !== "Map" &&
        this.$route.name !== "TwoSidedMetricsMap" &&
        this.$route.name !== "ItineraryStepsV2" &&
        this.$route.name !== "CommunitiesMap" &&
        this.$route.name !== "PaidCreatorsMap" &&
        this.$route.name !== "NonUserPhotoUpload"
      );
    },
    showFooter(): boolean {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$route.name !== "CmsEditorV2" && this.$route.name !== "TwoSidedMetricsMap" && this.$route.name !== "SpotlightOnboard";
    },
    showSettings(): boolean {
      return this.getViewingUserProfile?.adminOf?.length > 0;
    },
    showOrgInContextLogo(): boolean {
      // Need to have a logo to show from an org in context
      if (this.globalRoot.orgInContext?.logoUri && this.globalRoot.orgInContext?.isSpotlight === false) {
        // If we're impersonating and the user is not an admin, then hide it
        if (this.isImpersonating && this.getViewingUserProfile?.adminOf?.length === 0) {
          return false;
        }
        // Otherwise show it
        return true;
      }

      return false;
    },
    dashboardLink() {
      if (this.getViewingUserProfile?.adminOf?.length > 0) return { name: "CommunityAdminDashboard" };
      else return { name: "CreatorDashboard", params: { creatorId: this.getViewingUserProfile?.sherpaId } };
    },
    profileLink() {
      if (this.getViewingUserProfile?.adminOf?.length > 0) return { name: "CustomerAdminProfile", params: { creatorId: this.getViewingUserProfile?.sherpaId } };
      else return { name: "CreatorProfilePublic", params: { creatorId: this.getViewingUserProfile?.sherpaId } };
    },
    myPageLink(): any {
      if (this.getViewingUserProfile) return null;

      if (this.getViewingUserProfile?.adminOf?.length > 0) {
        var orgInContext = OrgContext.getOrgInContext(this);
        // Community or Hotel in context
        if (orgInContext) {
          return {
            name: "Pages",
            params: { pageId: orgInContext.uniqueName },
          };
        } else {
          // Otherwise home is the Shrpa homepage
          return { name: "Home" };
        }
      } else {
        // Show the creators public profile
        let currentProfile = this.getViewingUserProfile;
        if (currentProfile) return { name: "CreatorProfilePublic", params: { creatorId: currentProfile.sherpaId } };
        else return { name: "Home" };
      }
    },
    isHelpFloatingButtonVisible(): boolean {
      // Show on all the admin pages
      if (this.$route.path.startsWith("/admin") || this.$route.path.startsWith("/settings")) return true;
      // We also show this on the creator side now (dashboard, profile, CMS)
      // Note: Ideally we should make the creator paths more consistent
      var showToCreator =
        (this.$route.path.startsWith("/content/") || this.$route.path.startsWith("/creator/") || this.$route.path.startsWith("/creatordashboard") || this.$route.path.startsWith("/profile")) &&
        this.$route.name !== "CreatorProfilePublic"; // Exclusions (route names from index.ts)
      if (showToCreator) return true;

      return false;
    },
    showAdminLeftNav(): boolean {
      // The CustomerAdminLeftNav controls whether to show the settings or admin nav
      return (
        (this.$route.path.startsWith("/admin") ||
          this.$route.name === "CommunityAdminDashboard" ||
          this.$route.path.startsWith("/start") ||
          this.$route.path.startsWith("/settings") ||
          this.$route.path.startsWith("/saved-creators") ||
          // If you're an admin, always show the admin nav, even for the creator pages.
          (this.getViewingUserProfile?.adminOf?.length > 0 && this.showCreatorLeftNav)) &&
        !this.$route.path.startsWith("/SpotlightOnboard")
      );
    },
    currentUserIsCustomerAdmin(): boolean {
      return this.getViewingUserProfile?.adminOf?.length > 0;
    },
    showCreateButton(): boolean {
      // if (this.currentUserIsCustomerAdmin) return false;
      // Always false now that the new collab flow is in place.
      return false;
    },
    showCreatorLeftNav(): boolean {
      return (
        this.$route.name != "CreatorProfilePublic" &&
        this.$route.name != "RecentCreatorVisits" &&
        this.$route.name != "SpotlightOnboard" &&
        (this.$route.path.startsWith("/creator") || this.$route.path.startsWith("/content") || this.$route.path.startsWith("/profile"))
      );
    },
  },

  watch: {
    // Auth runs async so we need this in case mounted() completes first (often happens when logging in)
    "authService.loading": {
      handler(value): void {
        if (value === false) {
          Logger.info(`AuthService: LoadingComplete`);
          this.appLevelProfileSetup();
        }
      },
    },
  },

  beforeMount() {
    // both useHead and useSeoMeta should be places in a SYNC lifecycle hook in order to not override the useHead useSeoMeta calls in child components up the tree
    useHead({
      title: "Shrpa",
      meta: [{ name: "description", content: "Shrpa helps you discover and highlight the best places in your community!" }],
    });

    useSeoMeta({
      ogTitle: "Shrpa",
      ogImage: `${globalThis.Bootstrap.Config.contentCdnBaseUri}/images/shrpa-full-color.png`,
      ogSiteName: "Shrpa",
      ogType: "website",
      ogDescription: "Shrpa helps you discover and highlight the best places in your community!",
      fbAppId: `${import.meta.env.VITE_FACEBOOK_APP_ID}`,
      robots: "index,follow",
    });
  },

  async mounted() {
    this.checkForReset();
    this.rehydrateFromLocalStore();
    this.loadClientSettings();
    this.authService = getInstance();
    if (this.authService.loading === false) {
      Logger.info(`App mount: AuthService already loaded`);
      this.appLevelProfileSetup();
    }

    // Setup the partner nav link if needed
    // Note: the profile might not be loaded yet, but this gets called again once it is
    this.globalRoot.orgInContext = await AdminContext.defaultOrgInContext();

    this.handleResize();
    window.addEventListener("resize", this.handleResize);

    globalThis.toggleGridOverlay = this.toggleGridOverlay;
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    ...mapActions(useUserProfileStore, ["setProfileForCurrentUser", "stopImpersonation"]),
    toggleGridOverlay(isVisible, isColumnsMode = true, rightColShift = 0) {
      this.isGridOverlayVisible = isVisible;
      this.isGridOverlayColumnsMode = isColumnsMode;
      this.gridOverlayRightColShift = rightColShift;
    },
    handleResize() {
      this.windowInnerWidth = window.innerWidth;
    },
    logoClicked() {
      // Where the user goes depends on how they navigated here
      var orgInContext = OrgContext.getOrgInContext(this);
      if (orgInContext?.siteUri) {
        // Site Integration
        // This is pivoted off of how we set the orgInContext, which is fairly indirect, should improve...
        window.location.href = orgInContext.siteUri;
      } else {
        // Update: Going to the Destination page now since we have an explicit Dashboard link
        this.$router.push({
          name: "Pages",
          params: { pageId: orgInContext.uniqueName },
        });
      }
    },
    settingsClicked() {
      var orgInContext = OrgContext.getOrgInContext(this);
      this.$router.push({
        name: "CommunityPageAdmin",
        params: { pageId: orgInContext.uniqueName },
      });
    },
    async loadClientSettings() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/config/settings`);
      if (data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.globalRoot.settings = data;
      }
    },
    async appLevelProfileSetup() {
      // Note: This shouldn't fire until the autoProvision code has completed,
      // since the watch checks isLoading which doesn't get set to true until after that call.
      // That watch and the call from mounted() can fire at once so protecting against running this twice.
      if (this.loadProfileCalled) return;
      this.loadProfileCalled = true;

      const authService = getInstance();
      Logger.info(`appLevelProfileSetup: isAuthenticated=${authService.isAuthenticated}`);
      if (authService.isAuthenticated) {
        /* Note: No longer calling this since it happens in the authWrapper now
        if (!this.getActingUserProfile) {
          await this.setProfileForCurrentUser();
        }*/
        if (this.getActingUserProfile) {
          Logger.info(`appLevelProfileSetup: Root profile set to ${this.getActingUserProfile.authNameId}`);
          // Default the orgInContext in case they're an admin
          const defaultOrg = await AdminContext.defaultOrgInContext();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.globalRoot.orgInContext = defaultOrg;

          // Further attribute the user for user in metrics
          const userType = this.identifyUserForMetrics(this.getActingUserProfile, defaultOrg);

          this.globalRemoteLogger.info(
            `UserLogin and ProfileLoaded: userType=${userType}, ${this.getActingUserProfile?.authNameId}, ${this.getActingUserProfile?.sherpaId}, ${this.getActingUserProfile?.firstName}, defaultOrg=${defaultOrg?.customerId}`,
            true
          );

          // Check for blocked browsers
          if (await this.checkForBlockedBrowser()) return;
          else {
            // ChurnZero setup (this is mostly for creators, the admin flow sets up after we have a customer in context)
            setChurnZeroContactAndTrackLogInForCreator();
            // Note: Not sure if this is necessary...
            await this.$router.isReady();
            console.info(`App.loadProfile: Router current path: ${this.$router.currentRoute.value.fullPath}`);
            // ---Redirects--- (drop certain users to their dashboard if they come into the root homepage)
            if (this.$router.currentRoute.value.name === "Home" || this.$router.currentRoute.value.name === "AuthCallback") {
              // First check to see if they came into a specific page before doing the auth flow
              const redirectPath = sessionStorage.getItem("redirectPath");
              if (redirectPath) {
                this.globalLog.info(`Redirecting to redirectPath: ${redirectPath}`);
                sessionStorage.removeItem("redirectPath");
                // @ts-ignore
                window.location.href = redirectPath;
              }
              // Admins and creators go to their respective dashboard
              else if (this.getActingUserProfile.adminOf?.length > 0) {
                this.globalLog.info(`Redirecting to community dashboard on login`);
                this.$router.push({ name: "CommunityAdminDashboard" });
              } else if (this.getActingUserProfile.sherpaId?.trim().length > 0) {
                this.globalLog.info(`Redirecting to creator dashboard on login`);
                // Note: The profile may not be loaded yet so we can't specify the creatorId param for this route
                this.$router.push({ name: "CreatorDashboard", params: { creatorId: this.getActingUserProfile.sherpaId } });
              }
            }
          }
        }
      }
    },
    identifyUserForMetrics(profile: UserProfile, defaultOrg: PartnerNavInfo | null): string {
      try {
        let type = "traveler";
        if (profile.isSuperuser || profile.isSalesUser) type = "super";
        else if (defaultOrg?.isSpotlight === true) type = "spotlight";
        else if (profile.adminOf?.length > 0) type = "customer";
        else if (profile.collabPreferences?.isCollabEligible === true) type = "creator";
        else if (profile.collabPreferences?.becomingCollabEligible === true) type = "interested";
        MetricSender.identify(profile.sherpaId, profile.email, profile.firstName, profile.authNameId, type);
        // UserType can change once they're eligible. Setting a session property so we can filter replays.
        if (type === "interested") MetricSender.setPropertyOnSession("Creator-Onboarding", true);

        return type;
      } catch (ex) {
        this.globalLog.error(`setUserType FAILED! ${ex.message}`);
        return "error";
      }
    },
    // Returns true if blocked
    async checkForBlockedBrowser(): Promise<boolean> {
      const isBraveBrowser = navigator.brave && (await navigator.brave.isBrave());
      const isSamsungBrowser = navigator.userAgent.includes("SamsungBrowser");

      // Check if Brave browser or Samsung browser used
      if (isBraveBrowser || isSamsungBrowser) {
        const userAuthId = getInstance()?.getUser()?.sub ?? "unknown";
        const browser = isBraveBrowser ? "Brave" : "Samsung";
        getGlobalRemoteLogger().warn(`BlockedBrowser: ${browser}, authId: ${this.getActingUserProfile?.authNameId}, creatorId: ${this.getActingUserProfile?.sherpaId}`, true, true, {
          browser: browser,
          userAuthId: userAuthId,
        });
        this.isBrowserIncompatibilityModalVisible = true;
        return true;
      }

      return false;
    },
    createClicked() {
      // If they're not logged in, show the login modal
      const authService = getInstance();
      if (authService.isAuthenticated) {
        if (this.isImpersonating) {
          this.$router.push({ name: "MyItineraries", query: { creatorId: this.getViewingUserProfile.sherpaId } });
        } else {
          this.$router.push({ name: "MyItineraries" });
        }
      } else {
        // Note: Ideally we should specify a customer redirect uri here so they go straight to the create page
        this.showLoginModal();
      }
    },
    showLoginModal() {
      this.isShowLoginModal = true;
    },
    logout() {
      // Clear the org in context
      AdminContext.clearOrgInContext();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.globalRoot.orgInContext = null;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.globalAuth.logout();
      this.$router.push({ path: "/" });
    },
    checkForReset() {
      const reset = RouteHelper.getQueryStringParam("reset");
      if (reset === "true") {
        localStorage.removeItem("introClosed");
        localStorage.removeItem("location");
        localStorage.removeItem("preferredThemes");
      }
    },
    rehydrateFromLocalStore() {
      // Restore any localStorage values
      var savedLocation = localStorage.getItem("location");
      if (savedLocation) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.globalRoot.locationPreference = JSON.parse(savedLocation);
      }
    },
  },
});
</script>

<style lang="scss">
@import "@/scss/screen-size-ranges.scss";

@import "@/scss/mixins/flexbox";
@import "@/scss/mixins/columns";
@import "@/scss/variables";
@import "@/scss/menu";
@import "@/scss/shrpas";
@import "@/scss/forms";
@import "@/scss/footer";
@import "@/scss/steps";
@import "@/scss/utilities";
@import "@/scss/cms";
@import "@/scss/main";
@import "@/scss/home";
@import "@/scss/mobile";
@import "@/scss/datepicker";
@import "@/scss/community-stats-print";
@import "@/scss/headlines";
@import "@/scss/screen-size-ranges";
@import "@/scss/transitions";
@import "@/scss/columns-layout";

// Activity feed wrap =========================================================
.activity-feed-wrap {
  padding: 30px 35px 40px;
  overflow-y: scroll;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .activity-feed-wrap {
    padding: 15px 10px 25px;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .activity-feed-wrap {
    padding: 0 0 25px;
    margin-top: -1px;
  }
}

.help-floating-button {
  position: fixed !important;
  inset: auto auto 25px calc(100vw - 25px - 50px);
}
@media print {
  .help-floating-button {
    display: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop =============================
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large =======================
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .help-floating-button {
    inset: auto auto 80px calc(100vw - 15px - 40px);
  }
}
// tablet =============================
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .help-floating-button {
    inset: auto auto 38px calc(100vw - 10px - 40px);
    transform: scale(0.85);
  }
}
// mobile =============================
@media (max-width: $mobile-max-width) {
  .help-floating-button {
    inset: auto auto 38px calc(100vw - 10px - 40px);
    transform: scale(0.85);
  }
}

#app {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  @media screen and (max-width: 428px) {
    //NOTE! Side scrolling kept cropping up on Safari so we applied this at the top level to suppress that.
    //overflow-x: hidden;
  }
}

.ui.text.menu.main-menu {
  @media screen and (max-width: 428px) {
    margin-right: 0;
  }
}

@media print {
  /* Hiding the navigation for printing.  This is currently primarily used for CommunityStatsSummary*/
  .top-nav {
    display: none !important;
  }
  .left-nav {
    display: none !important;
  }
  #content-column {
    width: 100% !important;
  }
  .nav-footer {
    display: none !important;
  }
  .help-hover-icon {
    display: none !important;
  }
}

.customer-admin-left-nav {
  height: 100%;
  min-height: calc(100vh - 150px);
}

@media screen and (max-width: 1260px) {
  .customer-admin-left-nav {
    min-height: auto;
  }
}

.dp__menu {
  box-shadow: 0 8px 30px -7px rgba(0, 0, 0, 0.3);
}
</style>
