import { CollabInput } from "@contracts/collab";
import DateUtils from "@logic/DateUtils";

export function getCollabNameWithFallback(collabName: string | null): string {
  if (!collabName) collabName = "";
  collabName = collabName.trim();

  if (!collabName || collabName.length === 0) {
    return "New Creator Visit";
  } else {
    return collabName;
  }
}

export function formatPreferredTimingDates(collabInput: CollabInput): string {
  if (collabInput?.preferredDateRangeStart) {
    return DateUtils.formatDateRangeTwoDates(collabInput.preferredDateRangeStart, collabInput.preferredDateRangeEnd);
  } else {
    return "";
  }
}
