<template>
  <!--Note: Hide/show is owned by the parent since the buttons firing this live there-->
  <SrpModal className="create-collab-form" :isVisible="visible" @close="onClose" :isClosable="false" size="large" maxHeight="calc(100dvh - 120px)" isWithScroll :isWithBottomGap="false">
    <template #content>
      <div class="modal-description-wrap">
        <div class="modal-description">
          <StepIntroMain
            v-if="currentStep === 0"
            :collabInput="collabInput"
            :customerId="communityId"
            @changeStep="
              stepNumber => {
                changeStep(stepNumber);
              }
            "
            @mount="
              () => {
                collabIdToClone = null; //it's needed to clear the id when user get's back to the intro screen and selects any other option instead of clone
              }
            "
            :introSteps="introSteps"
            :draftCollabs="draftCollabs"
            :collabSummaries="collabSummariesRecentFirst"
            :plannedCollabs="plannedCollabs"
            :isLoadingCollabSummaries="isLoadingCollabSummaries"
            :isLoadingPlannedCollabs="isLoadingPlannedCollabs"
          />

          <!--<StepsSummary v-if="step === 0" />-->

          <StepIntroFresh v-if="currentStep === 0.1" :collabInput="collabInput" @changeStep="() => changeStep(1)" />
          <StepIntroDraft
            v-if="currentStep === 0.2"
            :customerId="communityId"
            @click:getStarted="
              collabId => {
                $emit('getStartedDraft', { collabId, createdForCreatorId });
                currentStep = 0;
              }
            "
            :draftCollabs="draftCollabs"
          />
          <StepIntroInspiration
            v-if="currentStep === 0.3"
            :communityId="communityId"
            @click:getStarted="
              (collabSuggestionId, theme) => {
                getStartedCollabInspiration(collabSuggestionId, theme);
              }
            "
          />
          <StepIntroPlanned
            v-if="currentStep === 0.4"
            :communityId="communityId"
            @click:getStarted="
              plannedCollab => {
                currentStep = 1;
                $emit('getStartedPlannedCollab', plannedCollab);
              }
            "
            :plannedCollabs="plannedCollabs"
          />
          <StepIntroClone
            v-if="currentStep === 0.5"
            @click:cloneCollab="
              id => {
                collabIdToClone = id;
                changeStep(1);
              }
            "
            :collabSummaries="collabSummariesRecentFirst"
          />

          <StepTiming
            v-if="currentStep === createStep"
            :getCurrentCollabInput="getCurrentCollabInput"
            :collabInput="collabInput"
            :communityId="communityId"
            :collabDateResetCounter="collabDateResetCounter"
            :errors="errors"
          />

          <StepLocations v-if="currentStep === locationsAndActivitiesStep" :collabInput="collabInput" :validationResult="validationResult" :clearValidation="clearValidation" :errors="errors" />

          <StepThemes v-if="currentStep === 3" :collabInput="collabInput" :errors="errors" />

          <StepLodging v-if="currentStep === lodgingStep" :collabInput="collabInput" :collabRemainingCounts="collabRemainingCounts" :errors="errors" />

          <StepLocationsNAdventures
            v-if="currentStep === locationsNAdventuresStep"
            v-model:requestedLocationCount="collabInput.requestedLocationCount"
            v-model:requestedAdventureCount="collabInput.requestedAdventureCount"
            :errors="errors"
          />

          <StepGearNMaterial v-if="currentStep === 6" :collabInput="collabInput" :errors="errors" />

          <StepBoundaries v-if="currentStep === 7" :collabInput="collabInput" :errors="errors" />

          <StepName v-if="currentStep === lastStep" :collabInput="collabInput" :errors="errors" @jumpToCompedActivitiesStep="currentStep = locationsAndActivitiesStep" />
        </div>
      </div>

      <StepsProgressBar v-if="currentStep >= 1" class="create-collab-form__steps-progressbar" :currentStep="currentStep" :numberOfSteps="lastStep" />

      <!--Errors-->
      <div v-if="errors.length > 0" style="color: red; margin-bottom: 15px">
        <h5 style="margin: 10px 0 0 0">Required Fields</h5>
        <div v-for="(error, i) in errors" :key="'error' + i">- {{ error }}</div>
      </div>
    </template>

    <!--Hide the actions panel if it's a brand new collab (since choosing a themeType moves to the next step)-->
    <template #footer v-if="currentStep > 0 || collabInputForEditFlow || plannedCollab">
      <label v-if="isSuperOrSalesUser && errors?.length > 0" style="width: auto">
        <input type="checkbox" v-model="superOverrideValidation" style="display: inline; width: auto" />
        skip validation (super)
      </label>
      <h3 style="margin: 0 15px 0 0; display: inline">{{ isSaving ? "Saving..." : "" }} {{ doneSavingMessage ? "Saved" : "" }}</h3>
      <SrpButton
        v-if="currentStep >= 1 || (currentStep > 0 && currentStep < 1 && isMainIntroStepAvailable)"
        :isDisabled="isSaving"
        color="gray"
        @click="
          () => {
            if (currentStep === 1 && !isMainIntroStepAvailable) {
              currentStep = 0.1;
            } else if (isCurrentStepIntroButNotMain) {
              currentStep = 0;
            } else {
              changeStep(-1);
            }
          }
        "
        fill="outlined"
        style="margin-right: 10px"
      >
        Back
      </SrpButton>

      <template v-if="!isCurrentStepIntroButNotMain">
        <SrpButton v-if="currentStep !== lastStep" :isDisabled="isSaving" @click="changeStep(1)">
          {{ currentStep === 0 && collabInputForEditFlow == null && plannedCollab == null ? "Let's Go!" : "Next" }}
        </SrpButton>
        <SrpButton v-else @click="changeStep(1)">Save</SrpButton>
      </template>
    </template>

    <!--    &lt;!&ndash;Hide the actions panel if it's a brand new collab (since choosing a themeType moves to the next step)&ndash;&gt;-->
    <!--    <template #footer v-if="step > 0 || collabInputForEditFlow || plannedCollab">-->
    <!--      <label v-if="isSuperOrSalesUser && errors?.length > 0" style="width: auto">-->
    <!--        <input type="checkbox" v-model="superOverrideValidation" style="display: inline; width: auto" />-->
    <!--        skip validation (super)-->
    <!--      </label>-->
    <!--      <h3 style="margin: 0 15px 0 0; display: inline">{{ isSaving ? "Saving..." : "" }} {{ doneSavingMessage ? "Saved" : "" }}</h3>-->
    <!--      <SrpButton v-if="step !== 0" :isDisabled="isSaving" color="gray" @click="changeStep(-1)" fill="outlined" style="margin-right: 10px">Back</SrpButton>-->

    <!--      <SrpButton v-if="step !== lastStep" :isDisabled="isSaving" @click="changeStep(1)">-->
    <!--        {{ step === 0 && collabInputForEditFlow == null && plannedCollab == null ? "Let's Go!" : "Next" }}-->
    <!--      </SrpButton>-->
    <!--      <SrpButton v-else @click="changeStep(1)">Save</SrpButton>-->
    <!--    </template>-->
    <!--    &lt;!&ndash; / &ndash;&gt;-->
  </SrpModal>
</template>

<script lang="ts">
/* eslint vue/no-mutating-props: 0 */
import axios from "axios";
import CollabInputValidator from "@logic/CollabInputValidator";
import CollabStatuses from "@logic/CollabStatuses";
import { RouteHelper } from "@helpers/RouteHelper";
import { defineComponent, inject } from "vue";
import { defineIsCollabDraft } from "@logic/CollabsMiscellaneous";

// Contracts
import CollabConsts, { CollabInput, CreatorCollabSummary, CustomerCollabRemainingCounts, ThemeType, themeTypes } from "@contracts/collab";
import { ItinerarySummary } from "@contracts/itinerary";
import { CollabInputValidationError } from "@logic/CollabInputValidator";
import { PlannedCollab } from "@contracts/plannedCollabs";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
// intro steps
import StepIntroMain from "./IntroSteps/StepIntroMain.vue";
import StepIntroFresh from "./IntroSteps/StepIntroFresh.vue";
import StepIntroDraft from "./IntroSteps/StepIntroDraft.vue";
import StepIntroInspiration from "./IntroSteps/StepIntroInspiration.vue";
import StepIntroPlanned from "./IntroSteps/StepIntroPlanned.vue";
import StepIntroClone from "./IntroSteps/StepIntroClone.vue";
// steps
import StepsSummary from "./StepsSummary.vue";
import StepThemes from "./StepThemes.vue";
import StepTiming from "./StepTiming.vue";
import StepLocations from "./StepLocations.vue";
import StepLocationsNAdventures from "./StepLocationsNAdventures.vue";
import StepLodging from "./StepLodging.vue";
import StepGearNMaterial from "./StepGearNMaterial.vue";
import StepBoundaries from "./StepBoundaries.vue";
import StepName from "./StepName.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { mapState } from "pinia";
import StepsProgressBar from "@components/StepsProgressBar.vue";

export default defineComponent({
  name: "CreateCollab",

  components: {
    StepsProgressBar,
    SrpButton,
    SrpModal,
    // intro steps
    StepIntroMain,
    StepIntroFresh,
    StepIntroDraft,
    StepIntroInspiration,
    StepIntroPlanned,
    StepIntroClone,
    // steps
    StepsSummary,
    StepThemes,
    StepTiming,
    StepLocations,
    StepLocationsNAdventures,
    StepLodging,
    StepGearNMaterial,
    StepBoundaries,
    StepName,
  },

  props: {
    communityId: { type: String, required: true },
    // Expected to be empty on the Create flow and set on the Edit flow
    collabInputForEditFlow: { type: Object as () => CollabInput | null, required: false, default: null },
    collabRemainingCounts: { type: Object as () => CustomerCollabRemainingCounts | null, required: false, default: null },
    visible: { type: Boolean, required: true },
    isLoadingCollabSummaries: { type: Boolean, required: false },
    isLoadingPlannedCollabs: { type: Boolean, required: false },
    openingEditFlow: { type: Number, required: false, default: null },
    plannedCollab: { type: Object as () => PlannedCollab | null, required: false, default: null },
    collabSuggestionId: { type: String, required: false, default: null },
    createdForCreatorId: { type: String, required: false, default: null },
    defaultTheme: { type: String, required: false, default: null },
    isMainIntroStepAvailable: { type: Boolean, required: false, default: true }, // prevents getting to the MainIntro step when the modal is in "Edit collab" mode
    plannedCollabs: { type: Array as () => Array<PlannedCollab>, required: false, default: () => [] },
    collabs: { type: Array as () => Array<CollabInput>, required: false, default: () => [] },
    collabSummaries: { type: Array as () => Array<CreatorCollabSummary>, required: false, default: () => [] },
  },

  emits: ["close", "collabCreated", "getStartedPlannedCollab", "getStartedCollabInspiration", "getStartedDraft", "setCreatedForCreatorId"],

  data() {
    return {
      globalLog: inject("globalLog") as any,

      currentStep: 0,

      introSteps: {
        freshStep: 0.1,
        draftStep: 0.2,
        inspirationStep: 0.3,
        plannedStep: 0.4,
        cloneStep: 0.5,
      },

      createStep: 1,
      locationsAndActivitiesStep: 2,
      lodgingStep: 4,
      locationsNAdventuresStep: 5,
      lastStep: 8,

      isSaving: false,
      isDirty: false,
      doneSavingMessage: false,

      collabIdToClone: null as string | null,

      newCollab: {} as CollabInput | null, // Set on mount
      collabDateResetCounter: 1,

      CollabInviteExpirationInDays: CollabConsts.CollabInviteExpirationInDays,
      DaysCreatorHasToCreateAdventures: CollabConsts.DaysCreatorHasToCreateAdventures,

      errors: [],
      superOverrideValidation: false,
      validationResult: null as CollabInputValidationError | null,
      version: 3, // Note: Just used for creating collabs, otherwise collab.version is used
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["isSuperOrSalesUser"]),
    isCurrentStepIntroButNotMain() {
      return this.currentStep > 0 && this.currentStep < 1;
    },
    collabInput: {
      // If the collabInputForEditFlow is set bind to that, otherwise we're in the newCollab flow so bind to that
      get(): CollabInput {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.collabInputForEditFlow?.id?.length > 0) return this.collabInputForEditFlow;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        else return this.newCollab;
      },
      set(value: CollabInput) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.collabInputForEditFlow?.id?.length > 0) console.error("Unexpected Code Path");
        // Should never hit this
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        else this.newCollab = value;
      },
    },
    draftCollabs(): Array<CollabInput> {
      return this.collabs.filter(c => defineIsCollabDraft(c));
    },
    collabSummariesRecentFirst(): Array<CreatorCollabSummary> {
      return this.collabSummaries.slice().sort((a, b) => {
        // nulls sort after anything else
        if (a.completedDate === null) return 1;
        if (b.completedDate === null) return -1;
        return b.completedDate - a.completedDate;
      });
    },
  },

  watch: {
    // Need an isDirty check so we save on next/back/close
    collabInputForEditFlow: {
      handler(newVal, oldVal) {
        if (oldVal != null) {
          if (oldVal.id != newVal.id) {
            this.globalLog.info("Switching Collabs, resetting isDirty");
            this.setIsDirty(false);
          } else {
            this.setIsDirty(true);
          }
        }
      },
      deep: true,
    },
    newCollab: {
      handler(newVal, oldVal) {
        if (oldVal != null) {
          this.setIsDirty(true);
        }
      },
      deep: true,
    },
    openingEditFlow: {
      handler(newVal, oldVal) {
        this.errors = [];
        // Jump back to the intro
        this.jumpToStep(0.1);
      },
    },
    visible(value) {
      if (!value) return;
      this.setNewCollabFields();
    },
  },

  async mounted() {
    this.setNewCollabFields();
  },

  methods: {
    async getStartedCollabInspiration(collabSuggestionId, theme) {
      let _createdForCreatorId;
      if (this.createdForCreatorId) _createdForCreatorId = this.createdForCreatorId;

      this.$emit("close");

      // "setCreatedForCreatorId" will be wiped out on the "close" event, so it should be set again
      // This is needed because the CreateCollab modal is included in two places and we flow between them
      // in this scenario, which is new when we added the early create screen.
      if (_createdForCreatorId) this.$emit("setCreatedForCreatorId", { creatorId: _createdForCreatorId });

      await new Promise(resolve => setTimeout(resolve, 0));
      this.$router.replace({
        name: "PaidCollabCommunityAdmin",
        params: { communityId: String(this.communityId) },
        hash: `#collabSuggestionId=${collabSuggestionId}&theme=${theme}`,
      });
      await new Promise(resolve => setTimeout(resolve, 0));
      this.$emit("getStartedCollabInspiration");
    },
    setNewCollabFields() {
      this.newCollab = {
        // Just the fields on the first panel, after that we'll populate this from the server
        name: "",
        preferredDateRangeStart: null,
        preferredDateRangeEnd: null,
        preferredTiming: null,
        reminderForFarOutRequested: false,
      } as CollabInput;

      if (this.plannedCollab) {
        this.newCollab.themeType = this.plannedCollab.themeType;
        this.newCollab.name = this.plannedCollab.name;
        this.newCollab.themes = this.plannedCollab.notes;
        if (this.plannedCollab.preferredDateRangeStart && this.plannedCollab.preferredDateRangeEnd) {
          this.newCollab.preferredDateRangeStart = this.plannedCollab.preferredDateRangeStart;
          this.newCollab.preferredDateRangeEnd = this.plannedCollab.preferredDateRangeEnd;
        }
      } else if (this.defaultTheme && themeTypes.includes(this.defaultTheme as ThemeType)) {
        this.newCollab.themeType = this.defaultTheme as ThemeType;
        this.changeStep(this.createStep);
      }
    },
    async createNewCollab(): Promise<boolean> {
      if (!this.validate(this.newCollab, this.createStep)) return;

      this.isSaving = true;
      // Server generates the Id
      this.newCollab.version = this.version;

      const urlSearchParams = new URLSearchParams();
      if (this.collabIdToClone) {
        urlSearchParams.append("collabInputIdToClone", String(this.collabIdToClone));
      }
      if (this.collabSuggestionId) {
        urlSearchParams.append("createFromCollabSuggestionId", String(this.collabSuggestionId));
      }

      if (this.createdForCreatorId) {
        this.newCollab.createdForCreatorId = this.createdForCreatorId;
      }
      const response = await axios.post(`${import.meta.env.VITE_API_URL}/collabs/${this.communityId}?` + urlSearchParams.toString(), this.newCollab);
      this.newCollab = response.data;

      if (this.collabIdToClone) this.globalLog.info(`Cloned! CollabInputId=${this.newCollab.id}`);
      else this.globalLog.info(`Created! CollabInputId=${this.newCollab.id}`);

      this.collabIdToClone = null;

      this.$nextTick(() => {
        this.setIsDirty(false);
      });
      // Tell the parent that the collab was created
      this.$emit("collabCreated", response.data);
      // Gets the CollabDatePicker to reset it's local data
      this.collabDateResetCounter++;
      this.isSaving = false;
      this.doneSavingMessage = true;
      setTimeout(() => {
        this.doneSavingMessage = false;
      }, 1500);
      return true;
    },
    // NOTE: Use this since we have two possible collab sources for the various flows (new vs. edit)
    getCurrentCollabInput(): CollabInput {
      return this.collabInputForEditFlow != null ? this.collabInputForEditFlow : this.newCollab;
    },
    async save(validate: boolean, stepToValidate: number | null, notify: boolean): Promise<boolean> {
      // Which collab to save depends on which flow we're in
      const collabToSave = this.getCurrentCollabInput();
      if (this.createdForCreatorId) {
        collabToSave.createdForCreatorId = this.createdForCreatorId;
      }
      // Validation
      if (validate) {
        if (!this.validate(collabToSave, stepToValidate)) return false;
      }

      this.isSaving = true;
      // Note: We pass a notify param to reduce the volume of update notifications we send ourselves
      // Always notify if it's already been sent to creators
      if (this.isPastInputStatus()) notify = true;

      this.cleanupCollabFieldsBeforeSave(collabToSave);
      const uri = `${import.meta.env.VITE_API_URL}/collabs/${this.communityId}/inputs/${collabToSave.id}?notify=${notify}`;
      const response = await axios.put(uri, collabToSave);
      this.globalLog.info(`Saved! CollabInputId=${collabToSave.id}`);
      this.setIsDirty(false);
      this.isSaving = false;
      this.doneSavingMessage = true;

      setTimeout(() => {
        this.doneSavingMessage = false;
      }, 1500);
      return true;
    },
    cleanupCollabFieldsBeforeSave(collab: CollabInput) {
      // Note: Decimals in the estimated value fields causes failures since they're integers server-side and the default server validation is surprisingly strict.
      if (collab.compedActivitiesEstimatedValue) {
        collab.compedActivitiesEstimatedValue = Math.trunc(collab.compedActivitiesEstimatedValue);
      }
      if (collab.hotelEstimatedValue) {
        collab.hotelEstimatedValue = Math.trunc(collab.hotelEstimatedValue);
      }
    },
    // Jumps to a specific step (used by the Edit button to jump into the flow)
    async jumpToStep(step: number) {
      this.currentStep = step;
    },
    shouldSeeHotelStep(): boolean {
      return this.collabRemainingCounts?.showLodgingStep ?? true;
    },
    async defaultHotelCoverageUsed() {
      // console.info(`Hotel Coverage=${this.collabInput.hotelCoverageUsed}`);
      if (!this.collabInput.hotelCoverageUsed || this.collabInput.hotelCoverageUsed === "Unset") {
        console.info(`${this.collabRemainingCounts.collabHotelsGrandfathered} grandfathered and ${this.collabRemainingCounts.collabHotelsPaidRemaining} addons `);
        if (this.collabRemainingCounts.collabHotelsGrandfathered > 0) {
          this.collabInput.hotelCoverageUsed = "Grandfathered";
        } else if (this.collabRemainingCounts.allRemaininingHavePaidCoverage === true) {
          this.collabInput.hotelCoverageUsed = "PaidAddOn";
          // Also default this field since it was always set previously (hard to say if leaving it null would cause problems...)
          this.collabInput.hotelNightsCovered = 0;
        } else {
          // Let the customer select
        }
      }
    },
    // Moves back and forth steps (used by the main buttons) - SAVES if dirty
    async changeStep(value: number) {
      // If the value is an Intro step ( > 0 && < 1) but not the Main intro step ( 0 )
      if (value > 0 && value < 1) {
        this.currentStep = value;
        return;
      }

      // Removes decimal part for not "intro" values (intro values are those which > 0 && < 1)
      if (!Number.isInteger(this.currentStep)) {
        this.currentStep = Math.floor(this.currentStep);
      }

      // If the user hits Next always validate
      if (value >= 1) {
        const collabToValidate = this.getCurrentCollabInput();
        if (!this.validate(collabToValidate, this.currentStep)) return;
      }
      if (value < 0) {
        this.clearValidation();
      }

      // Check if they should see the hotel step
      if (this.currentStep + value === this.lodgingStep) {
        await this.defaultHotelCoverageUsed();
      }
      let shouldSeeHotelStep = this.shouldSeeHotelStep();

      // Ensure the estimate fields are unset of they don't apply (based on the UI logic above)
      if (this.currentStep === this.locationsAndActivitiesStep) {
        if (this.collabInput.requestingBigTicketItems === false && this.collabInput.compedActivitiesTags.length === 0) {
          this.collabInput.compedActivitiesEstimatedValue = null;
          this.collabInput.bigTicketItemBookinNotes = null; // This also hides if no comps tags are selected
        }
      } else if (this.currentStep === this.lodgingStep) {
        if (this.collabInput.hotelNightsCovered === 0) {
          this.collabInput.hotelEstimatedValue = null;
        }
      }

      const needToCreate = this.collabInputForEditFlow == null && !(this.newCollab.id?.length > 0);
      // Each steps saves except the first intro slide
      if (this.currentStep !== 0 && this.isDirty) {
        // If we're in the create flow and the collab has no Id then create it, otherwise update it
        if (needToCreate) {
          // Create
          await this.createNewCollab();
        } else {
          // Save
          // notify=false here since other code paths cover create/done and we don't want to notify on each intermediate save.
          await this.save(false, null, false); // Validated above()
        }
      }
      if (this.currentStep === this.lastStep && value > 0) {
        await this.onClose();
      } else if (this.currentStep > 0 || this.currentStep < this.lastStep) {
        this.currentStep = this.currentStep + value;
        // Check if we should skip the hotel stop
        if (this.currentStep === this.lodgingStep && !shouldSeeHotelStep) {
          this.currentStep = this.currentStep + value;
        }
      }

      // reset this field
      this.superOverrideValidation = false;
    },
    setIsDirty(dirty: boolean) {
      // this.globalLog.info(`isDirty=${dirty}`);
      this.isDirty = dirty;
    },
    validate(collabToValidate: CollabInput, stepToValidation: number): boolean {
      // Note: The wizard validates step by step as the user progresses through the flow
      this.validationResult = CollabInputValidator.validateCollabInputs(collabToValidate, stepToValidation, this.isSuperOrSalesUser, this.superOverrideValidation);
      this.errors = this.validationResult.errors;
      return this.errors.length === 0;
    },
    clearValidation() {
      this.validationResult = null;
      this.errors = [];
    },
    async onClose() {
      const isPostedToCreators = this.isPastInputStatus();
      // We need to validate if they've submitted this collab, otherwise we let them close without passing validation
      if (isPostedToCreators) {
        if (!this.validate(this.getCurrentCollabInput(), null)) {
          this.$emit("close");
          return;
        }
      }
      // Save on close if there are changes (otherwise it'll look like they're saved but they might not have been)
      if (this.isDirty && (this.collabInputForEditFlow != null || this.newCollab.id?.length > 0)) {
        await this.save(isPostedToCreators, null, true);
      }
      this.errors = [];
      this.$nextTick(() => {
        this.setIsDirty(false);
      });
      this.collabDateResetCounter = 1;

      // Delay is needed to avoid the form from switching to the first slide before the modal closing animation ends
      setTimeout(() => (this.currentStep = 0), 400);
      this.$emit("close");
    },
    isPastInputStatus(): boolean {
      return this.collabInputForEditFlow && this.collabInputForEditFlow.collaboration.status !== CollabStatuses.CommunityInputStatusId;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

.modal-description-wrap {
  width: 100%;
  max-width: calc(100vw - 50px);
}

// Create collab form =========================================================
:global(.create-collab-form .srp-modal__content) {
  padding-bottom: 0 !important;
}

:global(.create-collab-form__steps-progressbar) {
  margin-bottom: 25px;
}

.themes-dropdown {
  color: #000 !important;
  font-weight: normal;
  .ui.label {
    color: #000 !important;
    font-weight: normal;
  }
}
//Override the default semantic min-height
.ui.form textarea {
  min-height: 60px;
  //Default height (override inline on some)
  height: 80px;
}
.step-counter {
  margin-bottom: -1rem;
}
.ui.header.all-set > i {
  font-size: 2rem !important;
}
.all-set-container {
  border-radius: 8px;
  padding: 1rem;
  background-color: $brand-color-lightest;
  text-align: center;
}
.intro-text {
  margin-top: 4px;
  margin-bottom: 0.5rem;
}
.modal-description {
  width: calc(100% + 120px);
  padding: 0 1rem 20px 120px;
  margin-left: -120px;
  box-sizing: border-box;
  overflow-y: auto;
  // -ms-overflow-style: none;  /* IE and Edge */
  // scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar-track {
    background: white;
  }
}
.ui.modal > .content {
  padding-right: 3rem !important;
  border-radius: 6px;
}
//needed to override the default label styling
.time-label {
  color: #058587 !important;
  font-weight: 500 !important;
  background-color: #05858700;
  font-size: 0.9em;
}
.current-step {
  background-color: $brand-color-lightest;
}
.ui.segment span.ui.header span.number {
  font-weight: 400;
  margin-right: 1rem;
}

.required-field {
  color: red;
  font-weight: bold;
  margin-left: 5px;
  font-size: 13px;
}
</style>
