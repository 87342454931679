<template>
  <div class="form-snippet">
    <!-- Name form element -->
    <div class="name-form-element form-snippet__name-form-element" @click="isNameEditMode = true">
      <div class="global-h2 name-form-element__text-overlay">
        {{ plannedCollab.name || "Plan name" }}
      </div>
      <textarea
        class="global-h2 name-form-element__textarea"
        type="text"
        :value="plannedCollab.name"
        @input="onNameInput"
        @blur="isNameEditMode = false"
        ref="domRefTextInput"
        placeholder="Plan name"
      ></textarea>

      <LinkWithIcon
        :class="{
          'name-form-element__edit-icon': true,
          'name-form-element__edit-icon--invisible': isNameEditMode,
        }"
        @click="
          () => {
            domRefTextInput?.focus();
            isNameEditMode = true;
          }
        "
      >
        <template #icon><IconEmbedded name="edit-pencil_2" :size="28" color="rgba(0, 0, 0, 0.5)" /></template>
      </LinkWithIcon>
    </div>
    <!-- / Name form element -->

    <div class="form-snippet__time-n-theme">
      <!-- Title & form element -->
      <div class="title-n-form-element form-snippet__time-snippet">
        <div class="global-h5 title-n-form-element__title">What time of year?</div>
        <DatePicker
          class="title-n-form-element__form-element title-n-form-element__form-element--higher-z-index"
          :isFormFocused="isFormFocused"
          :dateRange="{
            start: plannedCollab.preferredDateRangeStart,
            end: plannedCollab.preferredDateRangeEnd,
          }"
          :onlyAllowFutureDates="true"
          @update:date-range="emit('update:plannedCollab', { ...plannedCollab, preferredDateRangeStart: $event.start, preferredDateRangeEnd: $event.end })"
        />
      </div>
      <!-- / Title & form element -->

      <!-- Title & form element -->
      <div class="title-n-form-element form-snippet__theme-snippet">
        <div class="global-h5 title-n-form-element__title">What's the theme <span style="color: rgba(0, 0, 0, 0.5); font-weight: 500">(optional)</span></div>
        <CollabThemePicker
          :model-value="plannedCollab.themeType"
          @update:modelValue="emit('update:plannedCollab', { ...plannedCollab, themeType: $event })"
          class="title-n-form-element__form-element"
        />
      </div>
      <!-- / Title & form element -->
    </div>

    <!-- Title & form element -->
    <div class="title-n-form-element form-snippet__notes-snippet">
      <textarea placeholder="Optional notes" rows="1" class="global-textarea title-n-form-element__form-element" style="height: 60px" :value="plannedCollab.notes" @input="onNotesInput"></textarea>
    </div>
    <!-- / Title & form element -->

    <div v-if="isLocationsFieldsVisible" class="form-snippet__location-snippets-container">
      <!-- Title & form element -->
      <div class="title-n-form-element form-snippet__location-snippet">
        <div class="global-h5 title-n-form-element__title">Required Locations</div>

        <textarea class="global-textarea form-snippet__location-snippet__textarea" v-model="plannedCollab.requiredLocations" placeholder="optional"></textarea>
      </div>
      <!-- / Title & form element -->

      <!-- Title & form element -->
      <div class="title-n-form-element form-snippet__location-snippet">
        <div class="global-h5 title-n-form-element__title">Suggested Locations</div>

        <textarea class="global-textarea form-snippet__location-snippet__textarea" v-model="plannedCollab.suggestedLocations" placeholder="optional"></textarea>
      </div>
      <!-- / Title & form element -->
    </div>

    <div>
      <SrpButton v-if="props.displayNextButton" class="form-snippet__next-btn" size="small" @click.stop="onClickNext">
        Next
        <template #iconRight>
          <IconEmbedded name="arrow-bottom_2-5" :size="20" color="rgba(255, 255, 255, 1)" style="top: -1px" />
        </template>
      </SrpButton>
      <SrpButton v-if="props.displayClearButton" class="form-snippet__next-btn" size="small" fill="text" @click.stop="onClickClear"> Clear </SrpButton>
    </div>
    <p v-if="showDatePickerValidation" class="form-snippet__error-message">Please provide a date or this plan won't be saved</p>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

// Components
import CollabThemePicker from "./CollabThemePicker.vue";
import DatePicker from "./DatePicker.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { type PlannedCollab } from "@repos/PlannedCollabsRepo";

const props = withDefaults(
  defineProps<{
    displayNextButton: boolean;
    displayClearButton: boolean;
    plannedCollab: PlannedCollab;
    isFormFocused: boolean;
  }>(),
  {
    displayNextButton: true,
  }
);

const emit = defineEmits<{
  (e: "update:plannedCollab", value: PlannedCollab): void;
  (e: "goToNext"): void;
}>();

// Name value =================================================================
const isNameEditMode = ref<boolean>(false);
function onNameInput(e: InputEvent) {
  const newName = (e.target as HTMLTextAreaElement).value;
  emit("update:plannedCollab", { ...props.plannedCollab, name: newName });
}
const domRefTextInput = ref<HTMLInputElement | null>(null);

function onNotesInput(e: InputEvent) {
  const newNotes = (e.target as HTMLTextAreaElement).value;
  emit("update:plannedCollab", { ...props.plannedCollab, notes: newNotes });
}
// NOTE! The Required/Suggested fields don't use this pattern, they just v-model bind

const showDatePickerValidation = ref<boolean>(false);
function onClickNext() {
  showDatePickerValidation.value = false;
  if (props.plannedCollab.preferredDateRangeStart) {
    emit("goToNext");
    return;
  }
  showDatePickerValidation.value = true;
}

watch(
  () => isNameEditMode.value,
  () => {
    if (isNameEditMode.value) domRefTextInput.value.focus();
  }
);

function onClickClear() {
  emit("update:plannedCollab", {
    ...props.plannedCollab,
    name: "",
    themeType: null,
    preferredDateRangeStart: null,
    preferredDateRangeEnd: null,
    notes: null,
    requiredLocations: null,
    suggestedLocations: null,
  });
}

// Toggle locations fields ====================================================
const isLocationsFieldsVisible = ref<boolean>(true);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Name form element ==========================================================
.name-form-element {
  padding-right: 35px;
  display: flex;
  position: relative;
  z-index: 0;

  &__text-overlay {
    width: calc(100% + 10px);
    min-width: 127px;
    min-height: 31px;
    padding: 4px 15px 4px 4px;
    margin-left: -4px;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.5);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    word-break: break-all;
    user-select: none;
    cursor: text;
    opacity: 0;
    pointer-events: none;
  }

  &__textarea {
    width: calc(100% - 0px);
    min-width: 127px;
    max-width: calc(100% - 30px);
    height: calc(100% + 5px);
    min-height: 31px;
    padding: 4px;
    margin: 0 0 0 -4px;
    box-sizing: border-box;
    border: 1px rgba(0, 0, 0, 0) solid;
    border-radius: 4px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: transparent;
    box-shadow: none;
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.5);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
    word-break: break-all;
    transition: none;

    &:hover,
    &:focus {
      padding: 4px;
      margin: 0 0 0 -4px;
      border: 1px rgba(0, 0, 0, 0.2) solid;
      box-shadow: none;
    }

    &:focus {
      background: rgba(255, 255, 255, 1);
    }
  }

  &__edit-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    inset: 5px 0 auto auto;
    color: rgba(0, 0, 0, 0.5);
    font-size: 28px;
    transition: opacity 0.07s ease-in-out;
    transition-delay: 0ms;

    &--invisible {
      opacity: 0;
      pointer-events: none;
    }
  }
}

// Date picker field ==========================================================
.date-picker-field {
  &__input {
  }

  &__dropdown {
  }
}

// Title & form element =======================================================
.title-n-form-element {
  &__title {
    margin-bottom: 9px;
  }

  &__form-element {
    &--higher-z-index {
      z-index: 3;
    }
  }
}

// Form snippet ===============================================================
.form-snippet {
  padding: 12px 20px 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ededed;

  &__name-form-element {
    margin-bottom: 17px;
  }

  &__time-n-theme {
    margin-bottom: 21px;
    display: flex;
  }

  &__time-snippet {
    margin-right: 33px;
  }

  &__theme-snippet {
  }

  &__notes-snippet {
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__location-snippets-container {
    width: 100%;
    gap: 27px;
    margin-bottom: 10px;
    display: flex;
  }

  &__location-snippet {
    width: 50%;

    &__textarea {
      height: 70px;
      min-height: 55px;
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }

  &__next-btn {
    margin-inline-end: 1rem;
  }
  &__error-message {
    color: rgba(188, 73, 73);
    margin-top: 1rem;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .form-snippet {
    &__time-n-theme {
      flex-direction: column;
    }

    &__time-snippet {
      margin: 0 0 19px 0;
    }

    &__location-snippets-container {
      flex-direction: column;
    }

    &__location-snippet {
      width: 100%;
    }
  }
}
</style>
