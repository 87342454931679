<template>
  <div
    :class="{
      'open-collab-snippet': true,
      'open-collab-snippet--translucent': collab.statusForCurrentUser,
    }"
  >
    <div class="open-collab-snippet__white-bg-half">
      <!-- Logo container -->
      <router-link :to="{ name: 'Pages', params: { pageId: props.collab.communityId } }" target="_blank" class="logo-container open-collab-snippet__logo">
        <img class="logo-container__img" :src="`${contentBaseUri}/cms/images/orgs/${props.collab.icon}`" alt="partner logo" />
      </router-link>
      <!-- / Logo container -->

      <router-link
        :to="{ name: 'CollabOpportunity', params: { communityId: props.collab.communityId, collabInputId: props.collab.collabInputId, creatorId: props.creatorId } }"
        class="global-h2 open-collab-snippet__name"
        >{{ props.collab.collabName }}</router-link
      >

      <!-- Big location snippet -->
      <div class="big-location-snippet open-collab-snippet__big-location-snippet">
        <router-link :to="{ name: 'Pages', params: { pageId: props.collab.communityId } }" target="_blank" class="global-h5 big-location-snippet__title">{{ props.collab.communityName }}</router-link>

        <div v-if="!isSocialMediaManagerView" class="big-location-snippet__icon-n-distance">
          <IconEmbedded class="big-location-snippet__icon" name="map-pin_2" :size="22" color="rgba(0, 0, 0, 0.3)" />
          <div class="big-location-snippet__distance">
            {{ props.collab.proximityInMiles }} miles away
            <template v-if="props.collab.customerState">
              in <b>{{ props.collab.customerState }}</b>
            </template>
          </div>
        </div>
      </div>
      <!-- / Big location snippet -->

      <div class="open-collab-snippet__view-collab-btn-n-status">
        <div v-if="!isSocialMediaManagerView" class="open-collab-snippet__status">
          {{ collab.statusForCurrentUser ? collab.statusForCurrentUser : "Available!" }}
        </div>

        <SrpButton
          class="open-collab-snippet__view-collab-btn"
          :color="collab.statusForCurrentUser ? 'gray' : 'teal'"
          :to="{ name: 'CollabOpportunity', params: { communityId: props.collab.communityId, collabInputId: props.collab.collabInputId, creatorId: props.creatorId } }"
        >
          View Collab
          <template #iconRight>
            <IconEmbedded name="caret-right_4-5" :size="16" />
          </template>
        </SrpButton>
      </div>
    </div>

    <div class="open-collab-snippet__gray-bg-half">
      <div class="open-collab-snippet__details-snippets-group open-collab-snippet__details-snippets-group--location-n-timing">
        <!-- Details snippet -->
        <div class="details-snippet details-snippet--location open-collab-snippet__details-snippet open-collab-snippet__details-snippet--location">
          <IconEmbedded class="details-snippet__icon" name="map-pin_2" :size="18" color="rgba(0, 0, 0, 0.5)" />

          <div class="details-snippet__text-content">
            <router-link :to="{ name: 'Pages', params: { pageId: props.collab.communityId } }" target="_blank" class="global-h6 details-snippet__title">
              {{ props.collab.communityName }}
            </router-link>
            <span v-if="!isSocialMediaManagerView" class="details-snippet__description">
              {{ props.collab.proximityInMiles }} miles away
              <template v-if="props.collab.customerState">
                in <b>{{ props.collab.customerState }}</b>
              </template>
            </span>
          </div>
        </div>
        <!-- / Details snippet -->

        <!-- Details snippet -->
        <div class="details-snippet details-snippet--timing open-collab-snippet__details-snippet open-collab-snippet__details-snippet--timing">
          <IconEmbedded class="details-snippet__icon" name="calendar_2" :size="18" color="rgba(0, 0, 0, 0.5)" />

          <div
            :class="{
              'details-snippet__text-content': true,
              'details-snippet__text-content--vertical': ['mobile', 'tablet'].includes(screenSize),
            }"
          >
            <span class="global-h6 details-snippet__title">Preferred Timing</span>
            <span class="details-snippet__description">
              {{ formatPreferredTimingDates(props.collab.preferredDateRangeStart, props.collab.preferredDateRangeEnd) }}
            </span>
          </div>
        </div>
        <!-- / Details snippet -->
      </div>

      <div class="open-collab-snippet__details-snippets-group open-collab-snippet__details-snippets-group--visit-focus">
        <!-- Details snippet -->
        <div class="details-snippet details-snippet--visit-focus open-collab-snippet__details-snippet open-collab-snippet__details-snippet--visit-focus">
          <img v-if="props.collab.collabThemeType" class="details-snippet__icon details-snippet__icon--theme-icon" :src="getThemeIcon(props.collab.collabThemeType)" />

          <div
            :class="{
              'details-snippet__text-content': true,
              'details-snippet__text-content--vertical': ['mobile', 'tablet'].includes(screenSize),
              'details-snippet__text-content--text-overflow-hidden': !isDescriptionOpened,
            }"
          >
            <span class="global-h6 details-snippet__title">Visit Focus</span>
            <span class="details-snippet__description">
              {{ props.collab.collabThemes }}

              <!-- Read more link -->
              <span
                v-if="props.collab.collabThemes.length > visibleSymbolsLimit"
                :class="{
                  'read-more-link': true,
                  'details-snippet__read-more-link': true,
                  'details-snippet__read-more-link--positioned-absolute': !isDescriptionOpened,
                }"
                @click="isDescriptionOpened = !isDescriptionOpened"
              >
                <span class="read-more-link__text">
                  {{ isDescriptionOpened ? "hide" : "read more" }}
                </span>
                <IconEmbedded class="read-more-link__caret-icon" :name="isDescriptionOpened ? 'caret-top_4-5' : 'caret-bottom_4-5'" color="rgba(12, 135, 138, 1)" :size="10" />
              </span>
              <!-- / Read more link --> </span
            >&nbsp;
          </div>
        </div>
        <!-- / Details snippet -->
      </div>

      <div class="open-collab-snippet__details-snippets-group open-collab-snippet__details-snippets-group--collab-value-n-comps">
        <!-- Details snippet -->
        <div v-if="!isSocialMediaManagerView" class="details-snippet details-snippet--collab-value open-collab-snippet__details-snippet open-collab-snippet__details-snippet--collab-value">
          <div class="details-snippet__text-content">
            <span class="global-h6 details-snippet__title">Collab Value</span>
            <span class="details-snippet__description">${{ overallCollabValue }}</span>
          </div>
        </div>
        <!-- / Details snippet -->

        <!-- Details snippet -->
        <div
          v-if="CollabConsts.CollabCompTags.filter(i => collab.compedActivitiesTags.includes(i.key)).length || collab.hotelNightsCovered > 0"
          :class="{
            'details-snippet': true,
            'details-snippet--comps': true,
            'details-snippet--comps--not-social-media-manager': !isSocialMediaManagerView,
            'open-collab-snippet__details-snippet': true,
            'open-collab-snippet__details-snippet--comps': true,
          }"
        >
          <div class="details-snippet__text-content details-snippet__text-content--display-flex">
            <span class="global-h6 details-snippet__title">Comps</span>
            <span class="details-snippet__description details-snippet__description--content-icons-row">
              <!--<IconEmbedded name="home_2-5" :size="18" color="rgba(12, 135, 138, 1)" style="margin: -3px 0; position: relative; top: 3px" />-->
              <!--<IconEmbedded name="discount_2-5" :size="18" color="rgba(12, 135, 138, 1)" style="margin: -3px 0; position: relative; top: 3px" />-->

              <IconEmbedded v-if="collab.hotelNightsCovered > 0" name="home_2" :size="18" color="brandColor" style="position: relative; top: -2px" />

              <IconEmbedded
                v-for="tag in CollabConsts.CollabCompTags.filter(i => collab.compedActivitiesTags.includes(i.key))"
                :key="tag.key"
                :name="tag.icon"
                :size="18"
                color="brandColor"
                style="position: relative; top: -2px"
              />
            </span>
          </div>
        </div>
        <!-- / Details snippet -->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CollabValueCalculator from "@logic/CollabValueCalculator";
import DateUtils from "@logic/DateUtils";
import { getThemeIcon } from "@helpers/ThemeTypeHelper";
import { ref, inject, Ref, computed, onMounted } from "vue";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import CollabConsts from "@contracts/collab";
import { DestinationCollabSummaryForCreator } from "@contracts/collabCredits";
import { ScreenSize } from "@contracts/screenSize";
import FeatureFlags from "@logic/FeatureFlags";

// Global variables
const screenSize = inject<Ref<ScreenSize>>("screenSize");
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    collab: DestinationCollabSummaryForCreator;
    creatorCurrentRate: number;
    creatorId: string;
  }>(),
  {
    collab: null,
    creatorCurrentRate: null,
    creatorId: null,
  }
);

// We currently screenshot these so hiding a few fields for Samantha
const isSocialMediaManagerView = ref<boolean>(false);
onMounted(() => {
  if (FeatureFlags.isFlagActive("SocialMediaManagerView")) {
    isSocialMediaManagerView.value = true;
  }
});

// Open/close description =====================================================
const isDescriptionOpened = ref<boolean>(false);

const visibleSymbolsLimit = computed<number>(() => {
  if (screenSize.value === "desktop-wide" || screenSize.value === "desktop") {
    return 60;
  } else if (screenSize.value === "laptop") {
    return 25;
  } else {
    return 60;
  }
});

// Format timing dates ========================================================
function formatPreferredTimingDates(preferredDateRangeStart, preferredDateRangeEnd): string {
  if (!preferredDateRangeStart) return "";

  return DateUtils.formatDateRangeTwoDates(preferredDateRangeStart, preferredDateRangeEnd);
}

// Calc overall collab value ==================================================
const overallCollabValue = computed<string>(() => {
  const value = CollabValueCalculator.overallCollabValue(props.collab, props.creatorCurrentRate, props.collab.otherPaymentAmount);
  return Math.round(value).toLocaleString("en-US");
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Logo container =============================================================
.logo-container {
  max-width: 120px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__img {
    max-width: 100%;
    max-height: 100%;
  }
}

// Big location snippet =======================================================
.big-location-snippet {
  &__title {
    margin-bottom: 3px;
  }

  &__icon-n-distance {
    margin-left: -3px;
    display: flex;
    align-items: center;
  }

  &__icon {
    margin-right: 4px;
  }

  &__distance {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;
  }
}

// Read more link =============================================================
.read-more-link {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &::after {
    content: "";
    width: calc(100% + 16px);
    height: calc(100% + 8px);
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
  }

  &__text {
    margin-right: 4px;
    color: rgba(12, 135, 138, 1);
    font-family: sans-serif;
    text-decoration: underline;
    text-decoration-color: rgba(12, 135, 138, 0.5);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
  }

  &:hover &__text {
    text-decoration: none;
  }

  &__caret-icon {
  }
}

// Details snippet ============================================================
.details-snippet {
  display: flex;
  align-items: flex-start;

  &--location {
  }
  &--timing {
  }
  &--visit-focus {
  }
  &--collab-value {
  }
  &--comps {
  }

  &__icon {
    margin-right: 4px;

    &--theme-icon {
      width: 24px;
      height: 24px;
      position: relative;
      top: -2px;
    }
  }

  &__text-content {
    position: relative;

    &--vertical {
      display: flex;
      flex-direction: column;
    }

    &--text-overflow-hidden {
      display: -webkit-box !important;
      overflow: hidden !important;
      line-clamp: 2 !important;
      -webkit-line-clamp: 2 !important;
      text-overflow: ellipsis !important;
      -webkit-box-orient: vertical !important;
    }

    &--display-flex {
      display: flex;
    }
  }

  &__title {
    margin-right: 12px;
  }

  &__description {
    color: rgba(91, 91, 91, 1);
    font-family: sans-serif;

    &--content-icons-row {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 5px;
    }
  }

  &__read-more-link {
    &--positioned-absolute {
      position: absolute;
      inset: auto 0 0 auto;
      z-index: 2;
    }

    &::before {
      content: "";
      width: calc(100% + 30px);
      height: 100%;
      position: absolute;
      inset: 0 auto auto -30px;
      z-index: -1;
      //background: rgba(231, 237, 240, 1);
      background: linear-gradient(90deg, rgba(231, 237, 240, 0) 0%, rgba(231, 237, 240, 1) 25%, rgba(231, 237, 240, 1) 100%);
      pointer-events: none;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}

// Open collab snippet ========================================================
.open-collab-snippet {
  border: 1px rgba(204, 211, 215, 1) solid;
  border-radius: 6px;
  position: relative;

  &--translucent {
    opacity: 0.5;
  }

  // white bg half --------------------
  &__white-bg-half {
    padding: 12px 25px;
    display: flex;
    align-items: center;
  }

  &__logo {
    margin-right: 13px;
  }

  &__name {
    padding-right: 20px;
    flex-grow: 1;
    font-weight: 500;
  }

  &__big-location-snippet {
    visibility: hidden;
    position: absolute;
  }

  &__view-collab-btn-n-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__status {
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
  }

  &__view-collab-btn {
  }

  // gray bg half ---------------------
  &__gray-bg-half {
    padding: 13px 25px 11px;
    display: flex;
    background: rgba(231, 237, 240, 1);
  }

  &__details-snippets-group {
    margin-right: 38px;

    &--location-n-timing {
      width: 35%;
    }

    &--visit-focus {
      width: 45%;
    }

    &--collab-value-n-comps {
      width: 20%;
    }
  }

  &__details-snippet {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }

    &--location {
    }
    &--timing {
    }
    &--visit-focus {
    }
    &--collab-value {
    }
    &--comps {
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .open-collab-snippet {
    &__details-snippets-group {
      &--location-n-timing {
        width: 44%;
      }

      &--visit-focus {
        width: 38%;
      }

      &--collab-value-n-comps {
        width: 18%;
      }
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .open-collab-snippet {
    // gray bg half ---------------------
    &__gray-bg-half {
      flex-wrap: wrap;
    }

    &__details-snippets-group {
      display: contents;
    }

    &__details-snippet {
      &--location {
        width: 50%;
        order: 1;
      }
      &--visit-focus {
        width: 50%;
        order: 2;
      }
      &--timing {
        width: calc(50% + 30px);
        order: 3;
      }
      &--collab-value {
        margin-right: 25px;
        order: 4;
      }
      &--comps {
        order: 5;
      }
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .open-collab-snippet {
    // white bg half --------------------
    &__white-bg-half {
      padding-bottom: 20px;
      flex-wrap: wrap;
    }

    &__logo {
      margin-bottom: 7px;
    }

    &__name {
      width: calc(100% - 135px);
      margin-bottom: 7px;
    }

    // gray bg half ---------------------
    &__gray-bg-half {
      flex-wrap: wrap;
    }

    &__details-snippets-group {
      display: contents;
    }

    &__details-snippet {
      &--location {
        width: calc(100% - 180px);
        order: 1;
      }
      &--collab-value {
        order: 2;
      }
      &--timing {
        margin-bottom: 13px !important;
        width: calc(100% - 180px);
        order: 3;
      }
      &--comps {
        margin-bottom: 13px !important;
        order: 4;
      }
      &--visit-focus {
        margin-left: -6px;
        order: 5;
      }
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .open-collab-snippet {
    // white bg half --------------------
    &__white-bg-half {
      padding: 18px 25px 22px;
      flex-wrap: wrap;
    }

    &__name {
      order: 1;
      width: 100%;
      margin-bottom: 7px;
    }

    &__logo {
      order: 2;
      margin: 0 20px 10px 0;
    }

    &__big-location-snippet {
      order: 3;
      flex-grow: 1;
      min-width: calc(100% - 140px);
      margin-bottom: 10px;
      visibility: visible;
      position: static;
    }

    &__view-collab-btn-n-status {
      order: 4;
    }

    &__view-collab-btn {
      order: 4;
    }

    // gray bg half ---------------------
    &__gray-bg-half {
      padding: 20px 25px 3px;
      flex-wrap: wrap;
    }

    &__details-snippets-group {
      display: contents;
    }

    &__details-snippet {
      &--timing {
        order: 1;
        width: 50%;
      }
      &--collab-value {
        order: 2;
        width: 50%;
      }
      &--comps {
        order: 3;
        width: 50%;
        margin: -13px 0 10px 50% !important;
      }
      &--location {
        position: absolute;
        visibility: hidden;
      }
      &--visit-focus {
        order: 4;
        margin: 0 0 0 -6px !important;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .open-collab-snippet {
    // white bg half --------------------
    &__white-bg-half {
      padding: 18px 25px 22px;
      flex-wrap: wrap;
    }

    &__name {
      order: 1;
      width: 100%;
      margin-bottom: 7px;
    }

    &__logo {
      order: 2;
      margin: 0 20px 10px 0;
    }

    &__big-location-snippet {
      order: 3;
      flex-grow: 1;
      min-width: calc(100% - 140px);
      margin-bottom: 10px;
      visibility: visible;
      position: static;
    }

    &__view-collab-btn-n-status {
      order: 4;
    }

    &__view-collab-btn {
      order: 4;
    }

    // gray bg half ---------------------
    &__gray-bg-half {
      padding: 20px 25px 20px !important;
      flex-wrap: wrap;
    }

    &__details-snippets-group {
      display: contents;
    }

    &__details-snippet {
      &--timing {
        order: 1;
        width: 55%;
      }
      &--collab-value {
        order: 2;
        width: 45%;
      }
      &--comps {
        order: 3;
        width: 45%;
        padding: 8px 0 4px 0;
        margin: 0 0 0 21px;

        &--not-social-media-manager {
          margin: -13px 0 10px 55% !important;
          outline: 1px rgba(255, 0, 0, 0.25) solid;
        }
      }
      &--location {
        position: absolute;
        visibility: hidden;
      }
      &--visit-focus {
        order: 4;
        margin: 0 0 0 -6px !important;
      }
    }
  }
}
</style>
