<template>
  <div class="photowall-admin-page">
    <!-- Header -->
    <div class="header photowall-admin-page__header">
      <h1 class="global-h1 header__title">
        Photo Wall<span v-if="widgetData"> - {{ widgetData.name }}</span>
      </h1>
      <div class="header__subtitle">Embed amazing photos directly on your website!</div>
    </div>
    <!-- / Header -->

    <Loader v-if="isLoading" />

    <template v-else>
      <div class="photowall-admin-page__content">
        <!-- PhotoWall Preview & Controls -->
        <div class="photowall-preview-and-controls photowall-admin-page__preview-and-controls">
          <div class="photowall-preview-and-controls__header">
            <h3 class="global-h3 photowall-preview-and-controls__title">Preview</h3>
          </div>

          <div class="photowall-preview-itself photowall-preview-and-controls__preview">
            <div class="photowall-preview-itself__top-panel">
              <div class="photowall-preview-itself__top-panel-left">
                <LinkWithIcon @click="isCustomizePanelOpened = !isCustomizePanelOpened" isDottedUnderline color="blue">
                  <template #icon><IconEmbedded name="gear_2-5" :size="21" /></template>
                  <span><b>Customize</b></span>
                </LinkWithIcon>
              </div>

              <div class="photowall-preview-itself__edit-link"></div>

              <div class="photowall-preview-itself__top-panel-right">
                <!--<div class="photowall-preview-itself__counter" v-if="photosInTotal > 0 || videosInTotal > 0">
                  <span style="font-weight: bold">{{ photosInTotal }}</span> photos
                  <span v-if="videosInTotal"
                    >, <span style="font-weight: bold">{{ videosInTotal }}</span> videos</span
                  >
                  selected
                </div>-->

                <LinkWithIcon
                  v-if="widgetData.photoIds?.length > 0"
                  class="photowall-preview-itself__edit-link"
                  color="blue"
                  isDottedUnderline
                  @click="
                    () => {
                      isCustomizePanelOpened = false;
                      isEditModeOn = !isEditModeOn;
                    }
                  "
                >
                  <template #icon><IconEmbedded name="pencil_2" :size="20" /></template>
                  <span v-if="isEditModeOn">Cancel Edit/Reorder mode</span>
                  <span v-else>Edit/Reorder photos</span>
                </LinkWithIcon>
              </div>
            </div>

            <TransitionedHeightAuto :isOpened="isCustomizePanelOpened" isKeepAlive style="position: relative; z-index: 4">
              <CustomizationControls
                class="photowall-preview-and-controls__customization-controls"
                :settings="widgetData.settings"
                v-model:photoWallName="widgetData.name"
                :numberOfImages="widgetData.photoIds?.length"
              />
            </TransitionedHeightAuto>

            <PhotoWallPreview
              :selectedImages="widgetData.photoIds"
              :settings="widgetData.settings"
              :isEdited="isEditModeOn"
              :removeImageFunc="removeImageFromThePhotowall"
              @reorder="newImagesArray => (widgetData.photoIds = newImagesArray)"
            />
          </div>
        </div>
        <!-- / PhotoWall Preview & Controls -->

        <AllPhotosSection
          class="photowall-admin-page__all-photos-section"
          :isThumbnailsWithCheckboxes="true"
          :checkedFiles="widgetData.photoIds"
          :showTotalAssetCount="false"
          @checkUncheckFile="
            (fileName, isChecked) => {
              if (isChecked) selectImage(fileName);
              else removeImage(fileName);
            }
          "
          @checkUncheckAll="
            (images, isChecked) => {
              // unselect all the images first because otherwise it will just inverse the selection withing a certain photos section
              images.forEach(i => removeImage(i));

              images.forEach(i => {
                if (isChecked) selectImage(i);
                else removeImage(i);
              });
            }
          "
        />
      </div>

      <div class="photowall-admin-page__divider"></div>

      <!-- Bottom floating panel -->
      <div class="bottom-floating-panel photowall-admin-page__bottom-floating-panel">
        <div class="bottom-floating-panel__left-side">
          <LinkWithIcon v-if="widgetData?.photoIds?.length > 0" class="bottom-floating-panel__link-with-icon" color="blue" isDottedUnderline @click="askToClearPhotos">
            <template #icon><IconEmbedded name="remove_4" :size="15" /></template>
            <span>Unselect all</span>
          </LinkWithIcon>
        </div>

        <div class="bottom-floating-panel__right-side">
          <div class="bottom-floating-panel__button-and-counter">
            <SrpButton
              class="bottom-floating-panel__button"
              color="gray"
              fill="outlined"
              iconInset="-1px auto auto auto"
              @click="$refs.copyTheCodeModal.open"
              :isDisabled="justCopied"
              :size="isEqualOrSmaller(screenSize, 'tablet-large') ? 'normal' : 'big'"
            >
              <template #icon>
                <IconEmbedded v-if="justCopied" name="check_3" :size="isEqualOrSmaller(screenSize, 'tablet-large') ? 19 : 26" />
                <IconEmbedded v-else name="tag_2-5" :size="isEqualOrSmaller(screenSize, 'tablet-large') ? 19 : 26" />
              </template>
              {{ justCopied ? "Just Copied" : "Get the Code" }}
            </SrpButton>
          </div>
          <div class="bottom-floating-panel__button-and-counter">
            <SrpButton
              class="bottom-floating-panel__button"
              iconInset="-1px auto auto auto"
              @click="save"
              :isDisabled="saveStatus?.includes('Saving') || saveStatus?.includes('Saved')"
              :size="isEqualOrSmaller(screenSize, 'tablet-large') ? 'normal' : 'big'"
            >
              <template #icon>
                <LoadingIcon v-if="saveStatus?.includes('Saving')" :size="isEqualOrSmaller(screenSize, 'tablet-large') ? 19 : 26" />
                <IconEmbedded v-else-if="saveStatus?.includes('Saved')" name="check_3-5" :size="isEqualOrSmaller(screenSize, 'tablet-large') ? 19 : 26" />
                <IconEmbedded v-else name="save_2-5" :size="isEqualOrSmaller(screenSize, 'tablet-large') ? 19 : 26" />
              </template>
              {{ screenSize === "mobile" ? "Save" : "Save Photo Wall" }}
            </SrpButton>
          </div>
        </div>
      </div>
      <!-- / Bottom floating panel -->
    </template>

    <CopyTheCodeModal ref="copyTheCodeModal" :customerId="customerId" :widgetId="widgetId" />

    <!--Delete Modal-->
    <SrpModal v-model:isVisible="showRemoveListModal">
      <template #header><h2 class="global-h2">Delete this photo wall?</h2></template>
      <template #footer>
        <div class="ui basic black button" @click="cancelRemoveList">Cancel</div>
        <div class="ui red button" @click="deleteList">Yep, delete it.</div>
      </template>
    </SrpModal>

    <!--Clear Photos Modal-->
    <SrpModal v-model:isVisible="showClearPhotosModal" size="small">
      <template #header><h2 class="global-h2" style="text-wrap: balance">Are you sure you want to clear all selected photos from this wall?</h2></template>
      <template #footer>
        <SrpButton @click="cancelClearPhotos" color="gray" fill="outlined" style="margin-right: 12px">Cancel</SrpButton>
        <SrpButton @click="clearPhotosConfirmed" color="red">Yep, remove them.</SrpButton>
      </template>
    </SrpModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import FileUtils from "@logic/FileUtils";
import { mapState } from "pinia";
import generateSrc, { ImageSize } from "@helpers/GenerateGallerySrc";
import { useHead } from "@unhead/vue";
import lodashCloneDeep from "lodash-es/cloneDeep";
import { brandColorHex } from "@/constants";
import { isEqualOrSmaller } from "@helpers/ScreenSizeHelper";

// Types
import { PhotoWidgetData } from "@contracts/photoWidget";
import { ScreenSize } from "@contracts/screenSize";
import { WidgetSettingsForAdmin } from "@contracts/widgetsSettings";

// Components
import ButtonIconExpandable from "@components/ui/ButtonIconExpandable.vue";
import Loader from "@components/Loader/Loader.vue";
import PhotoWallPreview from "@views/Integration/PhotoWallPreview.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import UploadPhotosNonAdventure from "@views/CMS/UploadPhotosNonAdventure.vue";
import VideoRender from "@components/VideoRender.vue";
import GlobalAccentColorPicker from "@components/GlobalAccentColorPicker.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import InfoIconWithTooltip from "@components/ui/InfoIconWithTooltip.vue";
import AllPhotosSection from "@components/AllPhotosSection/index.vue";
import CustomizationControls from "./CustomizationControls/index.vue";
import CopyTheCodeModal from "./CopyTheCodeModal.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";

export default defineComponent({
  name: "PhotoWallAdminOld",

  components: {
    TransitionedHeightAuto,
    CopyTheCodeModal,
    LoadingIcon,
    IconEmbedded,
    LinkWithIcon,
    ButtonIconExpandable,
    InfoIconWithTooltip,
    SrpCheckbox,
    GlobalAccentColorPicker,
    SrpButton,
    Loader,
    PhotoWallPreview,
    SrpModal,
    UploadPhotosNonAdventure,
    VideoRender,
    AllPhotosSection,
    CustomizationControls,
  },

  data() {
    return {
      screenSize: inject("screenSize") as ScreenSize,

      title: "Photo Wall",
      customerId: null as string | null,
      widgetId: null as string | null,
      isLoading: true,
      isLoadingMore: false,
      saveStatus: null,
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      widgetData: null as PhotoWidgetData | null,
      // Those adventures that Select all have been clicked on (this is not persisted)
      adventureIds: [] as string[],
      adventurePhotosResults: null,
      maxResults: 20,
      nonAdventurePhotos: [] as string[],
      previewCollapsed: false,
      justCopied: false,
      allCollapsed: false,
      showRemoveListModal: false,
      showClearPhotosModal: false,
      isEditPreview: false,
      filterType: "all",

      globalAccentColor: "" as string,
      isUseAccentColorAsGlobal: false,

      isEditModeOn: false,

      brandColorHex,

      isCustomizePanelOpened: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile"]),
    photosInTotal(): number {
      return this.widgetData.photoIds.filter(f => !FileUtils.isVideoFileType(f))?.length;
    },
    videosInTotal(): number {
      return this.widgetData.photoIds.length - this.photosInTotal;
    },
    selectedPhotoCount(): number {
      return this.widgetData?.photoIds?.length || 0;
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // @ts-ignore
    this.customerId = this.$route.params.pageId;
    // @ts-ignore
    this.widgetId = this.$route.params.photoWallId;
    this.checkFilterType();
    await this.getAllData();
    await this.getWidgetAdminSettings();
  },

  methods: {
    isEqualOrSmaller,
    removeImageFromThePhotowall(imageId: string): void {
      const index = this.widgetData.photoIds.indexOf(imageId);

      if (index > -1) {
        this.widgetData.photoIds.splice(index, 1);
      }
    },
    checkFilterType() {
      const filterType = this.$route.query.filterType;
      if (filterType) {
        this.filterType = filterType as string;
      }
    },
    async getWidgetAdminSettings(): Promise<void> {
      this.isLoading = true;

      const { data } = await axios.get<WidgetSettingsForAdmin>(`${import.meta.env.VITE_API_URL}/widget-admin/${this.customerId}/widgets/settings`);

      this.globalAccentColor = data.widgetSettings.accentColor;
      if (this.isUseAccentColorAsGlobal) {
        this.widgetData.settings.color = data.widgetSettings.accentColor;
      }

      this.isLoading = false;
    },
    async getAllData() {
      this.isLoading = true;
      const widgetDataResult = await axios.get(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.customerId}/widgets/${this.widgetId}`);
      this.widgetData = widgetDataResult.data;

      this.isUseAccentColorAsGlobal = widgetDataResult.data.settings?.color === null;

      // Using the existing endpoint to get all the photos data also...
      await this.getPhotoData();
      // Not awaiting this one
      await this.getNonAdventurePhotoData();
      // this.previewCollapsed = this.widgetData.photoIds.length === 0;
      this.isLoading = false;
      this.title += ` - ${this.widgetData.name}`;
    },
    async getPhotoData() {
      const photoResult = await axios.get(`${import.meta.env.VITE_API_URL}/photos/${this.customerId}/byAdventure?resultCount=${this.maxResults}`);
      this.adventurePhotosResults = photoResult.data;
    },
    async getNonAdventurePhotoData() {
      const photoResult = await axios.get(`${import.meta.env.VITE_API_URL}/photo-gallery/customer/${this.customerId}`);
      this.nonAdventurePhotos = photoResult.data.photoIds;
    },
    async save() {
      this.saveStatus = "Saving...";

      const _widgetData: PhotoWidgetData = lodashCloneDeep(this.widgetData);
      if (this.isUseAccentColorAsGlobal) {
        _widgetData.settings.color = null;

        if (this.isUseAccentColorAsGlobal && this.globalAccentColor !== this.widgetData.settings.color) {
          await this.updateGlobalAccentColor(this.widgetData.settings.color);
        }
      }

      const config = { headers: { "Content-Type": "application/json" } };
      await axios.put(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.customerId}/widgets/${this.widgetId}`, JSON.stringify(_widgetData), config);

      this.saveStatus = "Saved!";

      setTimeout(() => (this.saveStatus = null), 3000);
    },
    async updateGlobalAccentColor(accentColor: string): Promise<void> {
      const uri = `${import.meta.env.VITE_API_URL}/widget-admin/${this.customerId}/widgets/settings?accentColor=${encodeURIComponent(accentColor)}`;
      await axios.put(uri);
    },
    async loadMore() {
      this.isLoadingMore = true;
      this.maxResults = this.maxResults + 20;
      await this.getPhotoData();
      this.isLoadingMore = false;
    },
    addImageToNonAdventureGallery(imageId: string) {
      // Add it to the start
      this.nonAdventurePhotos.unshift(imageId);
    },
    getCredit(adventure) {
      // return adventure.creatorCredit ?? adventure.creatorDisplayName;
      return adventure.creatorDisplayName;
    },
    setRowsToShow(change: number) {
      this.widgetData.settings.rowsToShow += change;
    },
    selectAdventure(adventure: any) {
      const adventureIndex = this.adventureIds.indexOf(adventure.adventureId);
      let shouldAddAll = false;
      if (adventureIndex > -1) {
        this.adventureIds.splice(adventureIndex, 1);
      } else {
        shouldAddAll = true;
        this.adventureIds.push(adventure.adventureId);
      }
      // Now select/deselect all the photos
      adventure.photos.forEach(photoId => {
        const photoIndex = this.widgetData.photoIds.indexOf(photoId);
        if (!shouldAddAll && photoIndex > -1) {
          // Remove it
          this.widgetData.photoIds.splice(photoIndex, 1);
        }
        if (shouldAddAll && photoIndex < 0) {
          // Add it
          this.widgetData.photoIds.push(photoId);
        }
      });
    },
    selectImage(image: string) {
      const index = this.widgetData.photoIds.indexOf(image);
      if (index > -1) {
        this.widgetData.photoIds.splice(index, 1);
      } else {
        // Add it as the first image
        this.widgetData.photoIds.unshift(image);
      }
    },
    removeImage(imageId: string): void {
      const index = this.widgetData.photoIds.indexOf(imageId);
      if (index > -1) {
        this.widgetData.photoIds.splice(index, 1);
      }
    },
    async copy(): Promise<void> {
      // NOTE: Need to add the \ in the final script tag or vue gets confused
      // eslint-disable-next-line no-useless-escape
      const code = `<script id="shrpa-go__${this.customerId}--${this.widgetId}" src="https://shrpa.com/widget/photos.js" async><\/script>`;
      await navigator.clipboard.writeText(code);

      this.justCopied = true;
      setTimeout(() => {
        this.justCopied = false;
      }, 3000);
    },
    askToRemoveList() {
      this.showRemoveListModal = true;
    },
    cancelRemoveList() {
      this.showRemoveListModal = false;
    },
    async deleteList() {
      await axios.delete(`${import.meta.env.VITE_API_URL}/photo-widget-admin/${this.customerId}/widgets/${this.widgetId}`);
      this.back();
    },
    askToClearPhotos() {
      this.showClearPhotosModal = true;
    },
    cancelClearPhotos() {
      this.showClearPhotosModal = false;
    },
    clearPhotosConfirmed() {
      this.widgetData.photoIds.splice(0);
      this.showClearPhotosModal = false;
    },
    back() {
      this.$router.push({ name: "WidgetDirections", params: { pageId: this.customerId } });
    },
    generateImageSrc(imageName: string, size: ImageSize): string {
      return generateSrc(imageName, size);
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
    setFilter(type) {
      this.filterType = type;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";

// Photowall preview itself ===================================================
.photowall-preview-itself {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__top-panel {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__top-panel-left {
    gap: 7px 35px;
    display: flex;
    align-items: center;
  }

  &__top-panel-right {
    gap: 7px 35px;
    display: flex;
    align-items: center;
  }

  &__counter {
  }

  &__edit-link {
  }

  &__photo-tiles-list {
    width: 100%;
    padding: 0;
    margin: 0 0 17px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    list-style: none;
    position: relative;
    overflow: hidden;
    transition: height 0.07s ease-in-out;
  }

  &__photo-tile-wrap {
    flex-grow: 1;
  }

  &__photo-tile {
    height: 100%;
    border-radius: 4px;

    :deep(.file-thumbnail__bg-image-wrap),
    :deep(.file-thumbnail__bg-image) {
      border-radius: 4px;
    }
  }

  &__show-more-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .photowall-preview-itself {
    &__top-panel {
      align-items: flex-end;
    }

    &__top-panel-right {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .photowall-preview-itself {
    &__top-panel {
      align-items: flex-end;
    }

    &__top-panel-right {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

// PhotoWall Preview & Controls ===============================================
.photowall-preview-and-controls {
  padding: 20px 0 30px;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 50px);
    height: 100%;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto -25px;
    z-index: -1;
    background: rgba(231, 237, 240, 1);
  }

  &__header {
    padding-bottom: 13px;
    margin-bottom: 26px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__title {
  }

  &__customization-controls {
    margin-bottom: 20px;

    &::before {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      inset: -7px auto auto 45px;
      transform: rotate(45deg);
      background: white;
    }
  }

  &__preview {
  }
}

.customize-section {
  margin-top: 1rem;
}

.photo-div {
  position: relative;

  //Note: Moved a style into main.scss
}

// Header =====================================================================
.header {
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;

  &__title {
    margin-bottom: 4px;
  }

  &__subtitle {
  }
}

// Files counter string =======================================================
.files-counter-string {
  height: 20px;
  position: relative;
  color: rgba(91, 91, 91, 1);
  font: 14px/18px sans-serif;
  text-align: center;

  &__in-wrap1 {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    inset: 0 auto auto 0;
    white-space: nowrap;
    text-align: center;
  }

  &__in-wrap2 {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .files-counter-string {
    &__in-wrap1 {
      justify-content: flex-end;
    }
  }
}

// Bottom floating panel ======================================================
.bottom-floating-panel {
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 0;

  &::before {
    content: "";
    width: calc(100% + 60px);
    height: calc(100% + 60px);
    position: absolute;
    inset: auto auto 0 -30px;
    z-index: -1;
    pointer-events: none;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
  }

  &__left-side {
    display: flex;
    align-items: center;
  }

  &__right-side {
    gap: 0 16px;
    display: flex;
    align-items: center;
  }

  &__button-and-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__button {
    margin-bottom: 3px;
  }

  &__files-counter {
    width: 100%;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .bottom-floating-panel {
    &__right-side {
      gap: 0 10px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .bottom-floating-panel {
    &__right-side {
      gap: 0 10px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .bottom-floating-panel {
    flex-direction: column;
    align-items: flex-end;

    &::before {
      height: calc(100% + 110px);
      bottom: -35px;
    }

    &__left-side {
      margin-bottom: 15px;
    }
    &__right-side {
      gap: 0 10px;
    }
  }
}

// PhotoWall Admin page =======================================================
.photowall-admin-page {
  width: $desktop-wide-right-col-width;
  margin: 0 auto;
  position: relative;
  z-index: 0;

  &__header {
    margin-bottom: 30px;
  }

  &__content {
  }

  &__preview-and-controls {
    margin-bottom: 45px;
  }

  &__all-photos-section {
    margin-bottom: 45px;
  }

  &__divider {
    width: 100%;
    height: 0;
    margin-bottom: 45px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__bottom-floating-panel {
    width: 100%;
    position: sticky;
    inset: auto auto 0 0;
    z-index: 10;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .photowall-admin-page {
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .photowall-admin-page {
    width: $laptop-right-col-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .photowall-admin-page {
    width: $tablet-large-content-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .photowall-admin-page {
    width: $tablet-content-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .photowall-admin-page {
    width: $mobile-content-width;

    &__bottom-floating-panel {
      bottom: 90px;
    }
  }
}
</style>
