<template>
  <div>
    <div class="subscription-billing-page">
      <div class="subscription-billing-page__header">
        <h1 class="global-h1 subscription-billing-page__title">Subscription/Billing</h1>
      </div>

      <Loader v-if="isLoading" />

      <template v-else>
        <NoteWithIcon v-if="subscriptionData?.isDisabled" color="red" style="margin-top: 15px">
          <template #icon><IconEmbedded name="warning_2-5" style="position: relative; top: -2px" /></template>
          <template #text>
            <div>
              <h3 class="global-h3" style="margin-bottom: 3px">This account is no longer active.</h3>
              <div>Reach out to <CopyText :text="'sales@shrpa.com'" /> to learn about the benefits of renewing!</div>
            </div>
          </template>
        </NoteWithIcon>

        <template v-else>
          <div class="subscription-billing-page__section-wrap subscription-billing-page__section-wrap--creator-visits">
            <div class="global-h3 subscription-billing-page__section-title">Creator Visits</div>
            <!-- Creator visits info -->
            <div class="creator-visits-info subscription-billing-page__section">
              <div class="creator-visits-info__top-section">
                <div class="creator-visits-info__big-number-and-description">
                  <div class="creator-visits-info__big-number creator-visits-info__big-number--red">
                    {{ subscriptionData?.collabsRemaining }}
                  </div>
                  <div class="creator-visits-info__description">
                    {{ `Creator Visit${subscriptionData?.collabsRemaining === 1 ? "" : "s"}` }}<br />
                    remaining
                  </div>
                </div>

                <template v-if="!subscriptionData?.isCancelling && !subscriptionData?.isMonthly">
                  <div class="creator-visits-info__vertical-divider"></div>

                  <div v-if="Boolean(subscriptionData?.nextSubscriptionLevel)" class="creator-visits-info__big-number-and-description">
                    <div class="creator-visits-info__big-number">
                      {{ subscriptionData?.nextSubscriptionLevel?.collabCount }}
                    </div>
                    <div class="creator-visits-info__description">
                      {{ `Creator Visit${subscriptionData?.nextSubscriptionLevel?.collabsTransferrable === 1 ? "" : "s"}` }}<br />
                      {{ subscriptionData?.nextSubscriptionLevel?.collabsTransferrable === 1 ? "is" : "are" }} scheduled to be added
                      <span v-if="subscriptionData?.subscriptionEndDate">on {{ moment(subscriptionData?.subscriptionEndDate).format("MMMM Do, YYYY") }}</span>
                    </div>
                  </div>

                  <div v-else class="creator-visits-info__big-number-and-description">
                    <div class="creator-visits-info__big-number">
                      {{ subscriptionData?.totalCollabsForYearlySubscription }}
                    </div>
                    <div class="creator-visits-info__description">
                      {{ `Creator Visit${subscriptionData?.totalCollabsForYearlySubscription === 1 ? "" : "s"}` }}<br />
                      {{ subscriptionData?.totalCollabsForYearlySubscription === 1 ? "is" : "are" }} scheduled to be added
                      <span v-if="subscriptionData?.subscriptionEndDate">on {{ moment(subscriptionData?.subscriptionEndDate).format("MMMM Do, YYYY") }}</span>
                    </div>
                  </div>
                </template>
              </div>
              <div class="creator-visits-info__bottom-section">
                <SrpButton class="creator-visits-info__add-more-btn" color="orange" @click="isAddMoreCreatorVisitsModalVisible = true">
                  <template #icon><IconEmbedded name="plus_4" :size="20" /></template>
                  Add More Creator Visits
                </SrpButton>

                <AddMoreCreatorVisitsModal v-model:isVisible="isAddMoreCreatorVisitsModalVisible" :communityId="pageId" @purchase="loadSubscription" />
              </div>
            </div>
            <!-- / Creator visits info -->
          </div>

          <div class="subscription-billing-page__section-wrap subscription-billing-page__section-wrap--your-subscription">
            <div class="global-h3 subscription-billing-page__section-title">Your Subscription</div>
            <!-- Your subscription info -->
            <div class="your-subscription-info subscription-billing-page__section">
              <div style="flex-grow: 1; display: flex; flex-direction: column; align-items: center; justify-content: center">
                <div class="your-subscription-info__name-and-info-icon">
                  <div class="global-h1 your-subscription-info__name">
                    {{ subscriptionData?.subscriptionName }}
                  </div>
                  <InfoIconWithTooltip class="your-subscription-info__info-icon" :width="screenSize === 'mobile' ? 300 : 400">
                    <MonthlyPlanDetails v-if="subscriptionData?.isMonthly" />
                    <YearlyPlanDetails v-else :totalCollabsForYearlySubscription="subscriptionData?.totalCollabsForYearlySubscription" :subscriptionName="subscriptionData?.subscriptionName" />
                  </InfoIconWithTooltip>
                </div>

                <div v-if="subscriptionData.isMonthly" class="global-h4 your-subscription-info__price">
                  {{ `$${monthlyPlanPrice}/month` }}
                </div>

                <div v-else class="global-h4 your-subscription-info__price">
                  {{ `$${subscriptionData?.subscriptionPrice.toLocaleString("en-US")}/year` }}
                </div>

                <div v-if="!subscriptionData?.isSubscriptionEndDatePast" class="your-subscription-info__description">
                  {{ `${subscriptionData?.totalCollabsForYearlySubscription} Creator Visit${subscriptionData?.totalCollabsForYearlySubscription === 1 ? "" : "s"}` }}

                  <span style="text-wrap: nowrap">+ Creator Suite subscription</span>
                </div>
              </div>

              <template v-if="!subscriptionData?.isMonthly && subscriptionData?.subscriptionEndDate">
                <div class="your-subscription-info__horizontal-divider"></div>

                <div class="your-subscription-info__renewing-date">
                  {{ subscriptionData?.isCancelling ? "Ends" : "Renews" }} on:
                  <span v-if="subscriptionData?.subscriptionEndDate">
                    {{ moment(subscriptionData?.subscriptionEndDate).format("MMMM Do, YYYY") }}

                    <span v-if="subscriptionData?.nextSubscriptionLevel" style="text-wrap: nowrap">
                      {{ `as ${subscriptionData?.nextSubscriptionLevel.frontendName} - $${subscriptionData?.nextSubscriptionLevel.yearlyPrice.toLocaleString("en-US")}/year` }}
                    </span>
                  </span>
                </div>
              </template>

              <div class="your-subscription-info__horizontal-divider"></div>

              <div class="your-subscription-info__price-sheet">
                Interested in other options?

                <span style="text-wrap: nowrap">
                  <a class="global-a" href="https://cdn.shrpa.com/legal/shrpa_packages.pdf" target="_blank" style="text-wrap: nowrap">price sheet</a>&nbsp;<IconEmbedded
                    name="external-link_2"
                    :size="15"
                    color="rgba(35, 131, 133, 0.5)"
                    style="position: relative; top: 3px"
                  />
                </span>
              </div>
            </div>
            <!-- / Your subscription info -->
          </div>
        </template>

        <div v-if="!subscriptionData?.isMonthly" class="subscription-billing-page__section-wrap">
          <!-- Text and CTA button section -->
          <div class="text-and-cta-button-section text-and-cta-button-section--gray subscription-billing-page__section">
            <div class="text-and-cta-button-section__left-side">
              <h3 class="global-h3 text-and-cta-button-section__title">Prefer Monthly?</h3>
              <div class="text-and-cta-button-section__description">After your first year, you can update Creator Suite to a ${{ monthlyPlanPrice }}/mo subscription.</div>
            </div>
            <div class="text-and-cta-button-section__right-side">
              <div v-if="isSwitchToMonthlyWasRequested" style="max-width: 250px; align-self: center; font-weight: 500; text-align: left">
                <h5 class="global-h5" style="margin-bottom: 6px; color: rgba(65, 97, 56, 1); font-weight: 700">Request sent.</h5>
                It may take up to <b>24 hours</b> for that to be reflected on your account.
              </div>

              <SrpButton v-else class="text-and-cta-button-section__cta-button" fill="outlined" @click="() => $refs.switchToMonthlyModal?.open()">Switch to Monthly</SrpButton>

              <SwitchToMonthlyModal ref="switchToMonthlyModal" :pageId="pageId" :subscriptionEndDate="subscriptionData?.subscriptionEndDate" @requestSent="isSwitchToMonthlyWasRequested = true" />
            </div>
          </div>
          <!-- / Text and CTA button section -->
        </div>

        <div v-if="subscriptionData?.canManageSubscriptionInStripe" class="subscription-billing-page__section-wrap">
          <!-- Text and CTA button section -->
          <div class="text-and-cta-button-section subscription-billing-page__section">
            <div class="text-and-cta-button-section__left-side">
              <h3 class="global-h3 text-and-cta-button-section__title">Additional Subscription Details</h3>
              <div class="text-and-cta-button-section__description">
                <ul class="global-ul">
                  <li>View/Update Payment Method</li>
                  <li>View/Update Billing Email & Company Name</li>
                  <li>View/Update Past Invoices</li>
                </ul>
              </div>
            </div>
            <div class="text-and-cta-button-section__right-side">
              <SrpButton class="text-and-cta-button-section__cta-button" fill="outlined" @click="navigateToStripe">{{ subscriptionData?.isSpotlight ? "Update" : "Manage" }} my Subscription</SrpButton>
              <span v-if="stripeSessionMessage">
                <b>{{ stripeSessionMessage }}</b>
              </span>
            </div>
          </div>
          <!-- / Text and CTA button section -->
        </div>

        <div class="subscription-billing-page__section-wrap subscription-billing-page__section-wrap--changes-to-subscription">
          <!-- Text and CTA button section -->
          <div class="text-and-cta-button-section text-and-cta-button-section--gray subscription-billing-page__section">
            <div class="text-and-cta-button-section__left-side">
              <h3 class="global-h3 text-and-cta-button-section__title">Questions or Changes to Your Subscription?</h3>
              <div class="text-and-cta-button-section__description text-and-cta-button-section__description--text-wrap-balance">
                Reach out anytime to <CopyTextNew>support@shrpa.com</CopyTextNew> and we'll be happy to help!
              </div>
            </div>
          </div>
          <!-- / Text and CTA button section -->
        </div>

        <div class="subscription-billing-page__section-wrap subscription-billing-page__section-wrap--w9">
          <!-- Text and CTA button section -->
          <div class="text-and-cta-button-section subscription-billing-page__section">
            <div class="text-and-cta-button-section__left-side">
              <h3 class="global-h3 text-and-cta-button-section__title">Need a W9?</h3>
              <div class="text-and-cta-button-section__description">
                Download Shrpa's W9 for your records:
                <a class="global-a" href="https://cdn.shrpa.com/legal/shrpa-w9.pdf" target="_blank">https://cdn.shrpa.com/legal/shrpa-w9.pdf</a>&nbsp;<IconEmbedded
                  name="external-link_2"
                  :size="15"
                  color="rgba(35, 131, 133, 0.5)"
                  style="position: relative; top: 3px"
                />
              </div>
            </div>
          </div>
          <!-- / Text and CTA button section -->
        </div>

        <div v-if="!subscriptionData?.isDisabled" class="subscription-billing-page__cancel-subscription-link-wrap">
          <div v-if="isSubscriptionCancellationWasRequested" style="max-width: 450px; font-weight: 500">
            <h5 class="global-h5" style="margin-bottom: 6px; color: rgba(236, 87, 59, 1)">Cancellation request sent</h5>
            We'll process your request and reach out when it's complete.
          </div>

          <LinkWithIcon v-else class="subscription-billing-page__cancel-subscription-link" color="red" iconInset="0 0 0 4px" isDottedUnderline @click="() => $refs.cancelYourSubscriptionModal?.open()">
            <template #icon><IconEmbedded name="remove_4" :size="12" /></template>
            <span>Cancel subscription</span>
          </LinkWithIcon>

          <CancelYourSubscriptionModal
            ref="cancelYourSubscriptionModal"
            :subscriptionEndDate="subscriptionData?.subscriptionEndDate"
            :pageId="pageId"
            @requestSent="isSubscriptionCancellationWasRequested = true"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import axios from "axios";
import { Page } from "@contracts/pages";
import OrgContext from "@logic/OrgContext";
import moment from "moment";
import { mapState } from "pinia";
import { useUserProfileStore } from "@stores/userProfileStore";
import { useHead } from "@unhead/vue";
import { MetricSender } from "@/helpers/MetricSender";
import { shrpaApi } from "@repos/axios";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import CopyText from "@components/CopyText.vue";
import Loader from "@components/Loader/Loader.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import InfoIconWithTooltip from "@components/ui/InfoIconWithTooltip.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import AddMoreCreatorVisitsModal from "@components/Modals/AddMoreCreatorVisitsModal.vue";
// plans details static
import MonthlyPlanDetails from "./MonthlyPlanDetails.vue";
import YearlyPlanDetails from "./YearlyPlanDetails.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
import SwitchToMonthlyModal from "@views/Community/SubscriptionSettings/SwitchToMonthlyModal.vue";
import CancelYourSubscriptionModal from "@views/Community/SubscriptionSettings/CancelYourSubscriptionModal.vue";
import CopyTextNew from "@components/CopyTextNew.vue";

// Note: Integrates with Stripe Customer Portal
// - https://stripe.com/docs/customer-management/integrate-customer-portal

interface GeneralSubscriptionInfo {
  adventureWalls: number;
  backendName: string;
  collabCount: number;
  collabsTransferrable: number;
  communityUploadPages: number;
  frontendName: string;
  isRetired: boolean;
  lodgingAddonPriceId: string;
  photoWalls: number;
  stripePriceId: string;
  subscriptionId: string;
  yearlyPrice: number;
}

interface CurrentSubscriptionData {
  billingEmail: string;
  canManageSubscriptionInStripe: boolean;
  collabsRemaining: number;
  isCancelling: boolean;
  isDisabled: boolean;
  isMonthly: boolean;
  isSpotlight: boolean;
  nextSubscriptionLevel: GeneralSubscriptionInfo | null;
  paymentStatus: string;
  stripePaymentLinkUri: string | null;
  subscriptionEndDate: string;
  subscriptionName: string;
  subscriptionPrice: number;
  isSubscriptionEndDatePast: boolean;
  totalCollabsForYearlySubscription: number;
  trialEndDate: null;
}

export default defineComponent({
  name: "SubscriptionSettings",

  components: {
    CopyTextNew,
    CancelYourSubscriptionModal,
    SwitchToMonthlyModal,
    LoadingIcon,
    AddMoreCreatorVisitsModal,
    LinkWithIcon,
    InfoIconWithTooltip,
    SrpButton,
    Loader,
    CopyText,
    IconEmbedded,
    NoteWithIcon,
    // plans info static
    MonthlyPlanDetails,
    YearlyPlanDetails,
  },

  data() {
    return {
      globalRemoteLogger: inject("globalRemoteLogger") as any,
      screenSize: inject("screenSize") as ScreenSize,

      monthlyPlanPrice: 200,

      title: "Subscription Management",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      pageId: "",
      orgName: null,
      isLoading: true,
      // Tells the user the state of the session setup/redirect to Stripe
      stripeSessionMessage: null,

      subscriptionData: null as CurrentSubscriptionData | null,

      isSwitchToMonthlyWasRequested: false,
      isSubscriptionCancellationWasRequested: false,

      isAddMoreCreatorVisitsModalVisible: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile"]),
    daysUntilTrialEnd(): number | null {
      if (!this.subscriptionData?.trialEndDate) {
        return null;
      }
      const trialEndDate = moment(this.subscriptionData.trialEndDate);
      const today = moment();
      return trialEndDate.diff(today, "days");
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.pageId = this.$route.params.pageId as string;
    const orgInContext = OrgContext.getOrgInContext(this);
    this.orgName = orgInContext?.name;
    this.title += " - " + this.orgName;
    await this.loadSubscription();

    MetricSender.sendMetric("Cust-ViewSubscription", null);
  },

  methods: {
    moment,
    async loadSubscription() {
      const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/customers/${this.pageId}/subscriptions/current`);
      this.subscriptionData = data;
      this.isLoading = false;
    },
    async navigateToStripe() {
      try {
        this.stripeSessionMessage = "Redirecting...";
        const returnUrl = window.location.href;
        const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/customers/${this.pageId}/stripe-session?returnUrl=${encodeURIComponent(returnUrl)}`);
        // @ts-ignore
        const currentUserAuthId = this.getViewingUserProfile?.authNameId;
        this.globalRemoteLogger.info(`StripeManageSubscription: ${this.pageId} by ${currentUserAuthId}`, true);
        const stripeUrl = data;
        window.location.href = stripeUrl;
      } catch (error) {
        this.stripeSessionMessage = "Error generating Stripe Session.";
        this.globalRemoteLogger.error(`--ALERT-- StripeSessionGenerate Failed for ${this.pageId} error: ${error.message}`, true);
      }
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/variables.scss";

// Creator visits info ========================================================
.creator-visits-info {
  @include standardFont;
  flex-grow: 1;
  padding: 26px 32px;
  outline: 1px rgba(231, 237, 240, 1) solid;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(231, 237, 240, 1);

  &__top-section {
    margin-bottom: 18px;
    display: flex;
  }

  &__big-number-and-description {
    margin-right: 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__big-number {
    margin-right: 11px;
    color: rgba(0, 0, 0, 1);
    font:
      65px/65px "Quicksand",
      sans-serif;

    &--red {
      color: rgba(210, 76, 76, 1);
    }
  }

  &__description {
    @include standardFont;
    max-width: 200px;
  }

  &__vertical-divider {
    margin-right: 20px;
    border-right: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__bottom-section {
  }

  &__add-more-btn {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .creator-visits-info {
    &__big-number-and-description {
      flex-direction: column;
    }

    &__big-number {
      margin: 0;
      font-weight: 500;
      font-size: 45px;
      line-height: 45px;
    }

    &__description {
      text-align: center;
    }
  }
}

// Your subscription info =====================================================
.your-subscription-info {
  @include standardFont;
  flex-grow: 1;
  padding: 26px 32px;
  outline: 1px rgba(217, 238, 211, 1) solid;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(217, 238, 211, 1);

  &__name-and-info-icon {
    margin-bottom: 1px;
    display: flex;
    align-items: center;
  }

  &__name {
    margin-right: 5px;
    line-height: 35px;
  }

  &__info-icon {
    position: relative;
    top: 3px;
  }

  &__price {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__description {
    margin-bottom: 12px;
    text-align: center;
  }

  &__horizontal-divider {
    width: 100%;
    max-width: 85%;
    margin-bottom: 15px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__renewing-date {
    margin-bottom: 12px;
    text-align: center;
  }

  &__price-sheet {
    text-align: center;
  }
}

// Text and CTA button section ================================================
.text-and-cta-button-section {
  @include standardFont;
  flex-grow: 1;
  padding: 26px 32px;
  outline: 1px rgba(0, 0, 0, 0.15) solid;
  border-radius: 6px;
  display: flex;

  &--blue {
    outline: 1px rgba(231, 237, 240, 1) solid;
    background: rgba(231, 237, 240, 1);
  }

  &--green {
    outline: 1px rgba(217, 238, 211, 1) solid;
    background: rgba(217, 238, 211, 1);
  }

  &--gray {
    outline: 1px rgba(243, 243, 243, 1) solid;
    background: rgba(243, 243, 243, 1);
  }

  &__left-side {
    flex-grow: 1;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__description {
    @include standardFont;

    &--text-wrap-balance {
      text-wrap: balance;
    }
  }

  &__right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  &__cta-button {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .text-and-cta-button-section {
    gap: 20px;
    flex-direction: column;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .text-and-cta-button-section {
    gap: 20px;
    flex-direction: column;
  }
}

// Subscription/billing page ==================================================
.subscription-billing-page {
  gap: $desktop-wide-grid-gap-width;
  width: $desktop-wide-right-col-width;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  &__header {
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 3px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__title {
  }

  &__section-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--creator-visits {
      width: calc(50% - $desktop-wide-grid-gap-width / 2);
    }

    &--your-subscription {
      width: calc(50% - $desktop-wide-grid-gap-width / 2);
    }

    &--changes-to-subscription {
      width: 100%;
    }

    &--w9 {
      width: 100%;
    }
  }

  &__section-title {
    margin-bottom: 22px;
  }

  &__section {
  }

  &__cancel-subscription-link-wrap {
    @include standardFont;
  }

  &__cancel-subscription-link {
    margin-left: -10px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .subscription-billing-page {
    gap: $desktop-grid-gap-width;
    width: $desktop-right-col-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .subscription-billing-page {
    gap: $laptop-grid-gap-width;
    width: $laptop-right-col-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .subscription-billing-page {
    gap: $tablet-large-grid-gap-width;
    width: $tablet-large-content-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .subscription-billing-page {
    gap: calc($tablet-grid-gap-width + 5px);
    width: $tablet-content-width;

    &__header {
      padding-bottom: 15px;
      margin-bottom: 10px;
    }

    &__section-wrap {
      &--creator-visits {
        width: 100%;
      }

      &--your-subscription {
        width: 100%;
      }

      &--changes-to-subscription {
        width: 100%;
      }

      &--w9 {
        width: 100%;
      }
    }

    &__section-title {
      margin-bottom: 10px;
    }

    &__section {
      padding: 16px 22px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .subscription-billing-page {
    gap: calc($mobile-grid-gap-width + 5px);
    width: $mobile-content-width;

    &__header {
      padding-bottom: 15px;
      margin-bottom: 10px;
    }

    &__section-wrap {
      &--creator-visits {
        width: 100%;
      }

      &--your-subscription {
        width: 100%;
      }

      &--changes-to-subscription {
        width: 100%;
      }

      &--w9 {
        width: 100%;
      }
    }

    &__section-title {
      margin-bottom: 10px;
    }

    &__section {
      padding: 16px 22px;
    }
  }
}
</style>
