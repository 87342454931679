import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import { ComponentPublicInstance } from "vue";
import { useToastsStore } from "@stores/toasts";
import { MetricSender } from "@/helpers/MetricSender";
import { getErrorMessage, checkIsNetworkErrorFromMessage } from "@/logic/ErrorHelper";

export function processUnhandledError(error: unknown, errorHandler: string, vm: ComponentPublicInstance | null = null) {
  const remoteLogger = getGlobalRemoteLogger();

  try {
    const errorMessageDetails = getErrorMessage(error);
    const componentName = vm?.$options?.name || vm?.$?.type?.__name || vm?.$?.type?.name || "<unknown>";
    const stack = error instanceof Error ? error.stack : undefined;
    const isNetworkError = checkIsNetworkErrorFromMessage(errorMessageDetails);
    // Note: Some places have specific conflict handling code, but this catches anywhere that doesn't and has strict conflict handling on.
    const isConflictError = errorMessageDetails?.toLowerCase()?.includes("status code 409") === true;

    if (shouldFullySuppressError(errorMessageDetails)) {
      console.info("Suppressing noisy error", errorMessageDetails);
      return;
    }

    // filter out errors that should not be shown to the user.
    // ResizeObserver errors are noisy and not easy to track down.
    const displayErrorToUser = shouldShowUserError(errorMessageDetails);

    const currentUri = window.location.href;
    const category = !displayErrorToUser ? "filtered" : isNetworkError ? "network" : isConflictError ? "conflict" : "error";
    remoteLogger.error(`ErrorToast! group=${category}, error=${errorMessageDetails}`, true, false, {
      category: category,
      networkError: isNetworkError,
      showUser: displayErrorToUser,
      uri: currentUri,
      errorHandler: errorHandler,
      component: componentName,
      stacktrace: stack,
    });

    if (!displayErrorToUser) return;

    const toastsStore = useToastsStore();
    /* if (import.meta.env.DEV) {
      toastsStore.error({ message, details, stack });
    } else {*/
    // if the error is a network error, we can provide a more helpful message
    let details = "";
    let messageToUser = "Oops, something didn't work";
    if (isNetworkError) {
      messageToUser = "Network Error";
      details = "Your network seems to be having issues.\n";
    }
    details += "Please try again or contact support@shrpa.com";

    if (isConflictError) {
      messageToUser = "Data Conflict";
      details = "This data was updated since you last loaded.\nPlease refresh and try again or contact support@shrpa.com";
    }
    if (isNetworkError || isConflictError) {
      toastsStore.warn({
        message: messageToUser,
        details,
      });
      MetricSender.sendMetric("Client-NetworkError", null);
    } else {
      toastsStore.error({
        message: messageToUser,
        details,
      });
      MetricSender.sendMetric("Client-ErrorShown", null);
      MetricSender.setReplayPriority(1);
    }
  } catch (e) {
    remoteLogger.error(`FATAL ERROR: Error handling error. ${e} ${e.stack}`, true, true);
  }

  // If running locally, re-throw so we get more console info
  /* if (window.location.hostname === "localhost") {
    throw error;
  }*/
}

function shouldFullySuppressError(errorMessage: string): boolean {
  const reallyNoisyErrorMessages = [
    // From the fancybox view when a user hits next while video is playing
    "ResizeObserver", // "ResizeObserver loop completed with undelivered notifications."
  ];
  return reallyNoisyErrorMessages.some(message => errorMessage.includes(message));
}

function shouldShowUserError(errorMessage: string): boolean {
  if (!errorMessage || errorMessage === "null") return false;

  // Exact match
  const noisyErrorExactMessages = ["Script error."];
  var isNoisyExact = noisyErrorExactMessages.some(message => errorMessage === message);
  if (isNoisyExact) return false;

  // Includes check
  const noisyErrorMessages = [
    "Expected mapDiv of type HTMLElement", // Google map issue when not visible (creator settings page)
    "/sapling",
    "api.amplitude.com",
    "widget.frill.co",
    "scrollLeft", // "Cannot read properties of null (reading 'scrollLeft')"
    "Cannot read properties of undefined (reading 'files')",
    "Failed to fetch icon", // This shouldn't happen, but if it does we don't want to flood them with toasts
    "evaluating 'e.contentWindow.postMessage'", // "null is not an object (evaluating 'e.contentWindow.postMessage')""
    "Cannot read properties of undefined (reading 'style')", // Noticed on a backend page
    "undefined is not an object (evaluating 'l[o].style')",
    "The play() request was interrupted",
    "The operation was aborted.",
    "Request aborted",
    "login_required",
    "undefined is not an object (evaluating 'media.duration')",
    "undefined is not an object (evaluating 'this.media.readyState'", // Covers a bunch of video errors
    "undefined is not an object (evaluating 'media.webkit", // Covers a bunch of video errors
    "properties of null (reading 'getBoundingClientRect')",
    "ReferenceError: og is not defined", // Posthog issue it seems, rarely happens but can be really spammy
  ];
  return !noisyErrorMessages.some(message => errorMessage.includes(message));
}
