<template>
  <div class="planned-collabs-list">
    <div class="planned-collabs-list__header">
      <h1 class="global-h1 planned-collabs-list__title">Use Planned Visit</h1>
    </div>

    <ul class="planned-collabs-list__list-itself">
      <PlannedCollabSnippet
        class="planned-collabs-list__planned-collab-snippet"
        v-for="plannedCollab in props.plannedCollabs"
        :key="plannedCollab.id"
        :plannedCollab="plannedCollab"
        :isEditButtonVisible="false"
        @click:getStarted="emit('click:getStarted', plannedCollab)"
        isSimplified
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
// Components
import PlannedCollabSnippet from "@views/PaidCollab/PlannedCollabs/PlannedCollabSnippet.vue";

// Types
import { PlannedCollab } from "@contracts/plannedCollabs";

const props = withDefaults(
  defineProps<{
    communityId: string;
    plannedCollabs: Array<PlannedCollab>;
  }>(),
  {
    communityId: "",
    plannedCollabs: () => [],
  }
);

const emit = defineEmits<{
  (e: "click:getStarted", plannedCollab: PlannedCollab): void;
}>();
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Planned collabs list =======================================================
.planned-collabs-list {
  &__header {
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__title {
    display: inline-flex;
    position: relative;
  }

  &__beta-badge {
    position: relative;
  }

  &__list-itself {
    gap: $desktop-wide-grid-gap-width;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__planned-collab-snippet {
    width: 100%;
    background: rgba(231, 237, 240, 1);
  }
}
</style>
