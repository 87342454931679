<template>
  <div
    :class="{
      'number-input': true,
      'number-input--medium': props.size === 'medium',
    }"
  >
    <button
      :class="{
        'number-input__minus-btn': true,
        'number-input__minus-btn--medium': props.size === 'medium',
      }"
      @click="() => (valueComputed -= 1)"
    >
      <span class="number-input__minus-btn-span">-</span>
    </button>

    <div
      :class="{
        'number-input__number-imitation': true,
        'number-input__number-imitation--medium': props.size === 'medium',
      }"
    >
      {{ value }}
    </div>

    <button
      :class="{
        'number-input__plus-btn': true,
        'number-input__plus-btn--medium': props.size === 'medium',
      }"
      @click="() => (valueComputed += 1)"
    >
      <span class="number-input__plus-btn-span">+</span>
    </button>

    <input
      :class="{
        'number-input__number-input': true,
        'number-input__number-input--medium': props.size === 'medium',
      }"
      type="number"
      :value="props.value"
      @input="$event => (valueComputed = $event.target.value)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";

const props = withDefaults(
  defineProps<{
    size?: "small" | "medium" | "large";
    value: number;
    isPositiveOnly?: boolean;
    min?: number | null;
    max?: number | null;
  }>(),
  {
    size: "small",
    value: 0,
    isPositiveOnly: false,
    min: null,
    max: null,
  }
);

const emit = defineEmits<{
  (e: "update:value", value: number): void;
}>();

// Value computed =============================================================
const inputRerenderKey = ref<symbol>(Symbol());

const valueComputed = computed<number>({
  get: () => props.value,
  set: newValue => {
    inputRerenderKey.value = Symbol();
    emit("update:value", validateValue(newValue));
  },
});

// Validate value =============================================================
onMounted(() => (valueComputed.value = validateValue(props.value)));

function validateValue(newValue: string | number): number {
  let _newValue = Number.isInteger(+newValue) ? +newValue : 0;

  if (props.isPositiveOnly && _newValue < 0) _newValue = 0;

  if (props.min !== null && _newValue < props.min) _newValue = props.min;
  if (props.max !== null && _newValue > props.max) _newValue = props.max;

  return _newValue;
}
</script>

<style scoped lang="scss">
// Number input ===============================================================
.number-input {
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;

  &--medium {
    height: 32px;
  }

  &__minus-btn,
  &__plus-btn {
    width: 24px;
    min-width: 24px;
    height: 24px;
    box-sizing: border-box;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    font:
      500 22px/22px "Quicksand",
      sans-serif;
    vertical-align: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 100px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: -1;
      background: rgba(217, 217, 217, 1);
      transition:
        transform 0.07s ease-in-out,
        background 0.07s ease-in-out;
    }

    &:hover::before {
      transform: scale(1.15);
    }

    &:focus::before {
      background: rgba(195, 195, 195, 1);
    }

    &::after {
      content: "";
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      border-radius: 100px;
      position: absolute;
      inset: 50% auto auto 50%;
      transform: translate(-50%, -50%);
    }

    &--medium {
      width: 32px;
      min-width: 32px;
      height: 32px;
      font-size: 26px;
      line-height: 26px;
    }
  }

  &__minus-btn {
    padding-bottom: 4px;
  }

  &__plus-btn {
    padding-bottom: 3px;
  }

  &__minus-btn-span {
    transform: scale(1.5, 1);
  }

  &__plus-btn-span {
  }

  &__number-imitation {
    margin: 0 7px;
    font-family: Arial, sans-serif !important;
    font-feature-settings: "tnum" on !important;
    font-variant-numeric: tabular-nums !important;
    opacity: 0;
    pointer-events: none;

    &--medium {
      font-size: 18px;
    }
  }

  &__number-input {
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    text-align: center;
    font-family: Arial, sans-serif !important;
    font-feature-settings: "tnum" on !important;
    font-variant-numeric: tabular-nums !important;
    background: rgba(0, 0, 0, 0);
    -moz-appearance: textfield;

    &--medium {
      font-size: 18px;
    }
  }

  &__number-input::-webkit-outer-spin-button,
  &__number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
