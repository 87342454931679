import { getGlobalLink } from "./ExternalLinks";

const contentBaseUri = globalThis.Bootstrap?.Config.contentCdnBaseUri;

export enum FileMimeTypes {
  png = "image/png",
  jpeg = "image/jpeg",
  gif = "image/gif",
  pdf = "application/pdf",
  zip = "application/zip",
  heic = "image/heic",
  svg = "image/svg+xml",
  unknown = "unknown",
}

export default {
  // Checks if this file is supported for upload
  // Returns an error if NOT supported or null if it's supported
  isFileSupportedForUpload(fileName: string, fileType: string, fileSize: number, appendHelpLink = true): string {
    // Note: There are also a few one-off checks below

    // July 2024 UPDATE: Switching over to a whitelist
    const supportedFileTypes = [".mp4", ".mov", ".jpg", ".jpeg", ".png", ".webp"];
    // If it's not in the supported list, tell the user that a file extension is not supported
    const validExt = supportedFileTypes.find(ext => fileName?.toLowerCase().endsWith(ext));
    // Get the extension of the file
    if (!validExt) {
      const ext = fileName.split(".").pop();
      let message = ext + " files are not supported.";
      if (ext?.toLowerCase() === "heic" && appendHelpLink) {
        message += " See " + getGlobalLink("ConvertingHeicToJpeg");
      }
      return message;
    }

    // We occassionally get errors that indicate empty files. Possibly cloud syncing or device corruption issues.
    if (fileSize === 0) {
      return `${fileName} is empty. Confirm it is synced to your device.`;
    }

    // Old: We don't currently support svg files server-side (the packages that convert tend to rely on System.Drawing whose support is sketchy server-side)
    /* if (fileName?.toLowerCase().endsWith(".svg") == true || fileType === "image/svg+xml") {
      return ".svg files not supported";
    }*/

    return null;
  },

  async getFileMimeType(file: File): Promise<FileMimeTypes> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.onloadend = function (event: ProgressEvent<FileReader>) {
        const arr = new Uint8Array(event.target.result as ArrayBuffer).subarray(0, 12);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        let mimeType;
        if (header.startsWith("89504e47")) {
          mimeType = FileMimeTypes.png;
        } else if (header.startsWith("ffd8ffe0") || header.startsWith("ffd8ffe1") || header.startsWith("ffd8ffe2") || header.startsWith("ffd8ffe3") || header.startsWith("ffd8ffe8")) {
          mimeType = FileMimeTypes.jpeg;
        } else if (header.startsWith("47494638")) {
          mimeType = FileMimeTypes.gif;
        } else if (header.startsWith("25504446")) {
          mimeType = FileMimeTypes.pdf;
        } else if (header.startsWith("504b0304")) {
          mimeType = FileMimeTypes.zip;
        } else if (header.startsWith("00018") || header.startsWith("00018") || header.startsWith("00028")) {
          mimeType = FileMimeTypes.heic;
        } else if (header.startsWith("3c3f786d6c") || header.startsWith("3c73766720")) {
          mimeType = FileMimeTypes.svg;
        } else {
          mimeType = FileMimeTypes.unknown;
        }
        resolve(mimeType);
      };

      fileReader.onerror = function (error) {
        reject(error);
      };

      fileReader.readAsArrayBuffer(file.slice(0, 12));
    });
  },

  isVideoFileType(fileName: string, fileType: string | null = null): boolean {
    if (fileName?.toLowerCase().endsWith(".mp4")) return true;
    if (fileName?.toLowerCase().endsWith(".mov")) return true;

    if (fileType?.toLowerCase().startsWith("video")) return true;
    else return false;
  },

  getThumbnailPath(imageOrVideoId: string): string {
    return this.getAdventureImagePath(imageOrVideoId, true);
  },
  getAdventureImagePath(imageOrVideoId: string, useThumbnail = false): string {
    if (!imageOrVideoId) return "missing-image.jpg";
    if (this.isVideoFileType(imageOrVideoId)) {
      return `${contentBaseUri}/cms/images/expeditions/${useThumbnail ? "thumbnocrop/" : ""}${imageOrVideoId.split(".")[0]}.jpg`;
    } else {
      return `${contentBaseUri}/cms/images/expeditions/${useThumbnail ? "thumbnocrop/" : ""}${imageOrVideoId}`;
    }
  },
};
