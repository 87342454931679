<template>
  <teleport :to="props.teleportTo" :disabled="!props.teleportTo">
    <TransitionSlide :offset="[0, 70]" :duration="props.transitionDuration" easing="ease-in-out">
      <div v-if="_isVisible" :class="`srp-modal ${props.className}`" :style="{ zIndex: zIndex + 1 }" ref="domRefModalItself">
        <div
          :class="{
            'srp-modal__window': true,
            'srp-modal__window--shaking': isWindowShaking,
            'srp-modal__window--moved-slightly-to-the-top': props.isWithBottomGap && screenSize === 'mobile',
          }"
        >
          <slot name="insideWindow" />

          <div
            :class="{
              'srp-modal__window-in-wrap': true,
              'srp-modal__window-in-wrap--small': props.size === 'small',
              'srp-modal__window-in-wrap--medium': props.size === 'medium',
              'srp-modal__window-in-wrap--large': props.size === 'large',
            }"
            :style="props.width ? { width: `${props.width}px !important` } : {}"
          >
            <div
              v-if="props.isCloseButtonVisible"
              :class="{
                'modal-close-button': true,
                'srp-modal__close-button': true,
              }"
              @click="hideModal"
            >
              <IconEmbedded class="modal-close-button__icon" name="remove_4-5" />
            </div>

            <div v-if="slots.header" class="srp-modal__header"><slot name="header" /></div>
            <div
              :class="{
                'srp-modal__content-wrap': true,
                'srp-modal__content-wrap--with-top-curtain': props.isWithScroll && scrollPosition > 10,
              }"
            >
              <div
                v-if="slots.content"
                :class="{
                  'srp-modal__content': true,
                  'srp-modal__content--no-paddings': !props.isContentPaddings,
                  'srp-modal__content--with-scroll': props.isWithScroll,
                  'srp-modal__content--with-top-padding': !slots.header,
                }"
                :style="{ maxHeight: _maxHeight }"
                @scroll="$event => defineScrollPosition($event)"
                ref="domRefContent"
              >
                <slot name="content" :scrollToTheTop="scrollToTheTop" />
              </div>
            </div>
            <div v-if="slots.footer" class="srp-modal__footer"><slot name="footer" /></div>
          </div>
        </div>

        <slot />
      </div>
    </TransitionSlide>

    <TransitionFade :duration="props.transitionDuration" easing="ease-in-out">
      <div v-if="_isVisible" class="bg-dim-layer" @click="hideModal(null, true)" :style="{ zIndex: props.zIndex }"></div>
    </TransitionFade>
  </teleport>
</template>

<!-- prettier-ignore -->
<script lang="ts">export default { name: "SrpModal" };</script>

<script setup lang="ts">
import { ref, onMounted, watch, computed, inject, Ref, useSlots, onBeforeUnmount, provide } from "vue";

// Types
import { ScreenSize } from "@contracts/screenSize";

// Components
import { TransitionSlide, TransitionFade } from "@morev/vue-transitions";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Global variables
const screenSize = inject("screenSize", null) as Ref<ScreenSize>;

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    isClosable?: boolean; // whether it should close on bg layer click
    size?: "small" | "medium" | "large";
    width?: number;
    className?: string;
    zIndex?: number;
    isWithScroll?: boolean;
    isContentPaddings?: boolean;
    maxHeight?: string;
    isWithBottomGap?: boolean; // whether add an extra space on the bottom in order to not cover the mobile navigation panel
    isCloseButtonVisible?: boolean;
    transitionDuration?: number;
    teleportTo?: string;
  }>(),
  {
    isVisible: false,
    isClosable: true, // whether modal should close by clicking on bg layer
    size: "medium",
    width: 0,
    className: "",
    zIndex: 80,
    isWithScroll: true,
    isContentPaddings: true,
    maxHeight: "",
    isWithBottomGap: true,
    isCloseButtonVisible: true,
    transitionDuration: 150,
    teleportTo: "#app",
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "close"): void;
}>();

const slots = useSlots();

// Calc maxHeight =============================================================
const _maxHeight = computed(() => {
  if (props.maxHeight) {
    return props.maxHeight;
  } else {
    if (screenSize?.value === "mobile") return props.isWithBottomGap ? "calc(100dvh - 260px)" : "calc(100dvh - 220px)";
    else return "calc(100dvh - 220px)";
  }
});

// Show/hide modal ============================================================
const _isVisible = ref(false);

onMounted(() => {
  _isVisible.value = props.isVisible;
});

let _isVisibleTimeout = null;
let updateIsVisibleTimeout = null;
let closeTimeout = null;

watch(
  () => props.isVisible,
  () => {
    clearTimeout(_isVisibleTimeout);
    clearTimeout(updateIsVisibleTimeout);
    clearTimeout(closeTimeout);

    _isVisible.value = props.isVisible;
  }
);

function hideModal(_: Event, isClickOnBgLayer?: boolean): void {
  if (isClickOnBgLayer && !props.isClosable) {
    shakeWindow();
    return;
  }
  _isVisible.value = false;

  updateIsVisibleTimeout = setTimeout(() => emit("update:isVisible", false), props.transitionDuration);
  closeTimeout = setTimeout(() => emit("close"), props.transitionDuration);
}

// Shake window when not closable =============================================
const isWindowShaking = ref(false);
let shakingTimeout = null;

function shakeWindow() {
  clearTimeout(shakingTimeout);

  isWindowShaking.value = true;
  shakingTimeout = setTimeout(() => (isWindowShaking.value = false), 200);
}

// Define scroll position =====================================================
const scrollPosition = ref(0);

onMounted(defineScrollPosition);

function defineScrollPosition(event?: Event): void {
  scrollPosition.value = (event?.target as Element)?.scrollTop || 0;
}

// ============================================================================
// Provide the scrollPosition to catch in inside the slots and recalculate
// the SrpDropdown position or for any other purpose
provide("srpModalScrollTop", scrollPosition);

// Scroll to the top ==========================================================
const domRefContent = ref(null);

function scrollToTheTop() {
  domRefContent.value.scrollTop = 0;
}

// Set domRefs ================================================================
const domRefModalItself = ref<HTMLElement | null>(null);

// Handle Esc press ===========================================================
function handleEscPress(event: KeyboardEvent) {
  if (event.key === "Escape") {
    hideModal(event, !props.isClosable);
  }
}

onMounted(() => {
  window.addEventListener("keydown", handleEscPress);
});

onBeforeUnmount(() => {
  window.addEventListener("keydown", handleEscPress);
});

// Define expose ==============================================================
defineExpose({ domRefModalItself, domRefContent });
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";
@import "@/scss/modal-close-button.scss";
@import "@/scss/variables.scss";

// Sherpa modal ===============================================================
.srp-modal {
  width: 1px;
  height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 50dvh auto auto 50vw;

  &__window {
    max-width: calc(100vw - 40px);
    border-radius: 5px;
    position: relative;
    transform-origin: center center;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 30px 30px -25px rgba(0, 0, 0, 1);

    &--shaking {
      /* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */
      animation: 170ms ease-in-out 0s 1 reverse both running horizontal-shaking;
    }

    &--moved-slightly-to-the-top {
      top: -30px;
    }

    @keyframes horizontal-shaking {
      0% {
        transform: translateX(0);
      }
      16% {
        transform: translateX(4px) rotate(0.2deg);
      }
      33% {
        transform: translateX(-4px) rotate(-0.2deg);
      }
      50% {
        transform: translateX(4px) rotate(0.2deg);
      }
      66% {
        transform: translateX(-4px) rotate(-0.2deg);
      }
      83% {
        transform: translateX(4px) rotate(0.2deg);
      }
      100% {
        transform: translateX(0);
      }
    }
  }

  &__window-in-wrap {
    max-width: calc(100vw - 40px);

    &--small {
      width: 450px;
    }
    &--medium {
      width: 680px;
    }
    &--large {
      width: 800px;
    }
  }

  &__close-button {
    position: absolute;
    inset: -8px -8px auto auto;
    z-index: 11;
  }

  &__header {
    padding: 20px 30px 20px;
  }

  &__content-wrap {
    position: relative;

    &::before {
      content: "";
      width: calc(100% - 15px);
      height: 22px;
      border-radius: 6px;
      position: absolute;
      inset: 0 auto auto 0;
      z-index: 10;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.07s ease-in-out;
    }

    &--with-top-curtain {
      &::before {
        opacity: 1;
      }
    }
  }

  &__content {
    @include standardFont;
    width: calc(100% - 5px);
    padding: 0 25px 20px 30px;
    //scroll-behavior: smooth;

    &--with-scroll {
      position: relative;
      overflow-x: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 10px !important;
      }

      &::-webkit-scrollbar-track {
        background: white;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.25);
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.35);
      }
    }

    &--with-top-padding {
      padding-top: 20px;
    }

    &--no-paddings {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  &__footer {
    padding: 20px 30px;
    border-radius: 0 0 7px 7px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    background: rgba(223, 223, 223, 0.3);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .srp-modal {
    top: 50dvh;

    &__window {
      max-width: calc(100vw - 30px);
    }

    &__header {
      padding: 17px 20px 16px;
    }

    &__content {
      width: 100%;
      padding: 0 10px 10px 20px;

      &--with-top-padding {
        padding-top: 13px;
      }

      &--no-paddings {
        padding: 0 !important;
        margin: 0 !important;
      }
    }

    &__footer {
      padding: 16px 20px;
    }
  }
}

// Bg dim layer ===============================================================
.bg-dim-layer {
  width: 120vw;
  height: 100dvh;
  position: fixed;
  inset: 0 auto auto 0;
  background: rgba(2, 7, 10, 0.9);
  mix-blend-mode: hard-light;
}
</style>
