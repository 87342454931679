<template>
  <SrpModal v-model:isVisible="isModalVisible">
    <template #content>
      <h2 class="global-h2" style="margin-bottom: 10px">On {{ moment(props.subscriptionEndDate).format("MMMM Do, YYYY") }} your subscription will update to a $200/month subscription.</h2>
      It may take up to <b>24 hours</b> for that to be reflected on your account.
    </template>

    <template #footer>
      <SrpButton @click="requestSwitchToMonthly" color="orange" :isDisabled="isLoading">
        <template v-if="isLoading" #icon>
          <LoadingIcon :size="22" />
        </template>
        Switch to Monthly
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { shrpaApi } from "@repos/axios";
import moment from "moment";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

const props = withDefaults(
  defineProps<{
    pageId: string;
    subscriptionEndDate: string;
  }>(),
  {
    pageId: "",
    subscriptionEndDate: "",
  }
);

const emit = defineEmits<{
  (e: "requestSent"): void;
}>();

// Is Modal Visible ===========================================================
const isModalVisible = ref<boolean>(false);

// Request switch to monthly ==================================================
const isLoading = ref<boolean>(false);
const isRequestJustSent = ref<boolean>(false);

async function requestSwitchToMonthly() {
  isLoading.value = true;

  await shrpaApi.post(`/customers/${props.pageId}/request-switch-to-monthly`);

  isLoading.value = false;
  isRequestJustSent.value = true;

  isModalVisible.value = false;

  emit("requestSent");
}

// Define expose ==============================================================
defineExpose({
  open: () => (isModalVisible.value = true),
  close: () => (isModalVisible.value = false),
});
</script>

<style scoped lang="scss"></style>
