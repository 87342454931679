<template>
  <SrpModal :is-visible="isVisible" size="medium" :isCloseButtonVisible="false" :isClosable="false" :isWithScroll="true" @update:is-visible="emit('update:isVisible', $event)">
    <template #header>
      <h1 class="global-h1" style="text-wrap: balance">
        <template v-if="step == 'confirm-interest'">Are you a creator interested in paid collaborations?</template>
        <template v-else>Add your home town</template>
      </h1>
    </template>

    <template #content>
      <template v-if="step == 'confirm-interest'">
        <LinkWithIcon class="guide-path-section__helper-link" color="teal" tag="a" :href="getGlobalLink('BecomingACreator')" target="_blank" style="margin: 10px 4px 0 0">
          <template #icon><IconEmbedded name="aperture_2" :size="23" /></template>
          <span>Learn more here</span>
        </LinkWithIcon>

        <IconEmbedded name="external-link_2" :size="17" style="position: relative; top: -3px" color="rgba(0, 0, 0, 0.5)" />
      </template>
      <!-- Get the creators location -->
      <template v-else>
        <p>We'll use your home town to help you find collabs near you.</p>
        <CreatorLocationAutocomplete v-model:paid-creator-fields="paidCreatorFields" @update:paidCreatorFields="showLocationRequiredMessage = false" />

        <div v-if="showLocationRequiredMessage" class="global-form-errors-list" style="margin-top: 5px">
          <div class="global-form-errors-list__section">
            <div class="global-form-errors-list__title">Please choose a location from the Google autocomplete</div>
          </div>
        </div>

        <div style="margin: 30px 0 30px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

        <div class="ui form">
          <div class="field">
            <h3 class="global-h3 subsection-title" style="margin-bottom: 15px">How did you hear about Shrpa?</h3>

            <!-- "Heard about" section -->
            <div class="heard-about-section" style="margin-bottom: 15px">
              <ul class="heard-about-section__column">
                <li class="heard-about-section__radio-input-wrap" v-for="(item, index) in heardAboutOptions.slice(0, 2)" :key="index">
                  <SrpCheckbox class="heard-about-section__radio-input" size="small">
                    <template #input>
                      <input type="radio" v-model="userProfileStore.getViewingUserProfile.heardAbout" :value="item.key" name="heard about" @change="showHeardAboutRequiredMessage = false" />
                    </template>
                    <template #text>{{ item.value }}</template>
                  </SrpCheckbox>
                </li>
              </ul>

              <ul class="heard-about-section__column">
                <li class="heard-about-section__radio-input-wrap" v-for="(item, index) in heardAboutOptions.slice(2)" :key="index">
                  <SrpCheckbox class="heard-about-section__radio-input">
                    <template #input>
                      <input type="radio" v-model="userProfileStore.getViewingUserProfile.heardAbout" :value="item.key" name="heard about" @change="showHeardAboutRequiredMessage = false" />
                    </template>
                    <template #text>{{ item.value }}</template>
                  </SrpCheckbox>
                </li>
              </ul>
            </div>
            <!-- / "Heard about" section -->

            <input
              v-if="userProfileStore.getViewingUserProfile.heardAbout === 'other'"
              class="global-text-input"
              type="text"
              v-model="userProfileStore.getViewingUserProfile.heardAboutOther"
              placeholder="Where did you hear about us?"
              maxlength="100"
              style="margin-top: 5px; max-width: 355px"
            />
            <div v-if="showHeardAboutRequiredMessage" class="global-form-errors-list" style="margin-top: 5px">
              <div class="global-form-errors-list__section">
                <div class="global-form-errors-list__title">Please tell us how you heard about Shrpa</div>
              </div>
            </div>
          </div>

          <div class="field" v-if="userProfileStore.getViewingUserProfile.heardAbout === HeardAboutReferByCreatorKey">
            <label class="subsection-title">What was the creator's referral code?</label>
            <input class="global-text-input" type="text" v-model="referredByCode" placeholder="referral code" />
          </div>
        </div>
      </template>
      <br />
      <br />

      <div style="display: flex; align-items: center">
        <SrpCheckbox :isWithHalo="false">
          <template #input><input type="checkbox" v-model="isAgreedToTheTerms" /></template>
          <template #text>By proceeding, I agree to the </template>
        </SrpCheckbox>
        &nbsp;
        <RouterLink class="global-a global-a--gray" lang="en" hreflang="en" :to="{ name: 'Terms' }" target="_blank">Terms and Conditions</RouterLink>
        <IconEmbedded name="external-link_2" :size="17" style="position: relative; inset: -1px 0 0 2px" color="rgba(0, 0, 0, 0.5)" />
      </div>
    </template>

    <template #footer>
      <template v-if="step == 'confirm-interest'">
        <SrpButton :isDisabled="loading || !isAgreedToTheTerms" @click="step = 'provide-location'" style="margin-right: 12px">Yep!</SrpButton>
        <SrpButton :isDisabled="loading || !isAgreedToTheTerms" @click="emit('click:notInterested')" color="gray" fill="outlined">Not currently</SrpButton>
      </template>
      <template v-else>
        <SrpButton :isDisabled="loading || !isAgreedToTheTerms" @click="validateAndSubmit" style="margin-right: 12px">Get started!</SrpButton>
        <SrpButton :isDisabled="loading || !isAgreedToTheTerms" @click="step = 'confirm-interest'" color="gray" fill="outlined">Back</SrpButton>
      </template>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { getGlobalLink } from "@logic/ExternalLinks";
import SrpModal from "./ui/SrpModal.vue";
import { onMounted, ref, watch } from "vue";
import CreatorLocationAutocomplete from "./CreatorLocationAutocomplete.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { PaidCreatorFields } from "@contracts/userProfile";
import LinkWithIcon from "./LinkWithIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import axios from "axios";
import { getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import { MetricSender } from "@/helpers/MetricSender";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";

const props = defineProps<{
  isVisible: boolean;
  loading: boolean;
}>();
const emit = defineEmits<{
  (event: "update:isVisible", value: boolean): void;
  (event: "click:notInterested"): void;
  (event: "click:interestedWithLocation", value: NewCreatorPaidInterestedModel): void;
}>();

export interface NewCreatorPaidInterestedModel {
  updatedCollabPreferences: PaidCreatorFields;
  heardAbout: string;
  heardAboutOther: string;
  referredByCode: string;
}

const userProfileStore = useUserProfileStore();

type Step = "confirm-interest" | "provide-location";

const step = ref<Step>("confirm-interest");

const paidCreatorFields = ref<PaidCreatorFields>({ ...userProfileStore.getViewingUserProfile?.collabPreferences });
const showLocationRequiredMessage = ref(false);

const showHeardAboutRequiredMessage = ref(false);
const HeardAboutReferByCreatorKey = "refer-creator";
const heardAboutOptions = [
  { key: "outreach-shrpa", value: "Email or DM" },
  { key: "ad-insta", value: "Instagram Ad" },
  { key: HeardAboutReferByCreatorKey, value: "Referred by creator" },
  { key: "refer-dest", value: "Referred by destination" },
  { key: "other", value: "Other" },
  // “Email or DM”, “Instagram Ad”, “Referred by creator”, “Referred by destination”

  // Options before May 2024
  /* { key: "outreach-shrpa", value: "Shrpa reached out to me" },
  { key: "conf", value: "At a Conference" },
  { key: "ad-insta", value: "I saw an Instagram Ad" },
  { key: "ad-fb", value: "I saw a Facebook Ad" },
  { key: "refer-creator", value: "Referred by another creator (word of mouth)" },
  { key: "refer-dest", value: "Referred by a destination (my local visitors bureau)" },
  { key: "other", value: "Other" },*/
];

const referredByCode = ref<string>("");
interface ReferredByPayload {
  referredByCode: string | null;
}
onMounted(async () => {
  try {
    const uri = `${import.meta.env.VITE_API_URL}/referrals/${userProfileStore.getViewingUserProfile.sherpaId}/referredBy`;
    const { data } = await axios.get<ReferredByPayload>(uri);
    if (data?.referredByCode) {
      referredByCode.value = data?.referredByCode;
      userProfileStore.getViewingUserProfile.heardAbout = HeardAboutReferByCreatorKey;
    }

    MetricSender.setPropertyOnSession("OnboardingState", "AskIfInterested");
  } catch (error) {
    getGlobalRemoteLogger().error(`--ALERT--GetReferredBy failed for ${userProfileStore.getViewingUserProfile?.sherpaId}: ${error?.message} ${error}`);
  }
});

function validateAndSubmit() {
  const hasLocation = paidCreatorFields.value.latitude && paidCreatorFields.value.longitude;
  const hasHeardAbout = userProfileStore.getViewingUserProfile?.heardAbout?.length > 0;
  showLocationRequiredMessage.value = !hasLocation;
  showHeardAboutRequiredMessage.value = !hasHeardAbout;

  if (hasLocation && hasHeardAbout) {
    var payload = {
      updatedCollabPreferences: paidCreatorFields.value,
      heardAbout: userProfileStore.getViewingUserProfile.heardAbout,
      heardAboutOther: userProfileStore.getViewingUserProfile.heardAboutOther,
      referredByCode: referredByCode.value,
    };
    emit("click:interestedWithLocation", payload);
    MetricSender.setPropertyOnSession("OnboardingState", "LocationSet");
  }
}

watch(
  () => props.isVisible,
  async value => {
    if (value) {
      step.value = "confirm-interest";
      paidCreatorFields.value = { ...userProfileStore.getViewingUserProfile?.collabPreferences };
    }
  }
);

// Agree to the terms =========================================================
const isAgreedToTheTerms = ref<boolean>(true);
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// "Heard about" section ======================================================
.heard-about-section {
  display: flex;

  &__column {
    gap: 10px;
    padding: 0 25px 0 0;
    margin: 0 25px 0 0;
    display: flex;
    flex-direction: column;
    list-style: none;

    &:first-child {
      border: 1px rgba(0, 0, 0, 0.1) solid;
      border-width: 0 1px 0 0;
    }
  }

  &__radio-input-wrap {
  }

  &__radio-input {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .heard-about-section {
    &__column {
      padding-right: 10px;
      margin-right: 10px;
    }
  }
}
</style>
