import { createApp, reactive, type ComponentPublicInstance } from "vue";
import App from "./App.vue";
import router from "./router";
import LogPlugin from "@plugins/log";
import externalLinkPlugin from "@plugins/externalLinks";
import { createGtm } from "@gtm-support/vue-gtm";
import { appInsights } from "@logic/AppInsights";
import { RemoteLogger, getGlobalRemoteLogger } from "@helpers/RemoteLogger";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import vClickOutside from "click-outside-vue3";
import axios from "axios";
import { createPinia } from "pinia";
import { setupAxiosAuthInterceptor, shrpaApi } from "@repos/axios";
import { setAppKey as setChurnZeroAppKey } from "@logic/ChurnZero";
import { createHead } from "@unhead/vue";
import posthogPlugin from "./plugins/posthog";
import viewportCullingDirective from "./directives/viewportCulling";

const app = createApp(App);

app.use(vClickOutside);

app.use(createHead());

app.use(VueGoogleMaps, {
  load: {
    v: 3.57, // https://developers.google.com/maps/documentation/javascript/versions
    key: globalThis.Bootstrap.Config.googlePlacesApiId,
    libraries: "geometry,places",
  },
});

app.use(router);

app.use(externalLinkPlugin);
app.use(LogPlugin);

// Google Tag Manager (replaces the old Google Analytics code in index.html)
// https://github.com/gtm-support/vue-gtm/tree/vue2-gtm
app.use(
  createGtm({
    id: globalThis.Bootstrap.Config.googleTagMangagerContainerId,
    defer: true,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
  })
);

app.directive("viewport-culling", viewportCullingDirective);

app.use(posthogPlugin);

// Import Auth0
// https://github.com/auth0/auth0-spa-js
// https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#scope
import { Auth0Plugin } from "./auth";
import { PartnerNavInfo } from "@contracts/partnerNavInfo";
import { processUnhandledError } from "@logic/GlobalErrorHandler";
// Install the authentication plugin here
app.use(Auth0Plugin, {
  domain: "shrpa.auth0.com",
  clientId: "Dgnfi1EliO2TjzpEGTMisa0Ntozu4rdI",
  audience: "https://shrpa.com/api/",
  scope: "openid email profile",
  onRedirectCallback: appState => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

app.use((Vue, options) => {
  /** * App Insights logic ***/
  appInsights.loadAppInsights();
  // Exclude PostHog from App Insights
  appInsights.addTelemetryInitializer(envelope => {
    // console.info("TEST: " + envelope.baseType + " " + envelope.baseData?.target);
    if (envelope?.baseType === "RemoteDependencyData") {
      // Check if the URL contains "posthog" or matches your PostHog API URL
      if (envelope.baseData?.target?.includes("posthog.com") === true) {
        return false;
      }
    }
  });
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
  app.provide("globalRemoteLogger", new RemoteLogger(appInsights));
});

// app.use(require("vue-moment"));

// Attach the auth token to every request if the user is authenticated
setupAxiosAuthInterceptor(axios, app);
setupAxiosAuthInterceptor(shrpaApi, app);
setChurnZeroAppKey();

// app.config.productionTip = false;

// // https://stackoverflow.com/questions/34941829/setting-focus-of-an-input-element-in-vue-js
// // Does this actually work??
// Vue.directive("focus", {
//   mounted: function(el) {
//     el.focus();
//   },
// });

app.use(createPinia());

const globalRoot = reactive(getDefaultGlobalRoot());
app.config.globalProperties.globalRoot = globalRoot;
app.provide("globalRoot", globalRoot);
function getDefaultGlobalRoot() {
  return {
    customerAdminLeftNavComponent: null as any,
    // The organization/community in context (shows an icon in the top nav)
    // Note: App.vue watches this to re-build the context menu
    orgInContext: null as PartnerNavInfo | null,

    settings: {},
    // Used to share state across component instances
    locationPreference: {
      selectedProximity: 200,
      selectedPlaceLat: null,
      selectedPlaceLon: null,
      placeName: null,
    },

    widgetDirectionsComponent: null,
    allCollabsComponent: null,

    linkifyOptions: {
      target: "_blank",
      truncate: 30,
      validate: { email: () => false },
      format: value => value.replace("http://", "").replace("https://", "").replace("www.", ""),
      className: "global-a",
    },
  };
}

// Hides warnings form the Google Autocomplete 3rd party component
// app.config.warnHandler = (msg, vm, trace) => {
//   if (trace.startsWith("at <Autocomplete>")) {
//     return;
//   }
//
//   console.warn(`[Vue warn]: ${msg}${trace}`);
// };

if (!window.location.origin.includes("localhost")) {
  // Turns off the custom error handler on localhost because it hides the error origin which is annoying
  app.config.errorHandler = (err, vm) => {
    processUnhandledError(err, "app.config.errorHandler", vm);
  };
}

window.addEventListener("unhandledrejection", function (event) {
  processUnhandledError(event.reason, "window.unhandledrejection");
});

window.addEventListener("error", function (event) {
  processUnhandledError(event.error ?? event.message, "window.error");
});

declare module "@vue/runtime-core" {
  export interface ComponentCustomProperties {
    globalRoot: ReturnType<typeof getDefaultGlobalRoot>;
  }
}

router.isReady().then(() => {
  app.mount("#app");
});
