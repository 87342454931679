<template>
  <!--Notifications-->
  <ul v-if="isCollabEligibleOrInterested">
    <li>Shrpa will notify you when new collaborations and destinations become available.</li>
    <li>You can adjust your Travel Boundary above to include more or fewer destinations.</li>
    <li>Once selected for a collaboration, we'll send you reminders and messages to help the collab go smoothly!</li>
  </ul>

  <template v-if="userProfileStore.getViewingUserProfile">
    <section v-if="isCollabEligibleOrInterested" class="medium-spacer">
      <h2>New Collaboration Opportunities</h2>
      <p>Find out when new collaborations are available within your travel boundry!</p>
      <p v-if="userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.email">
        <i class="icon envelope outline" />Email notifications are enabled. You can
        <SrpButton fill="text" @click="showCollabEmailDisableModal = true" style="text-decoration: underline">disable them</SrpButton>.
      </p>
      <NoteWithIcon v-else class="email-notifications-alert">
        <template #icon><IconEmbedded name="warning_2-5" /></template>
        <template #text>
          <div>
            <p>You will not be notified via email when new collaborations are available.</p>
            <SrpButton @click="enableCollabEmails">Send me emails</SrpButton>
          </div>
        </template>
      </NoteWithIcon>

      <p v-if="userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.sms">
        <IconEmbedded name="phone_2" color="black" :size="20" />Text notifications are enabled. You can
        <SrpButton fill="text" @click="disableCollabTexts" style="text-decoration: underline">disable them</SrpButton>.
      </p>
      <SrpButton size="small" v-else @click="displaySmsVerification">Send me texts</SrpButton>
    </section>

    <CreatorNotificationSettingsMailchimpSection />

    <SrpModal className="disable-emails-modal" v-model:is-visible="showCollabEmailDisableModal">
      <template #header>
        <h2>Disable Collab Opportunity Emails</h2>
      </template>
      <template #content>
        <NoteWithIcon class="disable-emails-modal__note">
          <template #icon><IconEmbedded name="warning_2-5" /></template>
          <template #text>You won't be notified by email when new collaboration opportunities are available.</template>
        </NoteWithIcon>
        <div class="disable-emails-modal__actions">
          <SrpButton class="disable-emails-modal__disable-action" fill="outlined" @click="disableCollabEmails">Disable Emails</SrpButton>
          <SrpButton @click="showCollabEmailDisableModal = false">Cancel</SrpButton>
        </div>
      </template>
    </SrpModal>

    <SrpModal v-model:isVisible="showCollabTextsEnableModal" size="small">
      <template #content>
        <PhoneNumberVerificationForm
          @phoneVerified="
            () => {
              showCollabTextsEnableModal = false;
              enableCollabTexts();
            }
          "
          @verificationCanceled="showCollabTextsEnableModal = false"
        />
      </template>
    </SrpModal>
  </template>
</template>

<script setup lang="ts">
import CreatorNotificationSettingsMailchimpSection from "@components/CreatorNotificationSettingsMailchimpSection.vue";
import PhoneNumberVerificationForm from "@components/PhoneNumberVerificationForm.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { useUserProfileStore } from "@stores/userProfileStore";
import { ref, computed } from "vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import { getGlobalRemoteLogger as globalRemoteLogger } from "@helpers/RemoteLogger";

// Globals ========================================================================
const userProfileStore = useUserProfileStore();

// Check if Paid Eligible or Interested ========================================================
const isCollabEligibleOrInterested = computed(() => {
  if (userProfileStore.getViewingUserProfile.collabPreferences.collabEligibleDisabled) return false;
  if (userProfileStore.getViewingUserProfile.collabPreferences.notInterestedInPaidCollabs) return false;
  return true;
});

// Notifications =============================================================
const showCollabEmailDisableModal = ref(false);

const showCollabTextsEnableModal = ref(false);

function enableCollabEmails() {
  userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.email = true;
  saveProfile();
  globalRemoteLogger().info(`Notifications: Collaboration opportunity emails enabled: ${userProfileStore.getViewingUserProfile.sherpaId}`);
}

function disableCollabEmails() {
  userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.email = false;
  showCollabEmailDisableModal.value = false;
  saveProfile();
  globalRemoteLogger().info(`Notifications: Collaboration opportunity emails disabled: ${userProfileStore.getViewingUserProfile.sherpaId}`);
}

function disableCollabTexts() {
  userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.sms = false;
  saveProfile();
  globalRemoteLogger().info(`Notifications: Collaboration opportunity texts disabled: ${userProfileStore.getViewingUserProfile.sherpaId}`);
}

function enableCollabTexts() {
  userProfileStore.getViewingUserProfile.creatorNotifications.newCollaborations.sms = true;
  saveProfile();
  globalRemoteLogger().info(`Notifications: Collaboration opportunity texts enabled: ${userProfileStore.getViewingUserProfile.sherpaId}`);
}

function displaySmsVerification() {
  showCollabTextsEnableModal.value = true;
  globalRemoteLogger().info(`Notifications: Display SmsVerification: ${userProfileStore.getViewingUserProfile.sherpaId}`);
}

async function saveProfile() {
  await userProfileStore.saveProfile(userProfileStore.getViewingUserProfile);
}
</script>

<style scoped lang="scss">
.disable-emails-modal {
  &__note {
    margin-bottom: 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    .disable-emails-modal__disable-action {
      margin-right: 1rem;
    }
  }
}

.email-notifications-alert {
  margin-bottom: 1rem;
}

.travel-boundary-link {
  text-decoration: underline;
}
</style>
