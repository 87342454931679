<template>
  <div>
    <NotificationsSettingsModalWithPaidEligibleGreeting v-model:isVisible="isNotificationsSettingsAndGreetingModalVisible" />

    <div v-if="!hasAuthenticationInitialized || (isAuthenticated && !profileHasLoaded)" class="auth-init-container">
      <h1 class="auth-init-message">Initializing...</h1>
      <div class="spinner"></div>
      <h2>Almost there!</h2>
    </div>

    <div v-else>
      <!--Not Authenticated yet-->
      <teleport to=".page-content">
        <NotAuthenticatedYetSection v-if="!isAuthenticated" @authenticate="authenticate" style="width: var(--grid-col-right-width); margin: 0 auto" />
      </teleport>
    </div>

    <!--Authenticated-->
    <div v-if="profileHasLoaded">
      <Loader v-if="!dashboardData" style="position: absolute"></Loader>
      <!-- --- Onboarding - not yet paid eligible-->
      <CreatorOnboardingV2 v-else-if="showV2CreatorOnboarding" style="margin: 0 auto" :dashboardSummary="dashboardData" />

      <!-- --- Collab Eligible-->
      <div
        v-else
        class="main-wrapper"
        :style="{ width: ['desktop-wide', 'desktop', 'laptop'].includes(screenSize) ? 'var(--grid-col-right-width)' : 'var(--grid-content-full-width)', margin: '0 auto' }"
      >
        <div class="admin-dashboard">
          <div class="main-section">
            <NoteWithIcon v-if="getViewingUserProfile?.isFormerAdmin === true" color="yellow" style="margin-top: 15px">
              <template #icon><IconEmbedded name="info-simple_4" /></template>
              <template #text>
                <div>
                  <h4 class="global-h4" style="margin-bottom: 3px">Your subscription has expired.</h4>
                  Reach out to support@shrpa.com if you have any questions.
                </div>
              </template>
            </NoteWithIcon>

            <h1 class="global-h1">
              Hi <span v-if="getViewingUserProfile">{{ getViewingUserProfile.firstName }}</span>
            </h1>
            <p>
              Thanks for being part of Shrpa!<br />
              <span v-if="!getViewingUserProfile?.collabPreferences?.notInterestedInPaidCollabs && !getViewingUserProfile?.collabPreferences?.collabEligibleDisabled">
                Connect with over {{ approximateCustomerCount }} destinations across {{ totalCollabCount }} collaborations and counting!<br />
              </span>
            </p>
          </div>
        </div>

        <Loader v-if="dashboardDataIsLoading"></Loader>
        <div v-else>
          <ProfileDisabledWarning v-if="getViewingUserProfile?.disabledDate" style="margin-top: 15px" />

          <!--Collaborations-->
          <div class="collab-list" v-if="creatorCollabActionSummaryArrayNotCompleted?.length > 0 || getViewingUserProfile.collabPreferences.isCollabEligible">
            <div style="width: 100%; margin: 0 auto 40px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

            <template v-if="creatorCollabActionSummaryArrayNotCompleted?.length > 0">
              <!-- Collab tiles section -->
              <div class="collab-tiles-section" style="margin: 0 auto 45px">
                <h2 class="global-h2 collab-tiles-section__title">My Collaborations</h2>

                <ul class="collab-tiles-section__tiles-list">
                  <CollabActionSummaryTile
                    class="collab-tiles-section__tile"
                    v-for="collabActionSummary in creatorCollabActionSummaryArrayNotCompleted"
                    :key="collabActionSummary.collabInputId"
                    :creatorCollabActionSummary="collabActionSummary"
                  />
                </ul>
              </div>
              <!-- / Collab tiles section -->

              <div style="width: var(--grid-col-right-width); margin: 0 auto 40px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
            </template>
          </div>

          <!-- V2 Dashboard Available Collabs widget -->
          <template v-if="getViewingUserProfile.collabPreferences.isCollabEligible && getViewingUserProfile.collabPreferences.collabEligibleDisabled !== true">
            <AvailableCollabsList />

            <div style="width: 100%; margin: 50px auto 50px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>
          </template>

          <div style="display: flex; flex-direction: column; align-items: center">
            <div
              :style="{
                minWidth: screenSize === 'mobile' ? 'calc(100vw - 28px)' : 'auto',
                maxWidth: '600px',
              }"
            >
              <h2 class="global-h2" style="margin-bottom: 20px">What's happening on Shrpa</h2>
              <ActivityFeed :isWithH1="false" :allowShowMore="false" />
            </div>

            <div style="width: 100%; margin: 55px auto 45px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

            <!-- Collab tiles section -->
            <div v-if="creatorCollabActionSummaryArrayCompleted?.length > 0" class="collab-tiles-section" style="margin: 0 auto 45px">
              <h2 class="global-h2 collab-tiles-section__title">Completed Collabs</h2>

              <ul class="collab-tiles-section__tiles-list">
                <CollabActionSummaryTile
                  class="collab-tiles-section__tile"
                  v-for="collabActionSummary in creatorCollabActionSummaryArrayCompleted"
                  :key="collabActionSummary.collabInputId"
                  :creatorCollabActionSummary="collabActionSummary"
                />
              </ul>
            </div>
            <!-- / Collab tiles section -->
          </div>
        </div>

        <!--Footer-->
        <div class="dashboardFooter">Thanks for being part of Shrpa! <i class="red heart icon"></i></div>
      </div>
    </div>

    <!--Modals-->
    <LoginModal :startWithNewUserFlow="true" :isVisible="isShowLoginModal" @close="isShowLoginModal = false" />

    <!--Ask if Interested in Paid Creation Modal-->
    <NewCreatorOptInModal
      v-if="getViewingUserProfile?.collabPreferences?.isCollabEligible === false"
      v-model:isVisible="showAskIfInterestedInPaidCreationModal"
      :loading="isSavingCreatorFields"
      @click:notInterested="notInterestedInPaidCreation"
      @click:interestedWithLocation="saveInterestedInPaidCreation"
    />

    <!--Missing Email Modal-->
    <SrpModal v-model:isVisible="showMissingEmailModal" :isClosable="false" :isCloseButtonVisible="false">
      <template #header>
        <h2 class="global-h2">Looks like we don't have your email</h2>
      </template>
      <template #content>
        Email is needed to receive important email notifications for collaborations and other updates!<br />
        <b>Email:</b><input type="email" v-model="missingEmailAddress" placeholder="person@domain.com" style="max-width: 500px; display: inline; margin-left: 8px; margin-top: 5px" />
        <br />
        <b v-if="missingEmailValidation" style="color: red">{{ missingEmailValidation }}</b>
      </template>
      <template #footer>
        <SrpButton :isDisabled="savingMissingEmail" class="ui primary button" @click="saveMissingEmail()">Submit</SrpButton>
      </template>
    </SrpModal>

    <CreatorReferralInvite :isVisible="showCreatorInvite" @close="showCreatorInvite = false" />
  </div>
</template>

<script lang="ts">
import axios from "axios";
import DateUtils from "@logic/DateUtils";
import { defineComponent, inject } from "vue";
import { getInstance } from "@auth/authWrapper";
import { mapActions, mapState } from "pinia";
import moment from "moment";
import { RouteHelper } from "@helpers/RouteHelper";
import { useHead } from "@unhead/vue";
import { MetricSender } from "@/helpers/MetricSender";

// Types
import { CollabInput, CreatorCollab } from "@contracts/collab";
import { CreatorDashboardSummary } from "@contracts/creatorPublicProfileFields";
import { NewCreatorPaidInterestedModel } from "@components/NewCreatorOptInModal.vue";
import { ScreenSize } from "@contracts/screenSize";

// Stores
import { useUserProfileStore } from "@stores/userProfileStore";

// Components
import ActivityFeed from "@components/ActivityFeed/index.vue";
import AvailableCollabsList from "./AvailableCollabsList.vue";
import CollabActionSummaryTile from "@components/collabSnippets/CollabActionSummaryTile.vue";
import CopyText from "@components/CopyText.vue";
import CreatorOnboardingV2 from "@views/Creator/CreatorOnboardingV2/index.vue";
import CreatorReferralInvite from "@components/CreatorReferralInvite.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import Loader from "@components/Loader/Loader.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import LoginModal from "@components/Modals/LoginModal.vue";
import NewCreatorOptInModal from "@components/NewCreatorOptInModal.vue";
import NotAuthenticatedYetSection from "@views/Creator/CreatorDashboard/NotAuthenticatedYetSection.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import PaidCreatorFields from "@components/PaidCreatorFields.vue";
import PaidCreatorFieldsEnteredMessage from "@components/PaidCreatorFieldsEnteredMessage.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import { CreatorCollabActionSummary, CustomerCollabActionSummary } from "@contracts/collabActionSummaries";
import NotificationsSettingsModalWithPaidEligibleGreeting from "@components/NotificationsSettingsModalWithPaidEligibleGreeting.vue";
import ProfileDisabledWarning from "@components/Sherpa/ProfileDisabledWarning.vue";

/* NOTES
   - This is the landing page for unauthenticated creators that guides them how to log in (like the Community Dashboard)
   - This has an optional parameter to specify the user's Id, otherwise it uses the profile in context
*/

export default defineComponent({
  name: "CreatorDashboard",

  // prettier-ignore
  components: { NotificationsSettingsModalWithPaidEligibleGreeting, ActivityFeed, AvailableCollabsList, CollabActionSummaryTile, CopyText, CreatorOnboardingV2, CreatorReferralInvite, IconEmbedded, Loader, LinkWithIcon, LoginModal, NewCreatorOptInModal, NotAuthenticatedYetSection, NoteWithIcon, PaidCreatorFields, PaidCreatorFieldsEnteredMessage, SrpButton, SrpModal, ProfileDisabledWarning },

  data() {
    return {
      globalLog: inject("globalLog") as any,
      globalRemoteLogger: inject("globalRemoteLogger") as any,
      globalGetLink: inject("globalGetLink") as any,
      screenSize: inject("screenSize") as ScreenSize,

      title: "Creator Dashboard",
      // @ts-ignore
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri as string,
      hasAuthenticationInitialized: false,
      isAuthenticated: false,
      isShowLoginModal: false,
      profileHasLoaded: false,

      userProfileStore: useUserProfileStore(),

      isCreatorCollabActionSummaryArrayLoading: false,
      creatorCollabActionSummaryArray: [] as Array<CreatorCollabActionSummary>,

      dashboardDataIsLoading: true,

      dashboardData: null as CreatorDashboardSummary | null,
      currentUserStats: null,
      topLevelStatsToShowCreators: null,

      // Paid Creator Fields
      showAskIfInterestedInPaidCreationModal: false,
      isSavingCreatorFields: false,

      // Missing email
      showMissingEmailModal: false,
      missingEmailAddress: null,
      missingEmailValidation: null,
      savingMissingEmail: false,

      // Referral
      showCreatorInvite: false,
      isNotificationsSettingsAndGreetingModalVisible: false,
    };
  },

  computed: {
    ...mapState(useUserProfileStore, ["getViewingUserProfile", "getActingUserProfile", "isSuperOrSalesUser"]),
    creatorCollabActionSummaryArrayNotCompleted(): Array<CreatorCollabActionSummary> {
      return this.creatorCollabActionSummaryArray.filter(s => s.completedDate === null);
    },
    creatorCollabActionSummaryArrayCompleted(): Array<CreatorCollabActionSummary> {
      return this.creatorCollabActionSummaryArray.filter(s => s.completedDate !== null);
    },
    showV2CreatorOnboarding(): boolean {
      if (
        this.getViewingUserProfile?.collabPreferences?.isCollabEligible === true ||
        this.getViewingUserProfile?.collabPreferences?.notInterestedInPaidCollabs === true ||
        this.getViewingUserProfile?.collabPreferences?.collabEligibleDisabled === true
      )
        return false;
      return this.getViewingUserProfile?.collabPreferences?.becomingCollabEligible === true || this.dashboardData?.promptToBePaidCreator === true;
    },

    approximateCustomerCount(): number {
      if (this.topLevelStatsToShowCreators) {
        return this.topLevelStatsToShowCreators.approximateDmoCustomerCount;
      }
      return 200;
    },
    totalCollabCount(): number {
      if (this.topLevelStatsToShowCreators) {
        return this.topLevelStatsToShowCreators.totalCollabsSoldCount;
      }
      return 1000;
    },
  },

  watch: {
    // Little hacky but works
    "userProfileStore.loggedInUser": function (profile) {
      this.globalLog.info("Dashboard: loggedInUser watch fired");
      if (profile) {
        this.profileLoaded();
      }
    },
    $route(to, from) {
      console.info("CreatorDashboard: $route watch fired");
      // Note: No left nav currently so this isn't needed unless we add that.
      this.checkForRedirect();
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    // NOTE: The below might be overkill for this since we probably don't need to support the unauthenticated scenarios.  Also might not need to wait for profileLoading (just auth)
    /* Note: This Init sequence is fairly complicated since we want to handle several scenarios:
     * 1. Auth is still loading (so we don't know much yet)
     * 2. User is not logged in
     * 3. Profile is loaded (user must be logged in)
     *
     * This logic is similar to the community dashboard
     */
    const authService = getInstance();
    if (authService.loading === false) {
      this.authLoaded();
    }
    authService.$watch("loading", loading => {
      if (loading === false) {
        this.authLoaded();
      }
    });
    // This code is used if they got here via a link on the site.
    // On a fresh load, the watch will catch them once their profiles loads (after auth)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.getActingUserProfile) {
      this.globalLog.info("Dashboard: Profile already loaded");
      await this.profileLoaded();
    }
    await this.getTopLevelStatsToShowCreators();

    if (this.$route.query?.justBecamePaidEligible === "true") {
      this.isNotificationsSettingsAndGreetingModalVisible = true;
      MetricSender.setPropertyOnSession("OnboardingState", "Complete");
    }
  },

  methods: {
    moment,
    ...mapActions(useUserProfileStore, ["impersonateProfile", "saveProfile"]),
    async getCollabsSummaryWithActions() {
      this.isCreatorCollabActionSummaryArrayLoading = true;

      // DON'T USE params.creatorId, that's optional and only for supers
      const uri = `${import.meta.env.VITE_API_URL}/collab-summary/creators/${this.getViewingUserProfile.sherpaId}/summary-with-actions`;
      const { data } = await axios.get(uri);
      this.creatorCollabActionSummaryArray = data;

      this.isCreatorCollabActionSummaryArrayLoading = false;
    },
    authLoaded() {
      this.hasAuthenticationInitialized = true;
      const authService = getInstance();
      this.isAuthenticated = authService.isAuthenticated;
      this.globalLog.info(`AuthLoaded: IsAuthenticated=${this.isAuthenticated}`);
    },
    async profileLoaded() {
      this.globalLog.info(`CreatorDashboard ProfileLoaded`);
      // Do this early since downstream code relies on the field
      this.profileHasLoaded = true;
      var creatorIdOverride = this.$route.params.creatorId as string;
      this.globalLog.info(`CreatorDashboard creatorIdParam=${creatorIdOverride}`);
      // Default the profile details
      await this.setViewingProfile(creatorIdOverride);

      // Check for redirects to sub-pages
      this.checkForRedirect();

      this.checkForMissingEmail();
      this.getCollabsSummaryWithActions();
      this.loadDashboardData();
      this.loadCreatorStats();
    },
    async setViewingProfile(creatorIdOverride: string | null) {
      this.globalLog.info(`CreatorDashboard setting view Profile`);
      // Check if we're an admin loading someone else's profile
      if (creatorIdOverride) {
        await this.impersonateProfile(creatorIdOverride);
      }
    },
    authenticate() {
      this.isShowLoginModal = true;
    },
    async getTopLevelStatsToShowCreators() {
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/sherpas/stats/overall`);
      this.topLevelStatsToShowCreators = response.data;
    },
    async loadCreatorStats() {
      // This endpoint handles non-creators also
      var currentCreatorId = this.getViewingUserProfile.sherpaId;
      this.globalLog.info(`CreatorDashboard: Loading Creator Stats`);
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/sherpas/${currentCreatorId}/stats/overview`);
      this.currentUserStats = response.data;
    },

    // ---Dashboard---
    async loadDashboardData() {
      this.globalLog.info("Loading Dashboard Data");
      var currentCreatorId = this.getViewingUserProfile.sherpaId;
      const response = await axios.get(`${import.meta.env.VITE_API_URL}/sherpas/${currentCreatorId}/dashboard`);
      this.dashboardData = response.data;

      // Paid Creator Flow
      this.checkPaidCreatorFlow(this.dashboardData);

      this.dashboardDataIsLoading = false;
    },

    checkForRedirect() {
      // This provides quick redirects w/out having the id in the uri, which makes it much easier to template emails
      // NOTE: This fires after the profile is loaded but can be before other data is fully loaded.
      const redirect = RouteHelper.getQueryStringParam("r");
      if (redirect) {
        if (redirect.toUpperCase() === "AVAILABLECOLLABS" || redirect.toUpperCase() === "COLLAB" || redirect.toUpperCase() === "COLLABCREDITS") {
          // We also have the ability to pass a specific collab
          const collabId = RouteHelper.getQueryStringParam("collab");
          const customerId = RouteHelper.getQueryStringParam("dest");
          if (customerId && collabId) {
            this.navigateToSpecificCollab(customerId, collabId, true);
          } else {
            // Update: Just stay here since Available Collabs are on the dashboard
          }
        } else if (redirect.toUpperCase() === "REFERRAL" || redirect.toUpperCase() === "REFERRALS") {
          this.navigateToReferrals(true);
        }
      }
    },

    async checkPaidCreatorFlow(dashboardData: CreatorDashboardSummary) {
      if (
        dashboardData.promptToBePaidCreator &&
        this.getViewingUserProfile.collabPreferences.notInterestedInPaidCollabs !== true &&
        this.getViewingUserProfile.collabPreferences.becomingCollabEligible !== true &&
        // Don't ask superusers if they're viewing their own profile
        this.getViewingUserProfile.isSuperuser !== true &&
        this.getViewingUserProfile.isSalesUser !== true
      ) {
        // UPDATE: Changed this to be a modal that we have the user answer.
        this.showAskIfInterestedInPaidCreationModal = true;
      }
    },
    navigateToReferrals(noHistory = false) {
      const destination = { name: "CreatorReferrals", params: { creatorId: this.getViewingUserProfile.sherpaId } };
      if (noHistory) this.$router.replace(destination);
      else this.$router.push(destination);
    },
    navigateToSpecificCollab(customerId: string, collabInputId: string, noHistory = false) {
      const destination = { name: "CollabOpportunity", params: { communityId: customerId, collabInputId: collabInputId, creatorId: this.getViewingUserProfile.sherpaId } };
      if (noHistory) this.$router.replace(destination);
      else this.$router.push(destination);
    },

    // --Paid Collab Interest responses--
    async notInterestedInPaidCreation(): Promise<void> {
      this.globalRemoteLogger.info(`Dashboard: NOT interested in paid collabs: ${this.getViewingUserProfile?.authNameId}`);
      this.isSavingCreatorFields = true;
      this.getViewingUserProfile.collabPreferences.notInterestedInPaidCollabs = true;
      // Save the profile
      this.showAskIfInterestedInPaidCreationModal = false;
      await this.saveProfile(this.getViewingUserProfile);
      this.isSavingCreatorFields = false;
      this.showAskIfInterestedInPaidCreationModal = false;
    },
    async saveInterestedInPaidCreation(paidInterestedFields: NewCreatorPaidInterestedModel) {
      this.globalRemoteLogger.info(`Dashboard: IS interested in paid collabs: ${this.getViewingUserProfile?.sherpaId}`, false, false, {
        heardAbout: paidInterestedFields.heardAbout,
        referredByCode: paidInterestedFields.referredByCode,
      });
      this.isSavingCreatorFields = true;
      const profile = this.getViewingUserProfile;
      // use updated collab preferences which includes location data, and force becomingCollabEligible to true
      profile.collabPreferences = { ...paidInterestedFields.updatedCollabPreferences, becomingCollabEligible: true };
      profile.heardAbout = paidInterestedFields.heardAbout;
      profile.heardAboutOther = paidInterestedFields.heardAboutOther;
      await this.saveProfile(profile, paidInterestedFields.referredByCode);
      this.isSavingCreatorFields = false;
      this.showAskIfInterestedInPaidCreationModal = false;

      MetricSender.setPropertyOnSession("Creator-Onboarding", true);
    },

    // ---Collab Methods---
    declinedCollab(collab: CreatorCollab) {
      if (collab.creatorInput.declinedDateTime) return true;
      return false;
    },
    differentCreatorSelected(collab: CreatorCollab) {
      var usersSherpaId = this.getViewingUserProfile.sherpaId;
      return collab.collabInput.collaboration.creatorId && collab.collabInput.collaboration.creatorId !== usersSherpaId;
    },
    formatPreferredTimingDates(collaborationInput: CollabInput) {
      return DateUtils.formatDateRangeTwoDates(collaborationInput.preferredDateRangeStart, collaborationInput.preferredDateRangeEnd);
    },

    checkForMissingEmail() {
      // Checks if we should prompt the user with the missing email modal/flow
      let parameter = RouteHelper.getQueryStringParam("missing-email-test");
      if (parameter?.toLowerCase() === "true") {
        // Just for testing
        this.showMissingEmailModal = true;
      } else {
        // Normal flow, ensure we only show this if they don't have a valid email
        let currentEmail = this.getViewingUserProfile.email;
        if (!currentEmail || currentEmail.trim().length === 0 || currentEmail === "undefined") this.showMissingEmailModal = true;
      }
    },
    async saveMissingEmail() {
      if (!this.missingEmailAddress || this.missingEmailAddress?.trim().length < 5 || !this.missingEmailAddress.includes("@")) {
        this.missingEmailValidation = "Please enter a valid email address";
        return;
      }
      this.missingEmailValidation = null;
      this.savingMissingEmail = true;
      // Note: This doesn't update the profile eTag so may cause a false positive there, should improve this at some point (it's low volume though)
      var uri = `${import.meta.env.VITE_API_URL}/profile/${this.getViewingUserProfile.authNameId}/email?newEmail=${encodeURIComponent(this.missingEmailAddress)}`;
      var updated = await axios.put(uri);
      this.globalLog.info(`saveMissingEmail ${this.missingEmailAddress}: wasUpdated=${updated.data}`);

      this.getViewingUserProfile.email = this.missingEmailAddress;
      this.showMissingEmailModal = false;
      this.savingMissingEmail = false;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";

// Collab tiles section =======================================================
.collab-tiles-section {
  width: $desktop-wide-right-col-width;

  &__title {
    margin-bottom: 15px;
  }

  &__tiles-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: $desktop-wide-grid-gap-width;
    list-style: none;
  }

  &__tile {
    width: calc(50% - $desktop-wide-grid-gap-width / 2 - 1px);
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .collab-tiles-section {
    width: $desktop-right-col-width;

    &__tiles-list {
      gap: $desktop-grid-gap-width;
    }

    &__tile {
      width: calc(50% - $desktop-grid-gap-width / 2 - 1px);
    }
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .collab-tiles-section {
    width: $laptop-right-col-width;

    &__tiles-list {
      gap: $laptop-grid-gap-width;
    }

    &__tile {
      width: calc(50% - $laptop-grid-gap-width / 2 - 1px);
    }
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .collab-tiles-section {
    width: $tablet-large-content-width;
    min-width: $tablet-large-content-width;

    &__tiles-list {
      gap: $tablet-large-grid-gap-width;
    }

    &__tile {
      width: calc(50% - $tablet-large-grid-gap-width / 2 - 1px);
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .collab-tiles-section {
    width: $tablet-content-width;

    &__tiles-list {
      gap: $tablet-grid-gap-width;
    }

    &__tile {
      width: 100%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .collab-tiles-section {
    width: $mobile-content-width;

    &__tiles-list {
      gap: $mobile-grid-gap-width;
    }

    &__tile {
      width: 100%;
    }
  }
}

.create-adventure-button {
  font-weight: bold;
  color: $brand-color;
  cursor: pointer;
}

.dashboardFooter {
  text-align: center;
  margin-top: 100px;
  width: 100%;
}

.collab-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3em;

  .collab-section {
    width: 100%;
  }

  .collab-subheading {
    margin-bottom: 5px;
  }

  .collab-section-description {
    margin-bottom: 10px;
  }

  .totals {
    //border-bottom: 1px solid #ccc;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    width: 100%;

    &:not(:first-of-type) {
      //border-top: 1px solid #ccc;
      margin-top: 1rem;
      padding-top: 1rem;
    }

    label {
      font-size: 1.5rem;
      margin-top: 0.5rem !important;
      padding: 1rem;
    }
  }

  .collab {
    box-shadow: 0 0 5px 0 #ccc;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
    padding: 1rem;
    position: relative;
    width: 100%;

    @media screen and (min-width: 600px) {
      width: 47%;
    }

    > div {
      &.community-name {
        max-width: 250px;
        font-size: 1.4rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }

    .collab-name {
      max-width: 250px;
    }

    .collab-timing {
      max-width: 220px;
    }

    .partner-img {
      position: absolute;
      right: 1rem;
      top: 1rem;
      max-height: 90px;
      max-width: 120px;
    }

    .another,
    .accepted,
    .decline {
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
    }

    .another {
      background-color: rgba(236, 86, 59, 0.2);
    }

    .decline {
      background-color: rgba(204, 204, 204, 0.4);
    }

    .accepted {
      background: rgba(179, 222, 222, 0.5);
    }

    .button {
      margin-top: auto;
      max-width: 250px;
      width: 100%;
    }
  }
}

// Section while auth is initializing
.auth-init-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-left-color: $brand-color;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.auth-init-message {
  margin-top: 20px;
}
</style>
