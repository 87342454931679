<template>
  <div class="status-content">
    <div v-if="showStatus" class="status-row">
      <div class="global-h6">Status:</div>
      <p
        class="status-badge"
        :class="{
          'not-started': collab.status === 'Not Started',
          complete: collab.status === 'Complete',
        }"
      >
        {{ collab.status }}
      </p>
    </div>
    <div v-if="collab.creatorNotes && showNotes" class="notes">
      <div class="global-h5">{{ collab.status === "Complete" ? "Deliverables" : "Creator Notes" }}</div>
      <p class="notes-text">{{ collab.creatorNotes }}</p>
      <a v-if="collab.status === 'Complete' && collab.deliverableLink" :href="collab.deliverableLink" target="_blank" rel="noopener noreferrer" class="drive-link">
        {{ collab.deliverableLink }}
        <IconEmbedded name="external-link_2-5" color="grey" :size="18" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import IconEmbedded from "@/components/ui/IconEmbedded.vue";

interface Collab {
  status: string;
  creatorNotes: string;
  deliverableLink?: string;
  title: string;
}

interface Props {
  collab: Collab;
  showNotes?: boolean;
  showStatus?: boolean;
}

const { collab, showNotes, showStatus } = withDefaults(defineProps<Props>(), {
  showNotes: true,
  showStatus: true,
});
</script>

<style scoped lang="scss">
.status-content {
  .title-row {
    margin-bottom: 16px;

    .global-h5 {
      margin: 0 0 4px 0;
    }
  }

  .status-row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;

    .global-h5 {
      margin: 0;
    }

    .status-badge {
      margin: 0;
    }
  }
}

.status-badge {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
  background: #e8f5e9;
  color: #2e7d32;

  &.not-started {
    background: #e3f2fd;
    color: #1976d2;
  }

  &.complete {
    background: #3d6a4dcc;
    color: white;
  }
}

.drive-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #2b6b78;
  text-decoration: none;
  margin-top: 8px;
  font-size: 0.9rem;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
  }
}
</style>
