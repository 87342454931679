<template>
  <div class="saved-creator-snippet">
    <CloseDeleteButton
      v-if="props.isWithDeleteButton"
      :class="{
        'saved-creator-snippet__delete-button': true,
        'saved-creator-snippet__delete-button--disabled': isDeleting,
      }"
      @click="
        async () => {
          await deleteSavedCreator();
          emit('delete', props.creator.shrpaCreatorId);
        }
      "
    />

    <!-- Name link -->
    <RouterLink class="global-h4 name-link saved-creator-snippet__name-link" :to="{ name: 'CreatorProfilePublic', params: { creatorId: props.creator?.shrpaCreatorId } }" target="_blank">
      <span class="name-link__text">{{ props.creator.name }}</span
      >&nbsp;<IconEmbedded class="name-link__external-link-icon" name="external-link_2-5" :size="16" color="rgba(0,0,0,0.3)" />
    </RouterLink>
    <!-- / Name link -->

    <RouterLink class="saved-creator-snippet__avatar-wrap" :to="{ name: 'CreatorProfilePublic', params: { creatorId: props.creator?.shrpaCreatorId } }">
      <CreatorAvatar class="saved-creator-snippet__avatar" :imageUri="`${contentBaseUri}/cms/images/profiles/${props.creator.profilePhoto}`" />
    </RouterLink>

    <div class="saved-creator-snippet__social-icons-wrap">
      <SocialMediaLinksBar
        v-if="!SocialMediaFieldsHelper.isAllEmpty(creator)"
        class="saved-creator-snippet__social-icons"
        :socialFields="creator"
        :isFullSizeIcons="false"
        :iconsSize="23"
        :iconsGap="3"
      />
    </div>

    <div class="saved-creator-snippet__divider"></div>

    <SrpButton
      v-if="props.saveCallback"
      class="saved-creator-snippet__cta-button"
      size="small"
      fill="outlined"
      @click="
        async () => {
          await save();
          emit('clickOnCtaButton', props.creator.shrpaCreatorId);
        }
      "
      :isDisabled="props.isCtaButtonDisabled || isSaving"
    >
      <template v-if="isSaving" #icon>
        <LoadingIcon :size="22" />
      </template>
      {{ props.ctaButtonText }}
    </SrpButton>

    <SrpButton
      v-else
      class="saved-creator-snippet__cta-button"
      size="small"
      fill="outlined"
      @click="
        async () => {
          emit('clickOnCtaButton', props.creator.shrpaCreatorId);
        }
      "
    >
      {{ props.ctaButtonText }}
    </SrpButton>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import SocialMediaFieldsHelper from "@helpers/SocialMediaFieldsHelper";

// Components
import CreatorAvatar from "@components/CreatorAvatar.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import SocialMediaLinksBar from "@components/SocialMediaLinksBar.vue";

// Types
import { CustomerSavedCreatorSummary } from "@contracts/customerSavedCreators";
import SrpButton from "@components/ui/SrpButton.vue";
import CloseDeleteButton from "@components/ui/CloseDeleteButton.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

// Global variables & injections
const contentBaseUri = globalThis.Bootstrap.Config.contentCdnBaseUri;

const props = withDefaults(
  defineProps<{
    creator: CustomerSavedCreatorSummary | null;
    isWithDeleteButton?: boolean;
    ctaButtonText?: string;
    isCtaButtonDisabled?: boolean;
    saveCallback?: (creatorId: string) => Promise<void> | null;
    deleteCallback?: (creatorId: string) => Promise<void> | null;
  }>(),
  {
    creator: null,
    isWithDeleteButton: false,
    ctaButtonText: "Get Started",
    isCtaButtonDisabled: false,
    saveCallback: null,
    deleteCallback: null,
  }
);

const emit = defineEmits<{
  (e: "save"): void;
  (e: "clickOnCtaButton", value: string): void;
  (e: "delete", value: string): void;
}>();

// Save =======================================================================
const isSaving = ref<boolean>(false);

async function save() {
  isSaving.value = true;
  await props.saveCallback?.(props.creator.shrpaCreatorId);

  setTimeout(() => (isSaving.value = false), 2000); // keep the CTA button disabled while the parent component reloads data

  emit("save");
}

// Delete =====================================================================
const isDeleting = ref<boolean>(false);

async function deleteSavedCreator() {
  isDeleting.value = true;
  await props.deleteCallback?.(props.creator.shrpaCreatorId);

  emit("delete", props.creator.shrpaCreatorId);
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Name link ==================================================================
.name-link {
  text-align: center;
  cursor: pointer;
  user-select: none;

  &__text {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.2);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
    text-decoration-style: solid;
    white-space: wrap;
    word-break: break-word;
  }

  &:hover &__text {
    text-decoration: none;
  }

  &__external-link-icon {
    position: relative;
    top: 2px;
  }
}

// Saved Creator snippet ======================================================
.saved-creator-snippet {
  padding: 19px 18px 23px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: rgba(240, 240, 240, 1);

  &__delete-button {
    position: absolute;
    inset: -10px -10px auto auto;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__name-link {
    margin-bottom: 17px;
  }

  &__avatar-wrap {
    width: 124px;
    max-width: 70%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.07s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__avatar {
    aspect-ratio: 1/1;
    width: 100%;
    max-width: 100%;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  &__social-icons-wrap {
    flex-grow: 1;
  }

  &__social-icons {
    margin-bottom: 14px;
  }

  &__divider {
    width: 90%;
    max-width: 165px;
    margin-bottom: 18px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__cta-button {
    &::before {
      background: rgba(255, 255, 255, 1);
    }
    &:hover::before {
      background: rgba(255, 255, 255, 1);
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .saved-creator-snippet {
    &__avatar-wrap {
      max-width: 75%;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .saved-creator-snippet {
    &__avatar-wrap {
      max-width: 80%;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .saved-creator-snippet {
    &__avatar-wrap {
      max-width: 80%;
    }
  }
}
</style>
