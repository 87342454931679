export default {
  beforeMount(el: HTMLElement, binding: { value: string }) {
    const observer = new IntersectionObserver(
      ([entry]) => {
        el.dataset.isWithinViewport = String(entry.isIntersecting);

        if (binding.value && typeof binding.value === "function") {
          binding.value(entry.isIntersecting);
        }
      },
      { rootMargin: el.dataset.viewportCullingRootMargin || "100px", threshold: 0.1 }
    );
    // observer.observe(el);
    // observer.disconnect();

    setTimeout(() => observer.observe(el), 0);

    el.__intersectionObserver__ = observer;
  },
  beforeUnmount(el: HTMLElement) {
    el.__intersectionObserver__.disconnect();
    delete el.__intersectionObserver__;
  },
};
