<template>
  <div class="planned-visit-snippet">
    <h3 class="global-h3 planned-visit-snippet__title">{{ plannedCollab.name }}</h3>

    <div class="planned-visit-snippet__date-n-visit-focus">
      <!-- Date snippet -->
      <div v-if="dateRangeText" class="date-snippet planned-visit-snippet__date-snippet">
        <IconEmbedded class="date-snippet__icon" name="calendar_2" />
        <div class="date-snippet__text">{{ dateRangeText }}</div>
      </div>
      <!-- / Date snippet -->

      <!-- Visit focus snippet -->
      <div v-if="plannedCollab.themeType" class="visit-focus-snippet planned-visit-snippet__visit-focus-snippet">
        <img class="visit-focus-snippet__icon" :src="getThemeIcon(plannedCollab.themeType)" alt="family" />
        <div class="visit-focus-snippet__text">
          {{ getThemeTypeName(plannedCollab.themeType) }}
        </div>
      </div>
      <!-- / Visit focus snippet -->
    </div>

    <div class="planned-visit-snippet__divider"></div>

    <div class="planned-visit-snippet__details-text">{{ plannedCollab.notes }}</div>

    <div v-if="!props.isSimplified && (plannedCollab.requiredLocations || plannedCollab.suggestedLocations)" class="planned-visit-snippet__required-and-suggested-locations">
      <!-- Locations snippet -->
      <div v-if="plannedCollab.requiredLocations" class="locations-snippet planned-visit-snippet__required-locations">
        <h6 class="global-h6 locations-snippet__title">Required Locations</h6>
        <ul class="locations-snippet__locations-list">
          <li class="locations-snippet__location" v-for="location in plannedCollab.requiredLocations?.trim().split('\n')" :key="location">
            {{ location }}
          </li>
        </ul>
      </div>
      <!-- / Locations snippet -->

      <!-- Locations snippet -->
      <div v-if="!props.isSimplified && plannedCollab.suggestedLocations" class="locations-snippet planned-visit-snippet__suggested-locations">
        <h6 class="global-h6 locations-snippet__title">Suggested Locations</h6>
        <ul class="locations-snippet__locations-list">
          <li class="locations-snippet__location" v-for="location in plannedCollab.suggestedLocations?.trim().split('\n')" :key="location">
            {{ location }}
          </li>
        </ul>
      </div>
      <!-- / Locations snippet -->
    </div>

    <div class="planned-visit-snippet__buttons-panel">
      <SrpButton class="planned-visit-snippet__button" size="small" @click="emit('click:getStarted')">Get Started</SrpButton>
      <SrpButton v-if="props.isEditButtonVisible" class="planned-visit-snippet__button" size="small" color="gray" fill="outlined" style="padding-left: 17px" @click="emit('click:edit')">
        <template #icon><IconEmbedded name="edit-pencil_2" style="margin-right: -5px" :size="17" color="rgba(0, 0, 0, 0.3)" /></template>
        Edit
      </SrpButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { getThemeTypeName, getThemeIcon } from "@helpers/ThemeTypeHelper";
import moment from "moment";

// Components
import SrpButton from "@components/ui/SrpButton.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { PlannedCollab } from "@contracts/plannedCollabs";

const props = withDefaults(
  defineProps<{
    plannedCollab: PlannedCollab | null;
    isEditButtonVisible?: boolean;
    isSimplified?: boolean; // cuts the location blocks
  }>(),
  {
    plannedCollab: null,
    isEditButtonVisible: true,
    isSimplified: false,
  }
);

const emit = defineEmits<{
  (e: "click:edit"): void;
  (e: "click:getStarted"): void;
}>();

// Format the date range ======================================================
const dateRangeText = computed(() => {
  if (props.plannedCollab.preferredDateRangeStart === null) {
    return "";
  }
  if (props.plannedCollab.preferredDateRangeEnd) {
    const startDate = moment(props.plannedCollab.preferredDateRangeStart).format("MMM D");
    const endDate = moment(props.plannedCollab.preferredDateRangeEnd).format("MMM D");
    return `${startDate} - ${endDate}`;
  }
  return moment(props.plannedCollab.preferredDateRangeStart).format("MMM, YYYY");
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Visit focus snippet ========================================================
.visit-focus-snippet {
  display: flex;
  align-items: center;

  &__icon {
    width: 30px;
    height: 30px;
    margin-right: 2px;
  }

  &__text {
  }
}

// Date snippet ===============================================================
.date-snippet {
  display: flex;
  align-items: center;

  &__icon {
    width: 22px;
    margin-right: 5px;

    :deep(svg) {
      fill: rgba(0, 0, 0, 0.5);
    }
  }

  &__text {
    font-weight: bold;
  }
}

// Locations snippet ==========================================================
.locations-snippet {
  &__title {
    margin-bottom: 5px;
    font-weight: 700;
    white-space: nowrap;
  }

  &__locations-list {
    gap: 3px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__location {
    padding-left: 10px;
    position: relative;

    &::before {
      content: "-";
      position: absolute;
      inset: -1px auto auto 0;
      transform: scaleX(1.3);
      color: rgba(0, 0, 0, 0.3);
      font-weight: bold;
    }
  }
}

// Planned visit snippet ======================================================
.planned-visit-snippet {
  padding: 16px 22px 21px;
  box-sizing: border-box;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);

  &__title {
    margin-bottom: 11px;
  }

  &__date-n-visit-focus {
    margin-bottom: 9px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__date-snippet {
    margin-right: 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }
  }

  &__visit-focus-snippet {
    display: flex;
    align-items: center;
  }

  &__divider {
    width: 100%;
    margin-bottom: 14px;
    height: 0;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__details-text {
    margin-bottom: 15px;
    white-space: pre-wrap;
  }

  &__required-and-suggested-locations {
    gap: 15px;
    margin-bottom: 18px;
    display: flex;
  }

  &__required-locations {
    flex-grow: 1;
  }

  &__suggested-locations {
    flex-grow: 1;
  }

  &__buttons-panel {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .planned-visit-snippet {
    padding: 13px 17px 16px;

    &__title {
      margin-bottom: 8px;
    }

    &__date-n-visit-focus {
      margin-bottom: 6px;
    }

    &__divider {
      margin-bottom: 10px;
    }
  }
}
</style>
