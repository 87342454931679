import { shrpaApi } from "./axios";

// Types
import { PlannedCollab } from "@contracts/plannedCollabs";

export default {
  async fetchPlannedCollabs(customerId: string): Promise<PlannedCollab[]> {
    const { data } = await shrpaApi.get<PlannedCollab[]>(`/planned-collabs/${customerId}`);
    return data;
  },
  async updatePlannedCollabs(customerId: string, plannedCollabs: PlannedCollab[]): Promise<PlannedCollab[]> {
    const { data } = await shrpaApi.put<PlannedCollab[]>(`/planned-collabs/${customerId}`, plannedCollabs);
    return data;
  },
};
