<template>
  <div class="step-intro__section">
    <h2 class="global-h2 step-intro__section-title step-intro__section-title--smaller-bottom-margin">Clone a previous Visit</h2>
    <div class="step-intro__section-subtitle">Copy a previous visit's guidance so you can submit with a new date and creator.</div>
    <div class="step-intro__section-content" style="margin-bottom: 20px">
      <ul class="step-intro__collabs-list">
        <li class="step-intro__collab-snippet-wrap" v-for="collab in props.collabSummaries.slice(0, howManyCollabsToShow)" :key="collab.collabInputId">
          <CollabSnippetV2 class="step-intro__collab-snippet" :collab="collab" @click="emit('click:cloneCollab', collab.collabInputId)" />
        </li>
      </ul>
    </div>

    <LinkWithIcon v-if="props.collabSummaries?.length > 3" class="additional-links__link" @click="toggleCollabsList" is-dotted-underline iconInset="1px auto auto 2px" style="margin-left: -5px">
      <template #icon>
        <IconEmbedded v-if="howManyCollabsToShow === 3" name="caret-bottom_4" :size="13" />
        <IconEmbedded v-else name="caret-top_4" :size="13" />
      </template>
      <span>Show {{ howManyCollabsToShow === 3 ? "more" : "less" }}</span>
    </LinkWithIcon>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";

// Components
import LinkWithIcon from "@components/LinkWithIcon.vue";
import CollabSnippetV2 from "@components/collabSnippets/CollabSnippetV2.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { CreatorCollabSummary } from "@contracts/collab";

// Stores
import { useCollabSummariesStore } from "@stores/collabSummaries";
const collabSummariesStore = useCollabSummariesStore();

// Router
const route = useRoute();

const props = withDefaults(
  defineProps<{
    collabSummaries: Array<CreatorCollabSummary>;
  }>(),
  {
    collabSummaries: () => [],
  }
);

const emit = defineEmits<{
  (e: "click:cloneCollab", collabInputId: string): void;
}>();

// Toggle collabs list ========================================================
const howManyCollabsToShow = ref<number>(3);

function toggleCollabsList(): void {
  howManyCollabsToShow.value = howManyCollabsToShow.value === 3 ? 100 : 3;
}
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Step intro =================================================================
.step-intro {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 25px;
  }

  &__section {
    padding-bottom: 15px;
    margin-bottom: 25px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__section-title {
    margin-bottom: 10px;

    &--bigger-bottom-margin {
      margin-bottom: 20px;
    }

    &--smaller-bottom-margin {
      margin-bottom: 5px;
    }
  }

  &__section-subtitle {
    margin-bottom: 25px;
    color: #5b5b5b;
    font: 14px/17px sans-serif;
  }

  &__section-content {
    padding: 0;
    margin: -6px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    overflow: hidden;

    &--overflow-visible {
      overflow: visible;
    }
  }

  &__icon-snippet-wrap {
    width: 25%;
    padding: 6px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &::before {
      content: "";
      width: 1px;
      height: 40%;
      position: absolute;
      inset: 30% -2px auto auto;
      background: rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: "";
      width: 40%;
      height: 1px;
      position: absolute;
      inset: auto auto -2px 30%;
      background: rgba(0, 0, 0, 0.1);
    }

    &--wide {
      width: 50%;
    }
  }

  &__icon-snippet {
    width: 100%;
    height: 100%;
  }

  &__creators-list {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: none;
  }

  &__creator-snippet {
    width: 20%;
    min-width: 20%;
    margin-bottom: 20px;
  }

  &__collabs-list {
    gap: 22px;
    padding: 0;
    margin: 6px 6px 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__collab-snippet-wrap {
    width: calc(33.3% - 22px);
    display: flex;
  }

  &__collab-snippet {
    width: 100%;
    opacity: 1;
    transition: opacity 0.05s ease-in-out;
    cursor: pointer;
    user-select: none;
  }

  &__note-wrap {
    margin-bottom: 20px;
  }

  &__note {
  }

  &__additional-links {
    margin-bottom: 10px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .step-intro {
    &__creator-snippet {
      width: 25%;
      min-width: 25%;
    }

    &__collab-snippet-wrap {
      width: calc(50% - 22px);
      margin: 0 22px 31px 0;

      &::before {
        left: calc(50% - 98px);
      }

      &:nth-child(3n) {
        margin-right: 22px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .step-intro {
    &__icon-snippet-wrap {
      width: 33.3%;

      &--wide {
        width: 100%;
        padding: 6px 0;

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    &__creator-snippet {
      width: 33.3%;
      min-width: 33.3%;
    }

    &__collab-snippet-wrap {
      width: calc(50% - 22px);
      margin: 0 22px 31px 0;

      &::before {
        top: 18px;
        left: calc(50% - 55px);
      }

      &:nth-child(3n) {
        margin-right: 22px;
      }

      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
