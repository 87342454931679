<template>
  <SrpModal :isVisible="props.isVisible" @update:isVisible="isVisible => emit('update:isVisible', isVisible)">
    <template #content>
      <div class="global-standard-font">
        <div style="margin-bottom: 32px">
          <h1 class="global-h1" style="margin-bottom: 10px; text-wrap: balance">Add Creator Visits to Your Plan</h1>
          <p class="global-p" style="text-wrap: balance">You can add additional Creator Visits at any time to keep highlighting your destination through authentic, engaging content.</p>
        </div>

        <h4 class="global-h4" style="margin-bottom: 10px">How many would you like to add?</h4>
        <div style="margin-bottom: 24px; display: flex; align-items: center">
          <NumberInputWithPlusMinus size="medium" :min="1" isPositiveOnly v-model:value="numberOfCollabsToAdd" style="margin-right: 14px" />
          <b>Creator Visits</b>
        </div>

        <div style="margin-bottom: 20px">
          <SrpCheckbox size="medium" style="margin-bottom: 15px !important">
            <template #input><input type="checkbox" v-model="isShrpaWillHandleLodging" /></template>
            <template #text><b>Shrpa will cover lodging</b></template>
          </SrpCheckbox>

          <br />

          <SrpCheckbox v-if="!isShrpaWillHandleLodging" size="tiny" style="display: inline-flex" fill="outlined">
            <template #input><input type="checkbox" v-model="isIWillHandleLodging" /></template>
            <template #text>I agree to provide 2 nights lodging for the creator</template>
          </SrpCheckbox>

          <div v-if="isLodgingErrorVisible" class="global-error-string" style="margin-top: 10px">Please agree to lodging terms.</div>
        </div>

        <div style="margin-bottom: 25px; border-bottom: 1px rgba(0, 0, 0, 0.1) solid"></div>

        <!-- Formula -->
        <div class="formula">
          <div class="formula__number">${{ creatorVisitPrice.toLocaleString("en-US") }}</div>
          <div class="formula__description"></div>

          <template v-if="isShrpaWillHandleLodging">
            <div class="formula__number">+ ${{ lodgingPrice.toLocaleString("en-US") }}</div>
            <div class="formula__description">Lodging Add-on</div>
          </template>

          <div class="formula__number formula__number--big">
            {{ `$${(creatorVisitPrice + (isShrpaWillHandleLodging ? lodgingPrice : 0)).toLocaleString("en-US")}` }}
          </div>
          <div class="formula__description formula__description--big">
            {{ ` x ${numberOfCollabsToAdd} = $${((creatorVisitPrice + (isShrpaWillHandleLodging ? lodgingPrice : 0)) * numberOfCollabsToAdd).toLocaleString("en-US")}` }}
          </div>
        </div>
        <!-- / Formula -->
      </div>
    </template>

    <template #footer>
      <div style="display: flex; flex-direction: column; align-items: flex-end; gap: 10px">
        <div>
          <SrpCheckbox size="tiny" whiteSpace="wrap">
            <template #input><input type="checkbox" v-model="isSendMeAnInvoiceActive" /></template>
            <template #text>I acknowledge I will be invoiced for this purchase</template>
          </SrpCheckbox>

          <div v-if="isInvoiceErrorVisible" class="global-error-string" style="margin-top: 10px">Please confirm that you will be invoiced.</div>
        </div>
        <div>
          <div v-if="isPurchasedSuccessfullyMsgVisible" style="color: rgba(65, 97, 56, 1); font-weight: 700">
            <IconEmbedded name="check_3-5" color="rgba(65, 97, 56, 1)" style="position: relative; inset: 3px -3px auto auto" :size="17" />&nbsp; New collabs added successfully
          </div>
          <SrpButton
            v-else
            @click="
              () => {
                isInvoiceErrorVisible = !isSendMeAnInvoiceActive;
                isLodgingErrorVisible = !isShrpaWillHandleLodging && !isIWillHandleLodging;

                if (!isInvoiceErrorVisible && !isLodgingErrorVisible) {
                  sendRequest();
                }
              }
            "
          >
            <template v-if="isLoading" #icon>
              <LoadingIcon :size="22" />
            </template>
            Purchase
          </SrpButton>
        </div>
      </div>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { shrpaApi } from "@repos/axios";

// Components
import NumberInputWithPlusMinus from "@components/ui/NumberInputWithPlusMinus.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import SrpModal from "@components/ui/SrpModal.vue";
import SrpCheckbox from "@components/ui/SrpCheckbox.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";

const props = withDefaults(
  defineProps<{
    isVisible: boolean;
    communityId: string;
  }>(),
  {
    isVisible: true,
    communityId: "",
  }
);

const emit = defineEmits<{
  (e: "update:isVisible", value: boolean): void;
  (e: "purchase"): void;
}>();

// Prices =====================================================================
const creatorVisitPrice = 995;
const lodgingPrice = 250;

// Number of collabs to add ===================================================
const numberOfCollabsToAdd = ref<number>(1);

// Lodging checkboxes =========================================================
const isShrpaWillHandleLodging = ref<boolean>(true);
const isIWillHandleLodging = ref<boolean>(false);

const isLodgingErrorVisible = ref<boolean>(false);

watch(
  () => [isIWillHandleLodging.value, isShrpaWillHandleLodging.value],
  () => {
    isLodgingErrorVisible.value = false;
  }
);

// Send invoice flag ==========================================================
const isSendMeAnInvoiceActive = ref<boolean>(false);
const isInvoiceErrorVisible = ref<boolean>(false);

watch(isSendMeAnInvoiceActive, () => {
  isInvoiceErrorVisible.value = false;
});

// Send the request ===========================================================
const isLoading = ref<boolean>(false);
const isPurchasedSuccessfullyMsgVisible = ref<boolean>(false);
async function sendRequest() {
  isLoading.value = true;

  const config = { headers: { "Content-Type": "application/json" } };
  const payload = {
    increaseBy: numberOfCollabsToAdd.value,
    isLodgingIncluded: isShrpaWillHandleLodging.value,
  };
  await shrpaApi.patch(`/customers/${props.communityId}/increment-total-paid-collabs`, JSON.stringify(payload), config);

  isLoading.value = false;

  isPurchasedSuccessfullyMsgVisible.value = true;

  emit("purchase");

  setTimeout(() => {
    resetValues();
    emit("update:isVisible", false);
  }, 3000);
}

// Reset values ===============================================================
watch(() => props.isVisible, resetValues);

function resetValues() {
  numberOfCollabsToAdd.value = 1;

  isSendMeAnInvoiceActive.value = false;
  isInvoiceErrorVisible.value = false;

  isShrpaWillHandleLodging.value = true;
  isIWillHandleLodging.value = false;
  isLodgingErrorVisible.value = false;

  isLoading.value = false;
  isPurchasedSuccessfullyMsgVisible.value = false;
}
</script>

<style scoped lang="scss">
// I Agree Text ====================================================================
.i-agree-text {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

// Formula ====================================================================
.formula {
  display: flex;
  flex-wrap: wrap;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-family: "Quicksand", sans-serif;

  &__number {
    width: 90px;
    min-width: 90px;
    padding-bottom: 2px;
    margin: 0 6px 0 0;
    text-align: right;
    white-space: nowrap;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;

    &--big {
      padding: 4px 0 30px;
      border-top: 1px rgba(0, 0, 0, 1) solid;
      color: rgba(84, 158, 63, 1);
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__description {
    width: calc(100% - 125px);
    min-width: calc(100% - 125px);
    padding: 0 0 2px 5px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;

    &--big {
      padding: 4px 0 0 5px;
      color: rgba(84, 158, 63, 1);
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__thin-text {
    font-weight: 400;
    white-space: nowrap;
  }
}
</style>
