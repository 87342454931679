<template>
  <div class="beta-badge">
    <slot v-if="slots.default" />
    <template v-else>beta</template>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

const slots = useSlots();
</script>

<style scoped lang="scss">
// Beta badge =================================================================
.beta-badge {
  height: 15px;
  padding: 0 4px 1px;
  box-sizing: border-box;
  border: 1px rgba(0, 0, 0, 0.3) solid;
  border-radius: 4px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.5);
  font: 12px/12px sans-serif;
  user-select: none;
}
</style>
