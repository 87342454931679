<template>
  <div class="additional-info">
    <div
      :class="{
        'additional-info__text': true,
        'additional-info__text--clamped': props.text.length > shortVersionMaxLength && !isFullyVisible,
      }"
      v-html="'<p>' + props.text.split('-----').join('</p><p>') + '</p>'"
    ></div>

    <LinkWithIcon
      v-if="props.text.length > shortVersionMaxLength"
      class="additional-info__show-more-link"
      isDottedUnderline
      color="blue"
      iconInset="1px -2px auto auto"
      @click="isFullyVisible = !isFullyVisible"
    >
      <template #icon>
        <IconEmbedded v-if="isFullyVisible" name="caret-top_3-5" :size="15" />
        <IconEmbedded v-else name="caret-bottom_3-5" :size="15" />
      </template>
      <span v-if="isFullyVisible">show less</span>
      <span v-else>show more</span>
    </LinkWithIcon>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

const props = withDefaults(
  defineProps<{
    text: string;
  }>(),
  {
    text: "",
  }
);

// Toggle line-clamp ==========================================================
const shortVersionMaxLength = 300;

const isFullyVisible = ref<boolean>(false);
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Additional info ============================================================
.additional-info {
  display: flex;
  flex-direction: column;
  position: relative;

  &__text {
    order: 1;

    &--clamped {
      display: -webkit-box !important;
      overflow: hidden !important;
      line-clamp: 3 !important;
      -webkit-line-clamp: 3 !important;
      text-overflow: ellipsis !important;
      -webkit-box-orient: vertical !important;
    }

    :deep(p) {
      @include standardFont;
      margin-bottom: 8px;
    }

    :deep(p:last-child) {
      margin-bottom: 0;
    }
  }

  &__show-more-link {
    position: absolute;
    inset: auto 0 -1px auto;
    z-index: 0;
    cursor: pointer;
    user-select: none;

    &::after {
      content: "";
      width: calc(100% + 27px);
      height: 100%;
      position: absolute;
      inset: auto 0 0 auto;
      z-index: -1;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 18%);
    }
  }
}
</style>
