<template>
  <div class="customer-quotes-page" style="margin-top: 80px">
    <!--<h1 class="global-h1" style="margin-bottom: 40px">Customer Quotes</h1>-->

    <Loader v-if="isLoading" />

    <div v-else class="logos-and-testimonials-carousels" style="margin: 0 auto">
      <h3 class="global-h3 logos-and-testimonials-carousels__title">Trusted Nationwide: Shrpa Connects Destinations with Top Travel Creators</h3>

      <HorizontalStepCarousel
        class="logos-and-testimonials-carousels__logos-carousel"
        itemWrapClassName="logos-row__logo-wrap"
        itemClassName="logos-row__logo"
        :stepSize="
          {
            'desktop-wide': 3,
            desktop: 3,
            laptop: 3,
            'tablet-large': 2,
            tablet: 2,
            mobile: 2,
          }[screenSize]
        "
        :transitionDuration="200"
      >
        <ul class="logos-row" style="width: 100%">
          <li class="logos-row__logo-wrap" v-for="logoFileName in customerLogos" :key="logoFileName">
            <img class="logos-row__logo" :src="`${contentBaseUri}/cms/images/orgs/${logoFileName}`" :alt="logoFileName" />
          </li>
        </ul>
      </HorizontalStepCarousel>

      <div class="connect-cta" style="text-align: center; margin: 60px 0">
        <div class="global-h3 logos-and-testimonials-carousels__title" style="margin-top: 0; margin-bottom: 20px">Want to see if Shrpa is right for your Destination?</div>
        <SrpButton tag="a" href="https://meetings.hubspot.com/shrpa-chris/quick-intro" target="_blank" color="orange" rel="noopener noreferrer"> Connect With Us Today! </SrpButton>
      </div>

      <div class="logos-and-testimonials-carousels__divider"></div>

      <SrpMasonry
        class="logos-and-testimonials-carousels__quotes-carousel"
        :columns="
          {
            'desktop-wide': 3,
            desktop: 3,
            laptop: 3,
            'tablet-large': 2,
            tablet: 1,
            mobile: 1,
          }[screenSize]
        "
        :columnGap="
          {
            'desktop-wide': 27,
            desktop: 27,
            laptop: 24,
            'tablet-large': 22,
            tablet: 18,
            mobile: 17,
          }[screenSize]
        "
        :items="customerQuotes"
      >
        <template #default="{ item, index, rebuildMasonry }">
          <!-- Testimonial snippet -->
          <div class="testimonial-snippet" style="margin-bottom: 27px">
            <div class="testimonial-snippet__logo-wrap">
              <img v-if="item.customerIcon" class="testimonial-snippet__logo" :src="`${contentBaseUri}/cms/images/orgs/${item.customerIcon}`" :alt="item.fullName" />
            </div>

            <div class="global-h5 testimonial-snippet__text" v-html="item.quoteText.replace(item.quoteAccentText, `<b>${item.quoteAccentText}</b>`)"></div>

            <div class="testimonial-snippet__divider"></div>

            <div class="testimonial-snippet__name-and-position">
              <div class="global-h4 testimonial-snippet__name">
                {{ item.fullName }}
              </div>
              <div class="testimonial-snippet__position">
                {{ item.position }}
              </div>
            </div>
          </div>
          <!-- / Testimonial snippet -->
        </template>
      </SrpMasonry>

      <div class="logos-and-testimonials-carousels__divider"></div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, inject } from "vue";
import { useHead } from "@unhead/vue";
import "vue-good-table-next/dist/vue-good-table-next.css";

// Components
import HorizontalStepCarousel from "@components/HorizontalStepCarousel.vue";
import Loader from "@components/Loader/Loader.vue";
import SrpMasonry from "@components/ui/SrpMasonry.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { CustomerQuote, CustomerQuotesPayload } from "@contracts/pages";
import { ScreenSize } from "@contracts/screenSize";

export default defineComponent({
  name: "CustomerQuotes",

  components: {
    HorizontalStepCarousel,
    Loader,
    SrpMasonry,
    SrpButton,
  },

  data() {
    return {
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      screenSize: inject("screenSize") as ScreenSize,

      isLoading: true,

      customerLogos: [] as Array<string>,
      customerQuotes: [] as Array<CustomerQuote>,
    };
  },

  computed: {},

  async mounted() {
    useHead({ title: "Customers" });

    await this.loadCustomerQuotes();
  },

  methods: {
    async loadCustomerQuotes() {
      const uri = `${import.meta.env.VITE_API_URL}/customer-quotes`;

      const urlSearchParams = new URLSearchParams();
      if (this.$route.query?.state) {
        urlSearchParams.append("state", String(this.$route.query?.state));
      }
      if (this.$route.query?.logosCount) {
        urlSearchParams.append("logosCount", String(this.$route.query?.logosCount));
      }
      if (this.$route.query?.quotesCount) {
        urlSearchParams.append("quotesCount", String(this.$route.query?.quotesCount));
      }

      const { data } = await axios.get<CustomerQuotesPayload>(uri + "?" + urlSearchParams.toString());

      this.customerLogos = data.logos;
      this.customerQuotes = data.customerQuotes;

      this.isLoading = false;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";
@import "@/scss/screen-size-ranges.scss";

// Logos row ==================================================================
.logos-row {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;

  &__logo-wrap {
    height: 95px;
    padding-right: 71px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      padding-right: 0;
    }
  }

  &__logo {
    max-width: 190px;
    max-height: 95px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .logos-row {
    &__logo-wrap {
      height: 65px;
      padding-right: 45px;
    }

    &__logo {
      max-width: 130px;
      max-height: 65px;
    }
  }
}

// Testimonial snippet ========================================================
.testimonial-snippet {
  @include standardFont;
  box-sizing: border-box;
  padding: 25px 25px 25px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.37);
  background: 25px 19px/42px 28px url(/images/quote-icon.svg) no-repeat;

  &__logo-wrap {
    width: 200px;
    height: 80px;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    align-items: center;
  }

  &__logo {
    width: 100%;
    height: 100%;
    margin-bottom: 17px;
    object-fit: contain;
    object-position: right;
  }

  &__text {
    margin-bottom: 18px;
    color: rgba(91, 91, 91, 1);
    font-weight: 500;
    line-height: 22px;
    white-space: pre-wrap;

    :deep(b) {
      color: rgba(0, 0, 0, 1) !important;
    }
  }

  &__divider {
    width: 200px;
    margin-bottom: 17px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__name-and-position {
  }

  &__name {
  }

  &__position {
  }
}

// Logos & testimonials carousel ==============================================
.logos-and-testimonials-carousels {
  width: $desktop-wide-content-width;
  position: relative;
  z-index: 2;

  &__title {
    margin: 127px 0 60px;
    text-align: center;
    font-weight: 600;
  }

  &__logos-carousel {
    width: 100%;
    margin: 0 auto 100px;
  }

  &__divider {
    max-width: var(--grid-content-full-width);
    margin: 0 auto 75px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__quotes-carousel {
    width: 100%;
    margin: 0 auto 65px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .logos-and-testimonials-carousels {
    width: $desktop-content-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .logos-and-testimonials-carousels {
    width: $laptop-content-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .logos-and-testimonials-carousels {
    width: $tablet-large-content-width;

    &__title {
      margin: 100px 0 50px;
    }

    &__logos-carousel {
      margin: 0 auto 90px;
    }

    &__divider {
      margin: 0 auto 65px;
    }

    &__quotes-carousel {
      margin: 0 auto 25px;
    }
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .logos-and-testimonials-carousels {
    width: $tablet-content-width;

    &__title {
      margin: 80px 0 50px;
    }

    &__logos-carousel {
      margin: 0 auto 70px;
    }

    &__divider {
      margin: 0 auto 45px;
    }

    &__quotes-carousel {
      margin: 0 auto 25px;
    }
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .logos-and-testimonials-carousels {
    width: $mobile-content-width;

    &__title {
      margin: 57px 0 30px;
    }

    &__logos-carousel {
      margin: 0 auto 50px;
    }

    &__divider {
      margin: 0 auto 35px;
    }

    &__quotes-carousel {
      margin: 0 auto 15px;
    }
  }
}

// Customer Quotes page =======================================================
.customer-quotes-page {
  width: $desktop-wide-content-width;
  margin: 0 auto;
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
  .customer-quotes-page {
    width: $desktop-content-width;
  }
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .customer-quotes-page {
    width: $laptop-content-width;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .customer-quotes-page {
    width: $tablet-large-content-width;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .customer-quotes-page {
    width: $tablet-content-width;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .customer-quotes-page {
    width: $mobile-content-width;
  }
}
</style>
