<template>
  <div class="comped-activities-textarea">
    <textarea
      :class="{
        'global-textarea': true,
        'global-textarea--warning': isRestrictedKeywordsWarningVisible,
        'comped-activities-textarea__textarea-itself': true,
      }"
      type="text"
      maxlength="1000"
      :value="props.value"
      @input="$event => emit('update:value', ($event.target as HTMLTextAreaElement).value)"
      placeholder="Ex. Ski passes at Powder Mountain and drink tickets at the Thirsty Bear"
      style="height: 120px; min-height: 120px"
    ></textarea>

    <div v-if="isRestrictedKeywordsWarningVisible" class="comped-activities-textarea__warning-text">Please only include non-lodging comps here (lodging is specified later).</div>
  </div>
</template>

<script setup lang="ts">
import lodashDebounce from "lodash-es/debounce";
import { ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    value: string | null;
  }>(),
  {
    value: "",
  }
);

const emit = defineEmits<{
  (e: "update:value", value: string): void;
}>();

// Restricted keywords ========================================================
const restrictedKeywords = ["hotel", "lodging"];

// Toggle warning message =====================================================
const isRestrictedKeywordsWarningVisible = ref<boolean>(false);

watch(
  () => props.value,
  newValue => checkValueForRestrictedKeywordsDebounced(newValue)
);

function checkValueForRestrictedKeywords(text: string) {
  const textLowerCased = text.toLowerCase();
  let _isRestrictedKeywordsWarningVisible = false;

  restrictedKeywords.forEach(keyword => {
    if (textLowerCased.includes(keyword)) {
      _isRestrictedKeywordsWarningVisible = true;
    }
  });

  isRestrictedKeywordsWarningVisible.value = _isRestrictedKeywordsWarningVisible;
}

const checkValueForRestrictedKeywordsDebounced = lodashDebounce(checkValueForRestrictedKeywords, 500);
</script>

<style scoped lang="scss">
@import "@/scss/variables.scss";

// Comped activities textarea =================================================
.comped-activities-textarea {
  &__textarea-wrap {
    position: relative;
  }

  &__textarea-itself {
    margin-bottom: 6px !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__warning-text {
    @include standardFont;
    font-weight: bold;
    color: rgba(193, 149, 29, 1);
  }
}
</style>
