<template>
  <SrpModal v-model:isVisible="isModalVisible">
    <template #content>
      <h2 class="global-h2" style="margin-bottom: 10px">Your subscription will end on {{ moment(props.subscriptionEndDate).format("MMMM Do, YYYY") }}</h2>
      <p class="global-p">
        At that point, you will continue to have access to all of the content created, but you will no longer have access to any of the Creator Visits features, including the Collaboration Hub
        (creator visits), Website Enhancements, and Social Snapshots.
      </p>
      <p class="global-p"><b>You will still have access to the content created for you from your subscription.</b></p>
    </template>

    <template #footer>
      <SrpButton @click="requestSubscriptionCancellation" color="orange" :isDisabled="isLoading">
        <template v-if="isLoading" #icon>
          <LoadingIcon :size="22" />
        </template>
        Cancel Subscription
      </SrpButton>
    </template>
  </SrpModal>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { shrpaApi } from "@repos/axios";
import moment from "moment";

// Components
import SrpModal from "@components/ui/SrpModal.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import LoadingIcon from "@components/ui/LoadingIcon.vue";

const props = withDefaults(
  defineProps<{
    pageId: string;
    subscriptionEndDate: string;
  }>(),
  {
    pageId: "",
    subscriptionEndDate: "",
  }
);

const emit = defineEmits<{
  (e: "requestSent"): void;
}>();

// Is Modal Visible ===========================================================
const isModalVisible = ref<boolean>(false);

// Request switch to monthly ==================================================
const isLoading = ref<boolean>(false);
const isRequestJustSent = ref<boolean>(false);

async function requestSubscriptionCancellation() {
  isLoading.value = true;

  await shrpaApi.post(`/customers/${props.pageId}/request-subscription-cancellation`);

  isLoading.value = false;
  isRequestJustSent.value = true;

  isModalVisible.value = false;

  emit("requestSent");
}

// Define expose ==============================================================
defineExpose({
  open: () => (isModalVisible.value = true),
  close: () => (isModalVisible.value = false),
});
</script>

<style scoped lang="scss"></style>
