<template>
  <div class="tile collab-opportunity__tile collab-opportunity__tile--apply-form">
    <div class="tile__header">
      <div class="global-h3 tile__title">Interested? Just fill out below!</div>
    </div>

    <ApplyForm class="tile__content" :dateRange="dateRange" @update:dateRange="emit('update:dateRange', $event)" :collab="collab" :errors="errors" />

    <div :style="{ fontWeight: collab.collabInput.createdForCreatorId ? 'bold' : 'normal' }">
      {{ creatorAcceptedText }}
    </div>

    <div style="margin-top: 10px">
      <h4 v-if="saveStatus" style="text-align: right">{{ saveStatus }}</h4>
      <div class="field btn-groups" style="display: flex; justify-content: flex-end">
        <div v-if="!collabHasCreatorAssigned && collab.creatorInput?.acceptedDateTime">
          <button class="ui small primary right button" @click="onClickUpdate">Update</button>
          <button v-if="collab.creatorInput && !collab.creatorInput.declinedDateTime" class="ui tiny basic right floated button" @click="emit('click:decline')">Decline</button>
        </div>
        <!--If the community hasn't chosen someone-->
        <template v-if="!collabHasCreatorAssigned">
          <div class="ui buttons right" style="display: flex; justify-content: flex-end">
            <template v-if="collab.collabInput.createdForCreatorId">
              <button :disabled="!collab.collabInput.postedToCreators" class="ui primary button" @click="onClickAccept">Accept</button>
              <div class="or" style="flex-grow: 0"></div>
              <button class="ui button" :class="!collab.collabInput.postedToCreators ? 'primary' : ''" @click="emit('click:message-destination')">Message the Destination</button>
            </template>
            <template v-else>
              <button
                v-if="!collab.creatorInput?.acceptedDateTime"
                class="ui primary button"
                :disabled="!collab.creatorInput.manuallyInvited && collab.creditCostToApply > 0 && collab.creatorCreditCount === 0"
                @click="onClickAccept"
                style="flex-grow: 0"
              >
                {{ collab.creatorInput.manuallyInvited ? "Accept Collab" : "Apply for Collab" }}
              </button>
              <template v-if="!collab.creatorInput?.acceptedDateTime && !collab.creatorInput?.declinedDateTime">
                <div class="or" style="flex-grow: 0"></div>
                <button class="ui button" @click="emit('click:decline')" style="flex-grow: 0">
                  {{ collab.creatorInput.manuallyInvited ? "Decline Collab" : "Can't make it" }}
                </button>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CreatorCollab } from "@contracts/collab";
import { computed, ref } from "vue";
import ApplyForm from "./ApplyForm.vue";

const props = defineProps<{
  saveStatus: string | null;
  collab: CreatorCollab;
  dateRange: Date[] | null;
  collabHasCreatorAssigned: boolean;
}>();
const emit = defineEmits<{
  (e: "update:dateRange", value: Date[]): void;
  (e: "click:save"): void;
  (e: "click:decline"): void;
  (e: "click:accept"): void;
  (e: "click:message-destination"): void;
}>();

const creatorAcceptedText = computed<string>(() => {
  if (props.collab.collabInput.createdForCreatorId) {
    if (props.collab.collabInput.postedToCreators) return "This collab was created just for you!";
    else return "You can accept once the destination finalizes this collab.";
  }
  const acceptedCount = props.collab.collabInput.collaboration.creatorsAccepted;
  // NOTE: Also in CollabOpportunity.vue
  if (acceptedCount <= 0) return "Open collab! No creators have applied";
  else if (acceptedCount === 1)
    // singular
    return "1 creator applied";
  // plural
  else return `${acceptedCount} creators applied`;
});

const errors = ref<string[]>();
function onClickUpdate() {
  // We now allow users to update the collab inputs.
  // They can even accept a collab that was previously declined (if they change their mind)
  if (!validate()) {
    return;
  }
  emit("click:save");
}

function onClickAccept() {
  if (!validate()) {
    return;
  }
  emit("click:accept");
}
function validate(): boolean {
  errors.value = [];
  if (!props.collab.creatorInput.proposedVisitDateTime || props.collab.creatorInput.proposedVisitDateTime.length === 0) {
    errors.value.push("Visit Date");
  } else {
    var startDate = new Date(props.collab.creatorInput.proposedVisitDateTime);
    if (startDate && startDate < new Date()) {
      errors.value.push("Visit Date must be in the future");
    } else if (props.collab.creatorInput.numberOfDaysStaying > 4) {
      errors.value.push("Visit Date Range must be less than 5 days");
    }
  }
  /* deprecated: if(!this.userConfirmedDatesAreValid) {
        errors.value.push("Confirm Dates checkbox");
      }*/
  if (props.collab.creatorInput.stayingOverNight && props.collab.creatorInput.nightsRequired === 0) {
    errors.value.push("Nights required");
  }
  if (props.collab?.collabInput.gearToBring?.length > 0 && props.collab.creatorInput.canBringNeededGear === null) {
    errors.value.push("Can bring gear");
  }
  // Gear is needed, they don't have it, but they haven't noted what is needed
  if (
    props.collab?.collabInput.gearToBring?.length > 0 &&
    props.collab.creatorInput.canBringNeededGear === false &&
    (!props.collab.creatorInput.gearNeeded || props.collab.creatorInput.gearNeeded.length === 0)
  ) {
    errors.value.push("Gear Needed");
  }
  // if (!props.collab.creatorInput.notesForCommunity || props.collab.creatorInput.notesForCommunity.length === 0) {
  //   errors.value.push("Community Notes");
  // }
  return errors.value.length === 0;
}
</script>
