// Note: We also have the UserProfile data type

// NOTE: This is SherpaPreview.cs on the server, should ideally rename to CreatorPublicProfile
export interface CreatorPublicProfileFields extends SocialMediaFields {
  _etag: string | null;
  authNameId: string | null;
  uriKey: string | null;
  sherpaId: string | null;
  disabledDate: string | null;
  firstName: string | null;
  createdOn: string | null;
  tileImageLocation: string | null;
  shortBlurb: string | null;
  orgSiteUrl: string | null;
  orgName: string | null;
  featuredMedia: Array<string>;

  // Old fields that don't map cleanly to the UserProfile
  profileCreatedDate: string | null;
  profileImageUri: string | null;
  stats: CreatorStats | null;
  // Only set for the admin endpoints
  hasAdventuresInAnyStatus: boolean;
  // Add others as we use them
}

export interface SocialMediaFields {
  twitterHandle: string | null;
  instagramHandle: string | null;
  facebookURL: string | null;
  youtubeURL: string | null;
  tikTokHandle: string | null;
}
export const socialMediaFieldNames = ["twitterHandle", "instagramHandle", "facebookURL", "youtubeURL", "tikTokHandle"];

export interface CreatorStats {
  adventureCount: number;
  locationCount: number;
  lastPublishedDate: string;
}

export interface CreatorDashboardSummary {
  profileIsSetup: boolean;
  createdAdventureCount: number;
  publishedAdventureCount: number;
  promptToBePaidCreator: boolean;
  collabCreditCount: number;

  completedActions: Array<string>;
}
