<template>
  <div class="ui form">
    <div class="field">
      <div class="global-h4">Give Your Creator Visit A Name</div>
      <p class="cms">A short name to describe the purpose of this Creator Visit. This will be shown to the <span @click="gptCreateTitle">creator.</span></p>
      <div
        :class="{
          'form-error-highlight': true,
          'form-error-highlight--bg-visible': errors.includes('Creator Visit Name'),
        }"
        style="width: 100%; max-width: 500px; margin-bottom: 25px"
      >
        <TextInputWithAISuggestions
          :suggestionsEndpointUri="`${apiBaseUri}/collabs/${collabInput.customerId}/collabs/${collabInput.id}/generate-title`"
          ref="collabNameInput"
          v-model:value="collabInput.name"
          @getSuggestions="globalRemoteLogger.info(`ai-collab-title-generate for ${collabInput.customerId} ${collabInput.id}`)"
          @selectSuggestion="suggestion => globalRemoteLogger.info(`ai-collab-title-chosen for ${collabInput.customerId} ${collabInput.id}: ${suggestion}`)"
        />
      </div>
    </div>

    <div class="field">
      <div class="global-h4">Additional Notes for the Creator</div>
      <p class="cms">(Optional) Anything a creator should be aware of before accepting?</p>
      <textarea ref="notesInput" type="text" maxlength="5000" v-model="collabInput.notesForCreator" autocapitalize="sentences" style="min-height: 25px; height: 70px; margin-bottom: 35px"></textarea>
    </div>

    <div v-if="collabInput.compedActivitiesEstimatedValue + collabInput.hotelEstimatedValue > 0" class="field">
      <div class="global-h4" style="margin-bottom: 10px">Your Comps for the Creator</div>

      <!-- Formula -->
      <div class="formula" style="margin-bottom: 15px">
        <div class="formula__number">${{ collabInput.compedActivitiesEstimatedValue || 0 }}</div>
        <div class="formula__description">Comped Activities and Items</div>

        <template v-if="collabInput.hotelNightsCovered > 0">
          <div class="formula__number">+ ${{ collabInput.hotelEstimatedValue || 0 }}</div>
          <div class="formula__description">Lodging Estimated Value</div>
        </template>

        <div class="formula__number formula__number--big">= ${{ (collabInput.compedActivitiesEstimatedValue + collabInput.hotelEstimatedValue).toLocaleString() }}</div>
        <div class="formula__description formula__description--big">in Comps</div>
      </div>
      <!-- / Formula -->

      <LinkWithIcon isDottedUnderline color="blue" @click="$emit('jumpToCompedActivitiesStep')">
        <template #icon><IconEmbedded name="arrow-left_2-5" :size="17" /></template>
        <span>make adjustments</span>
      </LinkWithIcon>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent, inject } from "vue";

// Types
import { CollabInput } from "@contracts/collab";

// Components
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import TextInputWithAISuggestions from "@components/TextInputWithAISuggestions.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";

export default defineComponent({
  name: "StepName",

  components: { LinkWithIcon, TextInputWithAISuggestions, IconEmbedded },

  props: {
    collabInput: { type: Object as () => CollabInput | null, required: true },
    errors: { type: Array<string>, required: true },
  },

  emits: ["jumpToCompedActivitiesStep"],

  data() {
    return {
      apiBaseUri: import.meta.env.VITE_API_URL as string,

      globalRemoteLogger: inject("globalRemoteLogger") as any,

      loadingTitles: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      ((this.$refs.collabNameInput as any).input as HTMLInputElement)?.focus();
    });
  },

  methods: {
    async gptCreateTitle() {
      // Hidden call for the ai-generated titles (until we test it and get a better UI)
      if (this.loadingTitles) return;
      this.loadingTitles = true;
      let result = await axios.get(`${import.meta.env.VITE_API_URL}/collabs/${this.collabInput.customerId}/collabs/${this.collabInput.id}/generate-title`);
      let toShow = result.data.responseText;
      this.loadingTitles = false;
      alert(toShow);
    },
  },
});
</script>

<style scoped lang="scss">
@import "./form-error-highlight.scss";

// Formula ====================================================================
.formula {
  display: flex;
  flex-wrap: wrap;
  color: #000;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;

  &__number {
    width: 80px;
    min-width: 60px;
    padding-bottom: 2px;
    margin: 0 6px 0 0;
    text-align: right;
    white-space: nowrap;
    line-height: 16px;
    font-weight: 700;

    &--big {
      padding: 4px 0 0;
      border-top: 1px black solid;
      color: #549e3f;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }

  &__description {
    width: calc(100% - 125px);
    min-width: calc(100% - 125px);
    padding: 0 0 2px 5px;
    box-sizing: border-box;
    line-height: 16px;
    font-weight: 700;

    &--big {
      padding: 4px 0 0 5px;
      color: #549e3f;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }

  &__thin-text {
    font-weight: 400;
    white-space: nowrap;
  }
}

// Sent successfully message ==================================================
.sent-successfully-msg {
  padding: 32px 0;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcf5d6;

  &__checkmark-icon-wrap {
    width: 97px;
    min-width: 97px;
    height: 97px;
    padding: 0 0 4px 4px;
    box-sizing: border-box;
    margin-right: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #518345;
    font-size: 54px;
    background: #fff;
  }

  &__checkmark-icon {
  }

  &__titles {
    max-width: 240px;
  }

  &__title {
    margin-bottom: 7px;
  }

  &__subtitle {
  }
}
</style>
