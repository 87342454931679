<template>
  <div>
    <div v-if="showFillWithAI && !isReadOnly" style="margin-bottom: 12px">This formalizes a proposal, which the creator can accept and deliver on.</div>
    <SrpButton v-if="showFillWithAI && !isReadOnly" size="small" style="margin-bottom: 12px">
      <IconEmbedded name="ai_2-5" :size="16" />
      Fill from messages with AI
    </SrpButton>
    <form @submit.prevent="submitCollab" class="collab-form">
      <div class="form-field same-line">
        <div class="global-h5">Title:</div>
        <template v-if="isReadOnly">
          <div class="read-only-text">{{ collabData.title }}</div>
        </template>
        <input v-else type="text" v-model="collabData.title" required placeholder="e.g. Summer Fest Promo Video" style="max-width: 300px" />
      </div>
      <div class="form-field">
        <div class="global-h5">What's the purpose of the work?</div>
        <template v-if="isReadOnly">
          <div class="read-only-text">{{ collabData.purpose }}</div>
        </template>
        <textarea v-else v-model="collabData.purpose" required placeholder="e.g. Showcase local attractions for summer tourism"></textarea>
      </div>

      <div class="form-field">
        <div class="global-h5">Deliverables</div>
        <template v-if="isReadOnly">
          <div class="read-only-text">{{ collabData.deliverables }}</div>
        </template>
        <textarea v-else v-model="collabData.deliverables" required placeholder="e.g. 30 photos of Cafe Steam and a social media post"></textarea>
      </div>

      <div class="form-group">
        <div class="form-field">
          <div class="global-h5">Payment</div>
          <template v-if="isReadOnly">
            <div class="read-only-text">${{ collabData.cashComp }}</div>
          </template>
          <div v-else class="payment-input">
            <span class="dollar-sign">$</span>
            <input type="number" v-model.number="collabData.cashComp" required />
          </div>
        </div>
        <div class="form-field">
          <div class="global-h5">Other Compensation</div>
          <template v-if="isReadOnly">
            <div class="read-only-text">{{ collabData.otherComp || "-" }}</div>
          </template>
          <input v-else type="text" v-model="collabData.otherComp" placeholder="e.g. Hotel stay" />
        </div>
      </div>

      <div class="form-group">
        <div class="form-field">
          <div class="global-h5">Activity Date</div>
          <template v-if="isReadOnly">
            <div class="read-only-text">{{ collabData.activityDate }}</div>
          </template>
          <input v-else type="date" v-model="collabData.activityDate" required />
        </div>
        <div class="form-field">
          <div class="global-h5">Due Date</div>
          <template v-if="isReadOnly">
            <div class="read-only-text">{{ collabData.dueDate }}</div>
          </template>
          <input v-else type="date" v-model="collabData.dueDate" required />
        </div>
      </div>

      <div class="form-field">
        <div class="global-h5">Additional Notes</div>
        <template v-if="isReadOnly">
          <div class="read-only-text">{{ collabData.customerNotes || "-" }}</div>
        </template>
        <textarea v-else v-model="collabData.customerNotes" placeholder="Any other details..."></textarea>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from "vue";
import SrpButton from "@/components/ui/SrpButton.vue";
import IconEmbedded from "@/components/ui/IconEmbedded.vue";
import { withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    collab: any;
    showFillWithAI?: boolean;
    isReadOnly?: boolean;
  }>(),
  {
    showFillWithAI: true,
    isReadOnly: false,
  }
);

const emit = defineEmits(["submit"]);

const collabData = ref({ ...props.collab });

watch(
  () => props.collab,
  newCollab => {
    collabData.value = { ...newCollab };
  },
  { deep: true }
);

const submitCollab = () => {
  emit("submit", collabData.value);
};
</script>

<style scoped lang="scss">
.collab-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  // padding: 0 16px 16px 0;

  .form-field {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &.same-line {
      flex-direction: row;
      align-items: center;
      gap: 7px;

      .global-h5 {
        min-width: 45px;
      }

      input {
        flex: 1;
      }
    }

    input,
    textarea {
      padding: 12px;
      border: 1px solid #ddd;
      border-radius: 6px;
      font-size: 1rem;

      &:focus {
        outline: none;
        border-color: #2b6b78;
      }
    }

    textarea {
      min-height: 75px;
      height: 75px;
      resize: none;
    }

    .payment-input {
      position: relative;
      display: flex;
      align-items: center;

      .dollar-sign {
        position: absolute;
        left: 12px;
        color: #666;
      }

      input {
        padding-left: 24px;
      }
    }
  }

  .form-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

.read-only-text {
  padding: 12px;
  //background-color: #f5f5f5;
  //border-radius: 6px;
  min-height: 43px;
  white-space: pre-wrap;
}
</style>
