<template>
  <div class="step-intro">
    <!-- Header -->
    <div class="header step-intro__header">
      <h1 class="global-h1 header__h1">Kickstart your Creator Visit!</h1>
      <div class="header__subtext">
        <p class="global-p" style="padding: 0; margin: 0 0 10px 0">
          What type of creator visit would you like to get started?<br />
          There are an endless number of ways to highlight your destination!
        </p>
      </div>

      <!-- "How it works" link -->
      <div
        :class="{
          'how-it-works-link': true,
          'header__how-it-works-link': true,
          'header__how-it-works-link--with-bottom-margin': isHowItWorksOpened,
        }"
      >
        <div class="how-it-works-link__caret-icon-wrap">
          <IconEmbedded v-if="!isHowItWorksOpened" class="how-it-works-link__caret-icon" name="caret-bottom_3-5" :size="14" color="#118689" />
          <IconEmbedded v-else class="how-it-works-link__caret-icon" name="caret-top_3-5" :size="14" color="#118689" />
        </div>

        <LinkWithIcon class="how-it-works-link__link-itself" is-dotted-underline color="teal" @click="isHowItWorksOpened = !isHowItWorksOpened">
          <template #icon><IconEmbedded name="bulb_2" :size="22" /></template>
          <span>How do Creator Visits work?</span>
        </LinkWithIcon>
      </div>
      <!-- / "How it works" link -->

      <TransitionedHeightAuto class="header__steps-guide" :isOpened="isHowItWorksOpened">
        <template #scoped>
          <StepsSummary />
        </template>
      </TransitionedHeightAuto>
    </div>
    <!-- / Header -->

    <Loader v-if="props.isLoadingCollabSummaries || props.isLoadingPlannedCollabs" style="margin: 0 0 20px !important" />

    <ul
      :class="{
        'step-intro__main-option-snippets-list': true,
        'step-intro__main-option-snippets-list--disabled': props.isLoadingCollabSummaries || props.isLoadingPlannedCollabs,
      }"
    >
      <!-- Main option snippet -->
      <li class="main-option-snippet step-intro__main-option-snippet" v-for="snippet in mainOptionSnippetsList" :key="snippet.name" @click="() => snippet.ctaButtonCallback()">
        <img class="main-option-snippet__icon" :src="`/images/${snippet.icon}.svg`" :alt="snippet.name" />

        <div class="global-h2 main-option-snippet__name">
          {{ snippet.name }}
        </div>

        <div class="main-option-snippet__cta-button-wrap">
          <SrpButton class="main-option-snippet__cta-button" fill="outlined">
            {{ snippet.ctaButtonText }}
            <template #iconRight>
              <IconEmbedded name="arrow-right_3" :size="18" />
            </template>
          </SrpButton>
        </div>
      </li>
      <!-- / Main option snippet -->
    </ul>

    <div class="step-intro__divider"></div>

    <NoteWithIcon class="step-intro__note" color="green">
      <template #icon><IconEmbedded name="info-simple_4" :size="27" /></template>
      <template #text>
        <div>
          <h5 class="global-h5">Questions or not sure where to start?</h5>
          <span>We can help! Reach out any time to <CopyTextNew>collabs@shrpa.com</CopyTextNew></span>
        </div>
      </template>
    </NoteWithIcon>

    <!-- Additional links -->
    <div class="additional-links step-intro__additional-links">
      <LinkWithIcon class="additional-links__link" tag="a" target="_blank" :href="globalGetLink('CommunityCollabInspire')">
        <template #icon><IconEmbedded name="bulb_2" :size="22" /></template>
        <span>Get inspiration</span>
      </LinkWithIcon>

      <LinkWithIcon class="additional-links__link" tag="a" target="_blank" :href="globalGetLink('CommunityPaidCollabHelp')">
        <template #icon><IconEmbedded name="lifebuoy_2" :size="21" /></template>
        <span>View Additional Guidance</span>
      </LinkWithIcon>
    </div>
    <!-- / Additional links -->
  </div>
</template>

<script setup lang="ts">
import { ref, inject, onMounted, computed } from "vue";
import { useRoute } from "vue-router";

// Components
import CopyTextNew from "@components/CopyTextNew.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import StepsSummary from "@views/PaidCollab/CreateCollab/StepsSummary.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import TransitionedHeightAuto from "@components/ui/TransitionedHeightAuto.vue";
import SrpButton from "@components/ui/SrpButton.vue";

// Types
import { CollabInput, CreatorCollabSummary } from "@contracts/collab";
import { PlannedCollab } from "@contracts/plannedCollabs";

// Stores
import { useCollabSummariesStore } from "@stores/collabSummaries";
import Loader from "@components/Loader/Loader.vue";
const collabSummariesStore = useCollabSummariesStore();

// Router
const route = useRoute();

// Global variables & injections
const globalGetLink = inject("globalGetLink") as any;

const props = withDefaults(
  defineProps<{
    introSteps: Record<string, number>;
    collabInput: CollabInput | null;
    customerId: string;
    draftCollabs: Array<CollabInput>;
    plannedCollabs: Array<PlannedCollab>;
    collabSummaries: Array<CreatorCollabSummary>;
    isLoadingCollabSummaries?: boolean;
    isLoadingPlannedCollabs?: boolean;
  }>(),
  {
    introSteps: () => ({}),
    collabInput: null,
    customerId: "",
    draftCollabs: () => [],
    plannedCollabs: () => [],
    CreatorCollabSummary: () => [],
    isLoadingCollabSummaries: false,
    isLoadingPlannedCollabs: false,
  }
);

const emit = defineEmits<{
  (e: "changeStep", stepNumber: number): void;
  (e: "cloneCollab", id: string): void;
  (e: "mount"): void;
}>();

// Sort collab summaries ======================================================
const collabSummariesRecentFirst = computed(() => {
  return props.collabSummaries.slice().sort((a, b) => {
    // nulls sort after anything else
    if (a.completedDate === null) return 1;
    if (b.completedDate === null) return -1;
    return b.completedDate - a.completedDate;
  });
});

// Main option snippets list ==================================================
const mainOptionSnippetsList = computed<
  Array<{
    icon: string;
    name: string;
    ctaButtonText: string;
    ctaButtonCallback: Function;
  }>
>(() => {
  const snippetsList = [
    {
      icon: "blankSheepDashedBorder",
      name: "Start fresh",
      ctaButtonText: "Start Fresh",
      ctaButtonCallback: () => emit("changeStep", 0.1),
    },
    {
      icon: "bulbWithLight",
      name: "Use Visit Inspiration",
      ctaButtonText: "Pick a Visit",
      ctaButtonCallback: () => emit("changeStep", 0.3),
    },
  ];

  if (props.plannedCollabs?.length) {
    snippetsList.push({
      icon: "calendarCheckMark",
      name: "Use Planned Visit",
      ctaButtonText: "Pick a Visit",
      ctaButtonCallback: () => emit("changeStep", 0.4),
    });
  }

  if (props.collabInput?.id == null && collabSummariesRecentFirst.value?.length) {
    snippetsList.push({
      icon: "copyWithArrow",
      name: "Clone Previous Visit",
      ctaButtonText: "Pick a Visit",
      ctaButtonCallback: () => emit("changeStep", 0.5),
    });
  }

  if (props.draftCollabs?.length) {
    snippetsList.push({
      icon: "blankSheetCheckMark",
      name: "Use Collab Draft",
      ctaButtonText: "Pick a Collab",
      ctaButtonCallback: () => emit("changeStep", 0.2),
    });
  }

  return snippetsList;
});

// is "How it works" opened ===================================================
const isHowItWorksOpened = ref(false);

// Emit "mount" ===============================================================
onMounted(() => {
  emit("mount");
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// "How it works" link ========================================================
.how-it-works-link {
  height: 30px;
  padding: 0 15px 0 10px;
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
  position: relative;
  background: rgba(240, 240, 240, 1);

  &__caret-icon-wrap {
    padding: 4px 9px 0 2px;
    margin-right: 7px;
    position: relative;

    &::after {
      content: "";
      width: 1px;
      height: calc(100% - 4px);
      border-right: 1px rgba(0, 0, 0, 0.1) solid;
      position: absolute;
      inset: 2px 0 auto auto;
    }
  }

  &__caret-icon {
  }

  &__link-itself {
    &::before {
      content: "";
      width: calc(100% + 50px);
      height: calc(100% + 10px);
      position: absolute;
      inset: -4px auto auto -40px;
      transform: none;
    }
  }
}

// Additional links ===========================================================
.additional-links {
  &__link {
    margin-right: 25px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .additional-links {
    display: flex;
    flex-direction: column;

    &__link {
      &:first-child {
        margin-bottom: 11px;
      }
    }
  }
}

// Header =====================================================================
.header {
  &__h1 {
    margin-bottom: 10px;
    text-wrap: balance;
  }

  &__subtext {
    color: #5b5b5b;
    font-family: sans-serif;
  }

  &__how-it-works-link {
    &--with-bottom-margin {
      margin-bottom: 15px;
    }
  }

  &__steps-guide {
    padding: 20px 20px 24px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
}

// Main option snippet ========================================================
.main-option-snippet {
  min-height: 85px;
  padding: 0 20px;
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: rgba(240, 240, 240, 1);
    background: rgba(240, 240, 240, 1);
  }

  &__icon {
    width: 67px;
    height: 67px;
    margin-right: 20px;
  }

  &__name-and-button {
    display: flex;
    align-items: center;
  }

  &__name {
    flex-grow: 1;
    font-weight: 500;
  }

  &:hover &__name {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.2);
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
  }

  &__cta-button-wrap {
    padding-left: 25px;
    position: relative;

    &::before {
      content: "";
      width: 0;
      height: calc(100% + 12px);
      border-left: 1px rgba(0, 0, 0, 0.1) solid;
      position: absolute;
      inset: -6px auto auto 0;
    }
  }

  &__cta-button {
    min-width: 165px;
  }
}

// Step intro =================================================================
.step-intro {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 55px;
  }

  &__main-option-snippets-list {
    gap: 17px;
    padding: 0;
    margin: 0 0 40px;
    display: flex;
    flex-direction: column;
    list-style: none;

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__main-option-snippet {
  }

  &__divider {
    margin-bottom: 40px;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  }

  &__note {
    margin-bottom: 25px;
  }

  &__additional-links {
    margin-bottom: 10px;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
}
</style>
