<template>
  <div
    :class="{
      'show-more-btn': true,
      'show-more-btn--no-bg': !props.isWithBg,
    }"
  >
    <IconEmbedded
      v-if="!slots.default"
      :class="{
        'show-more-btn__icon': true,
        'show-more-btn__icon--with-rotation': props.iconName.includes('reload'),
      }"
      :name="props.iconName"
      :size="props.iconSize"
      color="rgba(120, 120, 120, 1)"
    />

    <div class="global-h5 show-more-btn__text">
      <template v-if="slots.default">
        <slot />
      </template>
      <template v-else>Show more</template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useSlots } from "vue";

// Component
import IconEmbedded from "@components/ui/IconEmbedded.vue";

// Types
import { IconName } from "@contracts/IconName";

const props = withDefaults(
  defineProps<{
    isWithBg?: boolean;
    iconName?: IconName;
    iconSize?: number;
  }>(),
  {
    isWithBg: true,
    iconName: "reload_1-5",
    iconSize: 10,
  }
);

const slots = useSlots();
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Show more button ===========================================================
.show-more-btn {
  padding: 21px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  user-select: none;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px rgba(204, 211, 215, 1) solid;
    border-radius: 6px;
    position: absolute;
    inset: 0 auto auto 0;
    z-index: -1;
    background: rgba(255, 255, 255, 1);
    transition:
      width 0.05s ease-in-out,
      left 0.05s ease-in-out;
  }

  &--no-bg {
    padding: 0;

    &::before {
      display: none;
    }
  }

  &:hover::before {
    width: calc(100% + 10px);
    left: -5px;
  }

  &__icon {
    margin-right: 8px;
    transform: rotate(0deg);
    transition: transform 0.17s ease-in-out;
  }

  &:hover &__icon--with-rotation {
    transform: rotate(180deg);
  }

  &__text {
    text-decoration: underline;
    text-decoration-color: rgba(0, 0, 0, 0.3);
    text-underline-offset: 2px;
    text-decoration-thickness: 1px;
    text-decoration-style: dashed;
  }

  &:hover &__text {
    text-decoration: none;
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
  .show-more-btn {
    padding: 16px 0 22px;
  }
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
  .show-more-btn {
    padding: 16px 0 22px;
  }
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
  .show-more-btn {
    padding: 12px 0 18px;
  }
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .show-more-btn {
    padding: 10px 0 17px;
  }
}
</style>
