import { CollabInput } from "@contracts/collab";
import CollabDateLogic from "./CollabDateLogic";
import Logger from "@helpers/Logger";

export interface CollabInputValidationError {
  // All errors
  errors: Array<string>;

  // Strongly-typed erorrs (for special validation scenarios)
  compedActivityTagsRequired: boolean;
  compedActivityTextRequired: boolean;
}

export default {
  validateAdventureAndLocationCount(adventureCount: number, locationCount: number): boolean {
    var notValid = false;
    if (locationCount <= 4) notValid = adventureCount > 2;
    else if (locationCount <= 7) notValid = adventureCount > 3;

    return !notValid;
  },

  // Validates CollabInputs. Can validate a specific step (see CreateCollab.vue)
  validateCollabInputs(collabInput: CollabInput, stepToValidation: number | null, isSuperUser: boolean, allowShortcutForSupers: boolean): CollabInputValidationError {
    const result = {
      errors: [],
      compedActivityTagsRequired: false,
      compedActivityTextRequired: false,
    } as CollabInputValidationError;

    if (stepToValidation == null || stepToValidation === 3) {
      if (!collabInput.themes || collabInput.themes.trim().length == 0) {
        result.errors.push("Visit Focus");
      }
    }
    if (stepToValidation == null || stepToValidation === 1) {
      const hasTiming = collabInput.preferredDateRangeStart && collabInput.preferredDateRangeStart.length > 0;
      if (!hasTiming) {
        result.errors.push("Preferred Visit Window");
      } else {
        if (isSuperUser && allowShortcutForSupers) {
          Logger.info("SuperUser skip collab preferred date validation.");
        } else {
          var dateError = CollabDateLogic.validateCollabDates(collabInput.preferredDateRangeStart, collabInput.preferredDateRangeEnd);
          if (dateError) {
            result.errors.push(dateError);
          }
        }
      }
    }
    if (stepToValidation == null || stepToValidation === 2) {
      if ((!collabInput.suggestedAttractions || collabInput.suggestedAttractions.trim().length == 0) && (!collabInput.requiredAttractions || collabInput.requiredAttractions.trim().length == 0)) {
        result.errors.push("Suggested OR Required Activities");
      }
      if (collabInput.requiredAttractions && collabInput.requiredAttractions.trim().length > 0 && collabInput.requestingBigTicketItems === null) {
        result.errors.push("Are any Required Activities over $50");
      }
      if (collabInput.requestingBigTicketItems === true) {
        if (collabInput.compedActivitiesTags.length === 0) {
          result.compedActivityTagsRequired = true;
          result.errors.push("Comped Activities Category");
        }
        if (!collabInput.bigTicketItemBookinNotes || collabInput.bigTicketItemBookinNotes.trim().length == 0) {
          result.compedActivityTextRequired = true;
          result.errors.push("Comped Activities Details");
        }
      }
      // Logic is also in TotalCollabValue.vue
      if (
        (collabInput.requestingBigTicketItems === true || collabInput.compedActivitiesTags.length > 0) &&
        (collabInput.compedActivitiesEstimatedValue === null || collabInput.compedActivitiesEstimatedValue <= 0)
      ) {
        result.errors.push("Estimated Comped Activity Value");
      }
    }
    if (stepToValidation == null || stepToValidation === 4) {
      if (collabInput.hotelNightsCovered === null) {
        result.errors.push("Lodging Details");
      } else if (collabInput.hotelNightsCovered > 0 && (collabInput.hotelEstimatedValue === null || collabInput.hotelEstimatedValue <= 0)) {
        result.errors.push("Estimated Lodging Value");
      }
    }
    // Not validating on submit since in-flight collabs will set this in the Choose step (can add stepToValidation == null || back once those are done)
    if (stepToValidation === 5) {
      if (!this.validateAdventureAndLocationCount(collabInput.requestedAdventureCount, collabInput.requestedLocationCount)) {
        result.errors.push("Please adjust requested counts");
      }
    }
    if (stepToValidation == null || stepToValidation === 7) {
      if (collabInput.createdForCreatorId) {
        if (!collabInput.destinationContactName || collabInput.destinationContactName.trim().length == 0) {
          result.errors.push("Contact Name");
        }
        if (!collabInput.destinationPhoneNumber || collabInput.destinationPhoneNumber.trim().length == 0) {
          result.errors.push("Contact Phone Number");
        }
      }
    }
    if (stepToValidation == null || stepToValidation === 8) {
      if (!collabInput.name || collabInput.name.trim().length == 0) {
        result.errors.push("Creator Visit Name");
      }
    }

    Logger.info(`CollabInputValidator found ${result.errors.length} errors`);
    return result;
  },
};
