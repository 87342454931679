<template>
  <Loader v-if="isLoading" />

  <div v-else class="all-photos-with-grouping">
    <template v-if="!customerHasNoAssets">
      <TabsSection class="all-photos-with-grouping__tabs" :groupingType="groupingType" :useNewCollabLogic="useNewCollabLogic" @setGroupingType="setGroupingType" />

      <SearchAndFilters class="all-photos-with-grouping__search-and-filters" v-model:searchValue="filterText" :groupingType="groupingType" :useNewCollabLogic="useNewCollabLogic">
        <template #searchInput>
          <input class="global-text-input global-text-input--search" type="text" v-model="filterText" @input="searchChanged" @keyup.enter="applyFilters()" placeholder="" />
        </template>

        <template #photosCheckbox>
          <input type="checkbox" name="photos" v-model="filterShowPhotos" @change="searchChanged" />
        </template>
        <template #videosCheckbox>
          <input type="checkbox" name="videos" v-model="filterShowVideos" @change="searchChanged" />
        </template>
      </SearchAndFilters>

      <div class="all-photos-with-grouping__counter-and-sorting">
        <div class="all-photos-with-grouping__counter" :style="{ visibility: filterPhotoCount > 0 && showTotalAssetCount ? 'visible' : 'hidden' }">
          <b>{{ filterPhotoCount.toLocaleString() }}</b> asset{{ filterPhotoCount === 1 ? "" : "s" }}
          <template v-if="groupingType !== 'my-uploads'"
            >from <b>{{ groupResultCount.toLocaleString() }}</b> {{ groupTypeTextPluralized }}</template
          >
        </div>

        <!-- Sorting selector -->
        <div v-if="groupingType !== 'my-uploads'" class="sorting-selector all-photos-with-grouping__sorting-selector">
          <div class="global-h6 sorting-selector__title">Sorting:</div>

          <LinkWithIcon class="sorting-selector__selector" @click="reverseSort()" isDottedUnderline iconInset="2px auto auto 2px" color="blue">
            <template #icon><IconEmbedded :name="sortDescending ? 'descending_3' : 'ascending_3'" :size="15" /></template>
            <span>{{ sortFieldText }} first</span>
          </LinkWithIcon>
        </div>
        <!-- / Sorting selector -->
      </div>
    </template>

    <div :style="{ display: photosToShow.length === 0 ? 'block' : 'none' }">
      <!-- Note: We need to keep this rendered but hidden for any remaining uploads to continue -->
      <div :style="{ display: customerHasNoAssets ? 'block' : 'none' }">
        <NoteWithIcon color="green">
          <template #icon><IconEmbedded name="info-simple_4" :size="25" /></template>
          <template #text>
            <div>
              <h3 class="global-h3" style="margin-bottom: 4px">Where to start?</h3>
              <div>
                <div style="margin-bottom: 5px">
                  <RouterLink :to="{ name: 'UserUploadCampaigns', params: { pageId: customerId } }">Community Uploads</RouterLink> are a great way to acquire photos and videos.
                </div>
                <div style="margin-bottom: 10px">Or you can upload your own:</div>

                <UploadPhotosNonAdventure :customerId="customerId" @imageAddedToGallery="addImageToNonAdventureGallery">
                  <template #trigger="{ openModal }">
                    <SrpButton @click="openModal" size="small">Upload photos</SrpButton>
                  </template>
                </UploadPhotosNonAdventure>
              </div>
            </div>
          </template>
        </NoteWithIcon>

        <NoteWithIcon v-if="groupingType === 'community-upload'" style="margin: 18px 0" color="blue">
          <template #icon><IconEmbedded name="info-simple_4" /></template>
          <template #text>
            <div>
              <h5 class="global-h5">Where are my Community Uploads?</h5>
              <span>Community Upload content will show here as community members upload it.</span>
            </div>
          </template>
        </NoteWithIcon>
        <!--<div style="margin: 5px 0">In the meantime you can upload your own to use in a Photo Wall integration!</div>
        <router-link class="ui primary small button" :to="{ name: 'WidgetDirections', params: { pageId: customerId } }">Create a Photo Wall</router-link>-->
      </div>

      <template v-if="!customerHasNoAssets">No photos or videos found for these filters.</template>
    </div>

    <ul v-if="photosToShow.length > 0" class="all-photos-with-grouping__photos-spoilers-list" style="margin-bottom: 30px">
      <PhotosSpoiler
        class="all-photos-with-grouping__photos-spoiler"
        v-for="(group, i) in photosToShow"
        :key="`photoGroup-${i}`"
        :title="group.groupName"
        :date="group.sortValueAsc ? moment(sortDescending ? group.sortValueDesc : group.sortValueAsc).fromNow() : ''"
        :email="''"
        :additionalInfo="group.additionalInfo"
        :nonLinkText="group.nonLinkText"
        :linkRoute="group.linkRoute"
        :photosList="[...new Set(group.photos)]"
        :isThumbnailsWithDownloadButton="isThumbnailsWithDownloadButton"
        :isThumbnailsWithCheckboxes="isThumbnailsWithCheckboxes"
        @downloadImage="fileName => downloadFile(fileName)"
        :checkedFiles="checkedFiles"
        @checkUncheckFile="(fileName, isChecked) => $emit('checkUncheckFile', fileName, isChecked)"
        @checkUncheckAll="isChecked => $emit('checkUncheckAll', [...new Set(group.photos)], isChecked)"
        :isThumbnailsEntireAreaClickable="true"
        :isThumbnailsEntireAreaCheckable="true"
        :isWithSelectAllCheckbox="isThumbnailsWithCheckboxes"
        :showManageUploads="group.source === MyUploadsSource"
        @manageUploads="manageUploadsClicked"
        :groupingType="groupingType"
        :subGroupingTypes="subGroupingTypes"
        :locationShortPhotoSummariesList="group.locationShortPhotoSummariesList"
        :adventureShortPhotoSummariesList="group.adventureShortPhotoSummariesList"
        :uploadCampaignShortPhotoSummariesList="group.uploadCampaignShortPhotoSummariesList"
        :creatorShortPhotoSummariesList="group.creatorShortPhotoSummariesList"
        :searchText="filterText"
      >
        <template #custom-buttons v-if="group.source === MyUploadsSource">
          <UploadPhotosNonAdventure :customerId="customerId" @imageAddedToGallery="addImageToNonAdventureGallery">
            <template #trigger="{ openModal }">
              <SrpButton @click="openModal" fill="outlined" size="small">Upload more</SrpButton>
            </template>
          </UploadPhotosNonAdventure>
        </template>
      </PhotosSpoiler>
    </ul>

    <div style="text-align: center">
      <SrpButton v-if="hasMoreImages" @click="showMoreClick" fill="outlined">
        <template #icon><IconEmbedded name="caret-bottom_4-5" :size="16" /></template>
        Show More
      </SrpButton>
    </div>
  </div>
</template>

<script lang="ts">
import axios from "axios";
import { debounce } from "debounce";
import { defineComponent, inject } from "vue";
import FileDownload from "@logic/FileDownload";
import FileUtils from "@logic/FileUtils";
import moment from "moment";
import generateSrc, { ImageSize } from "@helpers/GenerateGallerySrc";
import FeatureFlags from "@logic/FeatureFlags";
import { useHead } from "@unhead/vue";
import { includesCaseInsensitive } from "@logic/String";

// Types
import {
  PhotosPageData,
  SubGroupingType,
  LocationShortPhotoSummary,
  CreatorShortPhotoSummary,
  GroupingType,
  UploadCampaignShortPhotoSummary,
  AdventureShortPhotoSummary,
} from "@contracts/photosPageData";

// Components
import CopyText from "@components/CopyText.vue";
import Loader from "@components/Loader/Loader.vue";
import VideoRender from "@components/VideoRender.vue";
import NoteWithIcon from "@components/NoteWithIcon.vue";
import PhotosSpoiler from "./PhotosSpoiler.vue";
import SearchAndFilters from "./SearchAndFilters.vue";
import IconEmbedded from "@components/ui/IconEmbedded.vue";
import LinkWithIcon from "@components/LinkWithIcon.vue";
import SrpButton from "@components/ui/SrpButton.vue";
import TabsSection from "./TabsSection.vue";
import UploadPhotosNonAdventure from "@views/CMS/UploadPhotosNonAdventure.vue";

export default defineComponent({
  name: "AllPhotosSection",

  components: {
    SrpButton,
    IconEmbedded,
    CopyText,
    NoteWithIcon,
    LinkWithIcon,
    Loader,
    VideoRender,
    PhotosSpoiler,
    SearchAndFilters,
    TabsSection,
    UploadPhotosNonAdventure,
  },

  props: {
    checkedFiles: { type: Array as () => Array<string>, default: () => [], required: false },
    isThumbnailsWithCheckboxes: { type: Boolean, default: false, required: false },
    isThumbnailsWithDownloadButton: { type: Boolean, default: false, required: false },
    showTotalAssetCount: { type: Boolean, default: true, required: false },
  },

  emits: {
    checkUncheckFile: (fileName: string, isChecked: boolean) => true,
    checkUncheckAll: (images: Array<string>, isChecked: boolean) => true,
    dataLoaded: (data: any) => true,
  },

  data() {
    return {
      globalLog: inject("globalLog") as any,

      title: "Photos & Videos",
      customerId: null as string | null,
      isLoading: true,
      contentBaseUri: globalThis.Bootstrap.Config.contentCdnBaseUri,
      maxResults: 50,
      downloadUncompressed: true,

      // Data
      useNewCollabLogic: false,
      dataFromServer: null, // Raw data from server (we structure it differently on this page)
      groupedPhotoData: Array<PhotosPageData>(), // Grouped but not filtered
      filteredPhotoData: Array<PhotosPageData>(), // Filters applied to this
      photosToShow: Array<PhotosPageData>(), // Lazy loading applied ot this
      filterPhotoCount: 0,
      groupResultCount: 0,
      debounceCallback: null,
      sortField: "date",
      sortDescending: true,
      // Simple lazy loading
      maxImagesToShow: 250,
      showMoreIncremement: 250,
      hasMoreImages: true,

      // Filtering fields
      debounceWaitInMs: 250,
      filterText: "",
      filterShowVideos: true,
      filterShowPhotos: true,
      groupingType: "adventure" as GroupingType,

      isUserCommentsTextExpanded: [],

      MyUploadsSource: "my-uploads",
    };
  },

  computed: {
    subGroupingTypes(): Array<SubGroupingType> {
      return (
        {
          adventure: ["byLocation" /* , "byHighlights" */],
          creator: ["byAdventure", "byUploadCampaign"],
          location: ["byCreator"],
          "community-upload": ["byCreator"],
        } as Record<GroupingType, Array<SubGroupingType>>
      )[this.groupingType];
    },
    sortFieldText(): string {
      if (this.sortField === "date") {
        return this.sortDescending ? "newest" : "oldest";
      } else return this.sortDescending ? "name desc" : "name asc";
    },
    groupTypeTextPluralized(): string {
      let name: string = this.groupingType;
      if (this.groupingType === "community-upload") name = "community upload";
      if (this.useNewCollabLogic && this.groupingType === "adventure") name = "creator visit";
      return name + (this.groupResultCount === 1 ? "" : "s");
    },
    // If the customer doesn't have any photos or videos uploaded.
    customerHasNoAssets(): boolean {
      return (
        this.dataFromServer && !this.dataFromServer.adventurePhotoSummaries?.length && !this.dataFromServer.communityUploadPhotoSummaries?.length && !this.dataFromServer.customerGalleryPhotos?.length
      );
    },
  },

  async mounted() {
    useHead({ title: () => this.title ?? "" });

    this.customerId = this.$route.params.pageId as string;
    this.checkForDefaultFilters();
    await this.getData();
    this.updateHash();
  },

  methods: {
    moment,
    includesCaseInsensitive,
    async getData() {
      this.useNewCollabLogic = FeatureFlags.isFlagActive("GroupByCollab");
      const url = `${import.meta.env.VITE_API_URL}/photos/${this.customerId}/v2${this.useNewCollabLogic ? "?useNewCollabLogic=true" : ""}`;
      const { data } = await axios.get(url);
      this.dataFromServer = data;
      this.$emit("dataLoaded", data);
      this.groupData();
      this.isLoading = false;
      this.title += ` - ${this.dataFromServer.communityTitle}`;
    },
    showMoreClick() {
      this.maxImagesToShow += this.showMoreIncremement;
      this.buildListToShow();
    },
    groupData() {
      var startTime = performance.now();
      let grouped = new Array<PhotosPageData>();
      if (this.groupingType === "adventure") {
        // Adventure Photos
        this.dataFromServer.adventurePhotoSummaries.forEach(adv => {
          let photoCredit = adv.creatorDisplayName;
          if (adv.creatorCredit?.length > 0) photoCredit = adv.creatorCredit;
          let group = this.createPhotoGroup(
            adv.adventureName,
            [],
            // New logic allows group by collab
            adv.isCollab
              ? { name: "PaidCollabCommunityAdmin", params: { communityId: this.customerId }, hash: `#collab=${adv.adventureId}` }
              : { name: "ItineraryPreview", params: { itineraryId: adv.uniqueName } },
            "adventure",
            adv.publishedDate,
            adv.publishedDate,
            `(Photo Credit: ${photoCredit})`
          );
          // Add the photos (how varies a bit if this is a collab or adventure)
          if (adv.titlePhoto) group.photos.push(adv.titlePhoto);
          if (adv.collabPhotos?.length > 0) group.photos.push(...adv.collabPhotos);
          else adv.locations.forEach(loc => group.photos.push(...loc.photos));

          // Add the subGrouping data
          // "my-uploads" group doesn't have any subgroups
          if (this.groupingType !== "my-uploads") {
            adv.locations.forEach(location => {
              if (!Array.isArray(group.locationShortPhotoSummariesList)) {
                group.locationShortPhotoSummariesList = [];
              }
              group.locationShortPhotoSummariesList.push({
                locationId: location.locationId,
                locationName: location.locationName,
                photos: location.photos,
              } as LocationShortPhotoSummary);
            });
          }

          grouped.push(group);
        });
      } else if (this.groupingType === "location") {
        // Adventure Photos by Location
        // Previously used flatMap but wanted a parent field so ended up with this
        const flattenedByLocation = [];

        // Creates a map of all photoId's to be able to find their creators easily later
        const photosAndItsCreators: Map<string, { creatorId: string; creatorName: string }> = new Map();
        this.dataFromServer.adventurePhotoSummaries.forEach(advevnture => {
          advevnture.locations.forEach(location => {
            location.photos.forEach(photoId => {
              photosAndItsCreators.set(photoId, { creatorId: advevnture.creatorId, creatorName: advevnture.creatorDisplayName });
            });
          });
        });

        this.dataFromServer.adventurePhotoSummaries.forEach(adv => {
          adv.locations.forEach(loc => {
            flattenedByLocation.push({
              publishedDate: adv.publishedDate,
              location: loc,
            });
          });
        });
        // const flattenedByLocation = this.dataFromServer.adventurePhotoSummaries.flatMap(adv => adv.locations);
        const locationGrouping = this.groupBy(flattenedByLocation, loc => loc.location.locationId);
        locationGrouping.forEach(locationGroup => {
          let sortMinMax = this.getMinMax(locationGroup, loc => loc.publishedDate);
          let group = this.createPhotoGroup(locationGroup[0].location.locationName, [], null, "adventure", sortMinMax[0], sortMinMax[1], null);
          if (!group.groupName || group.groupName.length === 0) group.groupName = "Unknown Location";
          locationGroup.forEach(loc => {
            group.photos.push(...loc.location.photos);
          });
          // Exclude locations without any photos
          if (group.photos.length > 0) {
            // Add the subGrouping data
            group.photos.forEach(photoId => {
              if (!Array.isArray(group.creatorShortPhotoSummariesList)) {
                group.creatorShortPhotoSummariesList = [];
              }

              // Find the target Short Photo Summary
              const targetShortPhotoSummary = group.creatorShortPhotoSummariesList.find(s => {
                return photosAndItsCreators.has(photoId) && s.creatorId === photosAndItsCreators.get(photoId)?.creatorId;
              });

              if (targetShortPhotoSummary) {
                targetShortPhotoSummary.photos.push(photoId);
              } else {
                group.creatorShortPhotoSummariesList.push({
                  creatorId: photosAndItsCreators.get(photoId)?.creatorId,
                  creatorName: photosAndItsCreators.get(photoId)?.creatorName,
                  photos: [photoId],
                } as CreatorShortPhotoSummary);
              }
            });

            grouped.push(group);
          }
        });
        // NOTE: Not showing Community Uploads and My Uploads by location (since we don't have that data)
      } else if (this.groupingType === "community-upload") {
        // Only applies to Community Uploads
        const campaignIdGrouping = this.groupBy(this.dataFromServer.communityUploadPhotoSummaries, upload => upload.campaignId);
        campaignIdGrouping.forEach(byCampaignGroup => {
          let sortMinMax = this.getMinMax(byCampaignGroup, loc => loc.createdDate);
          let creatorNames = byCampaignGroup.map(upload => `${upload.uploadersName} (${upload.uploadersEmail})`).join(", ");
          let group = this.createPhotoGroup(byCampaignGroup[0].campaignName, [], null, "community-upload", sortMinMax[0], sortMinMax[1], null, "Uploads by " + creatorNames);
          if (!group.groupName || group.groupName.length === 0) {
            group.groupName = "Unknown Campaign";
          }

          // Add the subGrouping data
          byCampaignGroup.forEach(campaign => {
            if (!Array.isArray(group.creatorShortPhotoSummariesList)) {
              group.creatorShortPhotoSummariesList = [];
            }
            group.creatorShortPhotoSummariesList.push({
              creatorId: campaign.uploadersEmail,
              creatorName: `${campaign.uploadersName} (${campaign.uploadersEmail})`,
              photos: campaign.photos,
              additionalInfo: campaign.additionalInfo,
            } as CreatorShortPhotoSummary);
          });

          byCampaignGroup.forEach(upload => {
            group.photos.push(...upload.photos);
          });
          grouped.push(group);
        });
      } else if (this.groupingType === this.MyUploadsSource) {
        // My Uploads
        let group = this.createPhotoGroup(
          "My Uploads",
          [...this.dataFromServer.customerGalleryPhotos],
          { name: "PhotoGalleryAdmin", params: { pageId: this.customerId } },
          this.MyUploadsSource,
          null,
          null,
          null
        );
        grouped.push(group);
      }
      var endTime = performance.now();
      this.globalLog.info(`Grouping took ${endTime - startTime}ms`);

      this.groupedPhotoData = grouped;
      this.applyFilters();
    },
    getMinMax(inputArray, fieldGetter) {
      if (!inputArray) {
        return null;
      }
      let minV = fieldGetter(inputArray[0]);
      let maxV = fieldGetter(inputArray[0]);
      for (let row of inputArray) {
        let rowVal = fieldGetter(row);
        if (rowVal) {
          if (rowVal < minV) minV = rowVal;
          if (rowVal > maxV) maxV = rowVal;
        }
      }
      return [minV, maxV];
    },
    calculateCounts() {
      this.groupResultCount = this.filteredPhotoData.length;
      this.filterPhotoCount = this.filteredPhotoData.reduce((n, { photos }) => n + photos.length, 0);
    },
    searchChanged() {
      // Note: Using Debounce here so it only actually fires periodically
      // this.globalLog.info("Search changed " + this.filterText);
      if (this.debounceCallback) {
        this.debounceCallback();
      } else {
        this.debounceCallback = debounce(this.applyFilters, this.debounceWaitInMs);
        this.debounceCallback();
      }
    },
    applyFilters() {
      this.globalLog.info("ApplyFilter " + this.filterText);

      var startTime = performance.now();
      // this.filterInProgress = true;
      let filterMatches = [];
      this.groupedPhotoData.forEach(inputGroup => {
        // Check if the group passes filters
        if (this.doesGroupPassFilters(inputGroup)) {
          // Check which photos pass filters
          let groupToAdd = this.applyPhotoFilter(inputGroup);
          // Note: Always show my uploads (since the buttons are currently in the spoiler)
          if (this.doesGroupPassFilters(groupToAdd) && (groupToAdd?.photos.length > 0 || this.groupingType === this.MyUploadsSource)) {
            filterMatches.push(groupToAdd);
          }
        }
      });
      this.filteredPhotoData = filterMatches;
      var endTime = performance.now();
      // this.filterInProgress = false;
      this.globalLog.info(`Filtering took ${endTime - startTime}ms`);

      // Sort
      this.sortFilteredData();

      this.calculateCounts();
      this.buildListToShow();
      this.updateHash();
    },
    sortFilteredData() {
      if (this.sortField === "name") {
        if (this.sortDescending) this.filteredPhotoData.sort((a, b) => -a.groupName.localeCompare(b.groupName));
        else this.filteredPhotoData.sort((a, b) => a.groupName.localeCompare(b.groupName));
      } else {
        // Sorting by Date
        // The null coalesce should push null dates to the end
        if (this.sortDescending) this.filteredPhotoData.sort((a, b) => -(a.sortValueDesc ?? "2000").localeCompare(b.sortValueDesc ?? "2000"));
        else this.filteredPhotoData.sort((a, b) => (a.sortValueAsc ?? "9999").localeCompare(b.sortValueAsc ?? "9999"));
      }
    },
    searchInsideSubgroups(
      subGroupsList: Array<LocationShortPhotoSummary> | Array<CreatorShortPhotoSummary>,
      subGroupingType: SubGroupingType,
      searchTerm: string,
      inputGroup: PhotosPageData
    ): boolean {
      const targetField = { byLocation: "locationName", byCreator: "creatorName" }[subGroupingType];

      return Boolean(
        subGroupsList
          ?.map(subGroup => {
            return this.includesCaseInsensitive(subGroup[targetField], searchTerm) && subGroup.photos?.filter(p => inputGroup.photos.includes(p)).length;
          })
          .filter(i => i).length > 0
      );
    },
    doesGroupPassFilters(inputGroup: PhotosPageData): boolean {
      if (this.filterText?.trim().length > 0) {
        const isGroupNameHasMatches = this.includesCaseInsensitive(inputGroup.groupName, this.filterText);
        let isSubGroupsHasMatches = false;

        if (this.groupingType === "adventure") {
          isSubGroupsHasMatches = this.searchInsideSubgroups(inputGroup.locationShortPhotoSummariesList, "byLocation", this.filterText, inputGroup);
        }
        if (this.groupingType === "location") {
          isSubGroupsHasMatches = this.searchInsideSubgroups(inputGroup.creatorShortPhotoSummariesList, "byCreator", this.filterText, inputGroup);
        }
        if (this.groupingType === "community-upload") {
          isSubGroupsHasMatches = this.searchInsideSubgroups(inputGroup.creatorShortPhotoSummariesList, "byCreator", this.filterText, inputGroup);
        }

        if (!isGroupNameHasMatches && !isSubGroupsHasMatches) return false;
      }

      // Source filters
      if (this.groupingType === "adventure" && inputGroup.source !== "adventure") return false;
      if (this.groupingType === "location" && inputGroup.source !== "adventure") return false;
      if (this.groupingType === "community-upload" && inputGroup.source !== "community-upload") return false;
      if (this.groupingType === this.MyUploadsSource && inputGroup.source !== this.MyUploadsSource) return false;

      return true;
    },
    applyPhotoFilter(inputGroup: PhotosPageData): PhotosPageData {
      // Currently there's only a single photo-level filter.
      // If this is show all just return the input
      if (this.filterShowVideos === true && this.filterShowPhotos === true) {
        return inputGroup;
      }
      let groupToReturn = this.createPhotoGroup(
        inputGroup.groupName,
        [],
        inputGroup.linkRoute,
        inputGroup.source,
        inputGroup.sortValueAsc,
        inputGroup.sortValueDesc,
        inputGroup.nonLinkText,
        inputGroup.additionalInfo,
        inputGroup.creatorShortPhotoSummariesList,
        inputGroup.locationShortPhotoSummariesList,
        inputGroup.uploadCampaignShortPhotoSummariesList,
        inputGroup.adventureShortPhotoSummariesList
      );
      inputGroup.photos.forEach(photo => {
        // Video or Photo check
        let isVideo = this.isVideo(photo);
        if (this.filterShowVideos && isVideo) groupToReturn.photos.push(photo);
        else if (this.filterShowPhotos && !isVideo) groupToReturn.photos.push(photo);
      });
      return groupToReturn;
    },
    buildListToShow() {
      // Takes into account lazy loading
      let countAdded = 0;
      let toShowLocalList = new Array<PhotosPageData>();
      this.hasMoreImages = false;
      for (let i = 0; i < this.filteredPhotoData.length; i++) {
        // Check if we've added enough
        if (countAdded > this.maxImagesToShow) {
          this.hasMoreImages = true;
          break;
        }
        // Otherwise add the group
        let currentGroup = this.filteredPhotoData[i];
        countAdded += currentGroup.photos.length;
        toShowLocalList.push(currentGroup);
      }
      this.photosToShow = toShowLocalList;
    },
    createPhotoGroup(
      groupName: string,
      photos: Array<string>,
      linkRoute: any | null,
      source: string,
      sortValueAsc: string,
      sortValueDesc: string,
      nonLinkText: string,
      additionalInfo?: string | null,
      creatorShortPhotoSummariesList?: Array<CreatorShortPhotoSummary> | null,
      locationShortPhotoSummariesList?: Array<LocationShortPhotoSummary> | null,
      uploadCampaignShortPhotoSummariesList?: Array<UploadCampaignShortPhotoSummary> | null,
      adventureShortPhotoSummariesList?: Array<AdventureShortPhotoSummary> | null
    ): PhotosPageData {
      return {
        groupName: groupName,
        photos: photos,
        linkRoute: linkRoute,
        // Currently used for PhotoCredit
        nonLinkText: nonLinkText,
        source: source,
        sortValueAsc: sortValueAsc,
        sortValueDesc: sortValueDesc,
        additionalInfo: additionalInfo,
        creatorShortPhotoSummariesList,
        locationShortPhotoSummariesList,
        uploadCampaignShortPhotoSummariesList,
        adventureShortPhotoSummariesList,
      } as PhotosPageData;
    },
    setGroupingType(type: GroupingType) {
      this.filterText = ""; // reset search term on grouping type change
      this.groupingType = type;
      this.updateHash();
      this.groupData();
    },
    reverseSort() {
      this.sortDescending = !this.sortDescending;
      this.applyFilters();
    },
    addImageToNonAdventureGallery(imageId: string) {
      // Add it to the start
      if (this.dataFromServer.customerGalleryPhotos) {
        this.dataFromServer.customerGalleryPhotos.unshift(imageId);
      }

      this.groupData();
    },
    manageUploadsClicked() {
      window.open(this.$router.resolve({ name: "PhotoGalleryAdmin", params: { pageId: this.customerId } }).href);
    },
    async downloadFile(imageName: string) {
      await FileDownload.downloadFile(imageName, this.downloadUncompressed, this.customerId, "photos-page");
    },
    checkForDefaultFilters() {
      // Some pages navigate in and want us to auto-apply filters
      let justCommunityUploads = this.getHashParameter("justCommunityUploads");
      if (justCommunityUploads) {
        // They typically get notified because a new creator uploads,
        // so defaulting this to creator (could also deafult to community-upload)
        this.groupingType = "community-upload";
      }
      let group = this.getHashParameter("group");
      if (group) {
        this.groupingType = group as GroupingType;
      }
      let searchText = this.getHashParameter("search");
      if (searchText) {
        this.filterText = searchText;
      }
    },
    updateHash() {
      // Keep track of the filters based on the hash
      let hash = new URLSearchParams();
      hash.set("group", this.groupingType);
      if (this.filterText) hash.set("search", this.filterText);
      // (future) Comma separate filters
      const url = `${window.location.pathname}#${hash.toString()}`;
      history.replaceState(null, "", url);
    },
    getHashParameter(key: string): string | null {
      if (window.location.hash.length < 2) return null;
      const parsedHash = new URLSearchParams(window.location.hash.substring(1));
      return parsedHash.get(key);
    },
    generateImageSrc(imageName: string, size: ImageSize): string {
      return generateSrc(imageName, size);
    },
    isVideo(assetName) {
      return FileUtils.isVideoFileType(assetName, null);
    },
    // Takes an Array<>, and a grouping property.
    // Returns a Map, grouped by the grouping property.
    groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach(item => {
        let key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Sorting selector ===========================================================
.sorting-selector {
  display: flex;
  align-items: center;

  &__title {
    margin-right: 2px;
  }

  &__selector {
  }
}

// All photos with grouping ===================================================
.all-photos-with-grouping {
  max-width: calc(100dvw - 36px);

  &__tabs {
    margin-bottom: 30px;
  }

  &__search-and-filters {
    margin-bottom: 28px;
  }

  &__counter-and-sorting {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__counter {
    color: rgba(91, 91, 91, 1);
    font: 300 14px/18px sans-serif;

    b {
      color: rgba(0, 0, 0, 1);
      font:
        700 14px/18px "Quicksand",
        sans-serif;
    }
  }

  &__sorting-selector {
    justify-self: flex-end;
  }

  &__photos-spoilers-list {
    gap: 27px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  &__photos-spoiler {
  }
}
// desktop wide -----------------------
@media (min-width: $desktop-wide-min-width) {
}
// desktop ----------------------------
@media (min-width: $desktop-min-width) and (max-width: $desktop-max-width) {
}
// laptop -----------------------------
@media (min-width: $laptop-min-width) and (max-width: $laptop-max-width) {
}
// tablet large -----------------------
@media (min-width: $tablet-large-min-width) and (max-width: $tablet-large-max-width) {
}
// tablet -----------------------------
@media (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
}
// mobile -----------------------------
@media (max-width: $mobile-max-width) {
  .all-photos-with-grouping {
    &__tabs {
      margin: 0 -20px 30px;
    }

    &__counter-and-sorting {
      gap: 12px 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
