<template>
  <div class="visit-inspirations-list">
    <div class="visit-inspirations-list__header">
      <h1 class="global-h1 visit-inspirations-list__title">Use Visit Inspiration</h1>
    </div>

    <Loader v-if="isFetchingAllCollabSuggestions" />

    <ul v-else class="visit-inspirations-list__list-itself">
      <CollabSuggestionSnippet
        class="visit-inspirations-list__visit-inspiration-snippet"
        v-for="collabSuggestion in allCollabSuggestions"
        :key="collabSuggestion.id"
        :collabSuggestion="collabSuggestion"
        :pageId="props.communityId"
        isSimplified
        @getStarted="emit('click:getStarted', collabSuggestion.id, collabSuggestion.theme)"
      />
    </ul>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import CollabSuggestionsRepo from "@repos/CollabSuggestionsRepo";

// Components
import CollabSuggestionSnippet from "@views/VisitInspiration/CollabSuggestionSnippet.vue";
import Loader from "@components/Loader/Loader.vue";

// Types
import { CollabSuggestion } from "@contracts/collabSuggestions";
import { ThemeType } from "@contracts/collab";

const props = withDefaults(
  defineProps<{
    communityId: string;
  }>(),
  {
    communityId: "",
  }
);

const emit = defineEmits<{
  (e: "click:getStarted", collabSuggestionId: string, theme: ThemeType): void;
}>();

// Fetch all collab suggestions ===============================================
const isFetchingAllCollabSuggestions = ref<boolean>(false);
const allCollabSuggestions = ref<Array<CollabSuggestion>>([]);

onMounted(async () => {
  isFetchingAllCollabSuggestions.value = true;
  allCollabSuggestions.value = await CollabSuggestionsRepo.fetchAllCollabSuggestions(props.communityId);
  isFetchingAllCollabSuggestions.value = false;
});
</script>

<style scoped lang="scss">
@import "@/scss/screen-size-ranges.scss";

// Visit inspirations list ====================================================
.visit-inspirations-list {
  &__header {
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
  }

  &__title {
    display: inline-flex;
    position: relative;
  }

  &__list-itself {
    gap: $desktop-wide-grid-gap-width;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__visit-inspiration-snippet {
    width: 100%;
  }
}
</style>
