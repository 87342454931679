import { shrpaApi } from "./axios";
import { CreatorCollabSummary } from "@contracts/collab";

export default {
  async loadAllCollabSummaries(pageId: string, isOnlyCompleted = true, includeSharedWith = false): Promise<Array<CreatorCollabSummary>> {
    const query = `?onlyCompleted=${isOnlyCompleted}&includeSharedWith=${includeSharedWith}`;
    const uri = `/collabs/${pageId}/summary${query}`;

    const { data } = await shrpaApi.get(uri);
    return data;
  },
};
